import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Login } from './pages/Login';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './assets/scss/config.scss'
import './assets/scss/mixins.scss'
import './assets/scss/utils.scss'
import './assets/scss/helpers.scss'
import { Provider, useSelector } from 'react-redux'
import { RootState, store } from './store'
import { Router } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './routes/AppRouter';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

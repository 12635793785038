import { useAxios } from "../../hooks/useAxios"
import { FamiliasAdapters, SubFamiliasAdapters } from "../adapters/parametros.adapters"

const FamiliasServices = {

    GET: function () {

        return useAxios({ method: 'get', url: '/ebcn/guia-publica-profesionales/familias', type: 'json', adapters: FamiliasAdapters.GET })
    },
}

const SubFamiliasServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/ebcn/guia-publica-profesionales/familias`, type: 'json', adapters: SubFamiliasAdapters.GET })
    },
}

export {
    FamiliasServices,
    SubFamiliasServices
}
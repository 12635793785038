import { LoginAdapters } from "../adapters/auth.adapters"
import { ComarcasAdapters, PaisesAdapters, PoblacionesAdapters } from "../adapters/ubicaciones.adapters"
import { useAxios } from "../../hooks/useAxios"

const LoginServices = {

    POST: function () {

        return useAxios({ method: 'post', url: '/auth/usuarios-ebcn', type: 'json', adapters: LoginAdapters.POST })
    }
}

const PoblacionesServices = {

    GET: function () {

        return useAxios({ method: 'get', url: '/ebcn/ubicaciones/poblaciones/paginas', type: 'json', adapters: PoblacionesAdapters.GET })
    },
}

const PaisesServices = {

    GET: function () {

        return useAxios({ method: 'get', url: '/ebcn/ubicaciones/paises', type: 'json', adapters: PaisesAdapters.GET })
    },
}

const ComarcasServices = {

    GET: function () {

        return useAxios({ method: 'get', url: '/ebcn/ubicaciones/comarcas', type: 'json', adapters: ComarcasAdapters.GET })
    },
}

export {
    LoginServices,
    PoblacionesServices,
    PaisesServices,
    ComarcasServices
}
import { BreadCrumbCustom } from '../../components/BreadCrumbCustom/BreadCrumbCustom';
import { Box, Button, Grid, List, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLang } from '../../language';
import { Navigate, Route, Routes } from 'react-router';
import { SubList } from '../../components/SubList/SubList';
import { DatosServices } from '../ActualizacionDatos/controllers/services/datos.services';
import { InscripcionListaGeneralPeritosJudiciales } from './InscripcionListaGeneralPeritosJudiciales';
import { InscripcionListaPeritosJudicialesAsistenciaReconocida } from './InscripcionListaPeritosJudicialesAsistenciaReconocida';
import { InscripcionListaPeritosTerceros } from './InscripcionListaPeritosTerceros';
import { InscripcionListaColegiados } from './InscripcionListaColegiados';

export const PeritosTercerosAdministracion = ({ onlyPath }: any) => {

    const Lang = useLang()

    const [BreadcrumbLineItems, setBreadcrumbLineItems] = useState<any>([
        {
            title: Lang('SELLOS'),
            link: onlyPath
        }
    ])

    const listRoutes = [
        {
            title: Lang('INSCRIPCION_LISTA_GENERAL_PERITOS_JUDICIALES'),
            link: '/inscripcio-llistat-general-perits-judicials'
        },
        {
            title: Lang('INSCRIPCION_LISTA_PERITOS_JUDICIALES_ASISTENCIA_RECONOCIDA'),
            link: '/inscripcio-llistat-perits-judicials-assistencia-reconeguda'
        },
        {
            title: Lang('INSCRIPCION_LISTA_PERITOS_TERCEROS'),
            link: '/inscripcio-llistat-perits-tercers'
        },
        {
            title: Lang('INSCRIPCION_LISTA_COLEGIADOS'),
            link: '/inscripcio-llistat-col-legiats'
        },
    ]


    const [fetchDatosGET, fetchDatosGETData] = DatosServices.GET()

    useEffect(() => {

        fetchDatosGET({
            params: {
                'tipo-direccion': 'CONTACT'
            }
        })

    }, [])


    return (
        <div>
            <BreadCrumbCustom items={BreadcrumbLineItems} />

            <section className='LayoutPrimary__contentmain'>

                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className='Paper__SubList'>

                                <SubList
                                    listRoutes={listRoutes}
                                    parentPath={onlyPath}
                                />

                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>

                            <Routes>

                                <Route
                                    path={listRoutes[0].link}
                                    element={<InscripcionListaGeneralPeritosJudiciales formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[0]} />}
                                />

                                <Route
                                    path={listRoutes[1].link}
                                    element={<InscripcionListaPeritosJudicialesAsistenciaReconocida formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[1]} />}
                                />

                                <Route
                                    path={listRoutes[2].link}
                                    element={<InscripcionListaPeritosTerceros formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[2]} />}
                                />

                                <Route
                                    path={listRoutes[3].link}
                                    element={<InscripcionListaColegiados formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[3]} />}
                                />

                                <Route path="*" element={<Navigate to={`${onlyPath}${listRoutes[0].link}`} replace />} />

                            </Routes>

                        </Grid>
                    </Grid>
                </Box>
            </section>
        </div>
    )
}

import { styled } from '@mui/material/styles';

import {
    Alert,
    Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup,
    FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { BreadCrumbCustom } from '../../../components/BreadCrumbCustom/BreadCrumbCustom';
import { useLang } from '../../../language';
import { InputText } from '../../../components/Inputs/InputText';
import { InputSelect } from '../../../components/Inputs/InputSelect';
import { InputChecks } from '../../../components/Inputs/InputChecks';
import { InputTextarea } from '../../../components/Inputs/InputTextarea';
import { InputRadio } from '../../../components/Inputs/InputRadio';
import { DatosAdapters } from '../../ActualizacionDatos/controllers/adapters/datos.adapters';
import { useForm } from '../../../hooks/useForm';
import { cloneDeep } from 'lodash';
import { ComunicacionServices } from '../../../controllers/services/comunicaciones.services';
import { validExiste } from '../../../helpers';
import { useValidator } from '../../../hooks/useValidator';
import { ModalStatusServer } from '../../../components/ModalStatus/ModalStatusServer';

export const SolicitudCertificado = ({ setBreadcrumbLineItems, route, formContact }: any) => {

    const Lang = useLang()

    useEffect(() => {

        setBreadcrumbLineItems((item: any) => [item[0], route])

    }, [])

    const variableTempIdiomas: any = {
        calatan: 'Català',
        castellano: 'Castellà',
        angles: 'Anglès',
    }

    // Formulario principal

    const [formDatosContacto, handleInputDatosContacto, handleFormDatosContacto, handleResetFormDatosContacto] = useForm({
        ...DatosAdapters.GET.receive.schema()
    })


    const [formSolicitud, handleInputSolicitud, handleFormSolicitud, handleResetFormSolicitud] = useForm({
        ...DatosAdapters.GET.receive.schema(),
        lopd: [],
        tipoCertificado: [],
        enviarPorEmail: [],
        idioma: 'calatan',
        fecha1: '',
        fecha2: '',
        fecha3: '',
        email: '',
        delanteDe: '',
        motivo: '',
    })

    useEffect(() => {
        if (formContact.status === 200) {
            
            handleFormDatosContacto({
                ...formDatosContacto,
                ...formContact.data?.items?.[0]
            })
        }
    }, [formContact])


    // Funciones helpers

    const handleChangeCheck = (e: any, name: string) => {

        let listaModulosTemp = cloneDeep(formSolicitud[name])

        if (e.target.checked) {

            listaModulosTemp.push(e.target.name)

        } else {

            listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormSolicitud({
            ...formSolicitud,
            [name]: listaModulosTemp
        })
    }

    // ---------------- Validaciones

    const [validComunicacion, handleValidationComunicacion] = useValidator({
        delanteDe: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    // Envio de solicitud por EMAIL

    const [fetchComunicacionesPOST, fetchComunicacionesPOSTData] = ComunicacionServices.POST()

    const handleSubmitComunicacionPOST = () => {

        if (handleValidationComunicacion(formSolicitud)) {

            const date = new Date();

            const todayDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
            const todayHour = `${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`

            const bodyTemp = {
                // adjunto: new File([""], "filename"),
                comunicacion: {
                    asunto: `[web] Formulari de la web: Sol·licitud de Certificat`,
                    cuerpo: `
                    <h3 style="margin-bottom: 15px">Sol·licitud de Certificat</h3>

                    <p>
                        <strong>Tipus de certificat:</strong> <span lang="CA"> Certificat de col·legiació </span>
                    </p>
                    <p>
                        <strong>Presentar davant de:</strong> <span lang="CA"> ${formSolicitud.delanteDe} </span>
                    </p>
                    <p>
                        <strong>Motiu presentacio:</strong> <span lang="CA"> ${formSolicitud.motivo} </span>
                    </p>

                    <table lang="CA" border="1" width="100%" cellspacing="4" cellpadding="0" style="margin-bottom: 15px">
                        <tbody lang="CA">
                            <tr>
                                <td colspan="2" style="padding: 14px">
                                    <p style="text-align: center; margin: 0">
                                        <strong>Dades colegiat</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Nom:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formDatosContacto.nombre} ${formDatosContacto.apellidos}
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2" style="padding: 14px">
                                    <p style="text-align: center; margin: 0">
                                        <strong>Mitja de resposta</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Mitja de resposta:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.enviarPorEmail?.length > 0 ? 'Enviar per correu electrònic' : ''}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Email de resposta:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.email || ''}
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2" style="padding: 14px">
                                    <p style="text-align: center; margin: 0">
                                        <strong>Caracteristiques</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Idioma:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${variableTempIdiomas[formSolicitud.idioma] || ''}
                                    </p>
                                </td>
                            </tr>
                            
                            <tr>
                                <td colspan="2" style="padding: 14px">
                                    <p style="text-align: center; margin: 0">
                                        <strong>Data</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Dia:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.fecha1 || ''}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Mes:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.fecha2 || ''}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Any:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.fecha3 || ''}
                                    </p>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                    `,
                    remitente: `${formDatosContacto.nombre} ${formDatosContacto.apellidos}`,
                    cuerpoHTML: true,
                    emailRemitente: `${formDatosContacto.datosContacto__email}`,
                    destinatarios: [
                        {
                            email: process.env.REACT_APP_EMAIL_SOLICITUD_CERTIFICADO,
                            destinatario: process.env.REACT_APP_BUSINESS_NAME,
                            variables: {}
                        },
                    ]
                }
            }

            fetchComunicacionesPOST({
                body: bodyTemp
            })
        }
    }

    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" >

                <Grid item xs={12} className='mb-4 pb-2'>
                    <Paper className='p-3 p-md-4'>

                        <Typography variant='h6' className="my-0 pb-1 fw-500 color-primary text-center">
                            {Lang('SOLICITUD_CERTIFICADO_COLEGIACION')}
                        </Typography>

                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="mt-2 fw-500 color-primary">
                                    Dades del col·legiat
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} lg={3}>
                                <InputText
                                    label={Lang('NUMERO_COLEGIADO')}
                                    value={formDatosContacto.persCodiCole}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={5}>
                                <InputText
                                    label={Lang('COGNOMS')}
                                    value={formDatosContacto.apellidos}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <InputText
                                    label={Lang('NOMS')}
                                    value={formDatosContacto.nombre}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="fw-500 color-primary">
                                    Tipus de certificat
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className='pt-2'>
                                <InputChecks
                                    values={[
                                        {
                                            id: 0,
                                            checked: formSolicitud.tipoCertificado?.length > 0,
                                            label: `De la meva col·legiació: (cost total de 7,62 €)`
                                        }
                                    ]}
                                    value
                                    name=''
                                    onChange={(e: any) => handleChangeCheck(e, 'tipoCertificado')}
                                />
                            </Grid>

                            <Grid item xs={12} className='pt-2'>
                                <Typography variant='body1' className="d-flex flex-wrap align-items-center">
                                    Per haver de presentar-ho davant de <div style={{ 'width': '130px' }} className='px-2 mb-3'><InputText
                                        label=''
                                        name='delanteDe'
                                        value={formSolicitud.delanteDe}
                                        onChange={handleInputSolicitud}
                                        localValidation={validComunicacion.delanteDe}
                                    /></div> , per motiu de:
                                </Typography>

                                <Grid item xs={12} className=''>
                                    <InputTextarea
                                        label=''
                                        rows={3}
                                        name='motivo'
                                        value={formSolicitud.motivo}
                                        onChange={handleInputSolicitud}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="fw-500 color-primary">
                                    Mitjà de resposta
                                </Typography>
                            </Grid>

                            <Grid item xs={12} xl={6} className='pt-2'>
                                <InputChecks
                                    values={[
                                        {
                                            id: 0,
                                            checked: formSolicitud.enviarPorEmail?.length > 0,
                                            label: `Enviar per correu electrònic a l'adreça:`
                                        }
                                    ]}
                                    onChange={(e: any) => handleChangeCheck(e, 'enviarPorEmail')}
                                />
                            </Grid>

                            <Grid item xs={12} lg={8} xl={6} className='pt-1'>
                                <InputText
                                    label={Lang('EMAIL')}
                                    name='email'
                                    value={formSolicitud.email}
                                    onChange={handleInputSolicitud}
                                />
                            </Grid>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="fw-500 color-primary">
                                    Característiques
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputRadio
                                    label='Idioma:'
                                    name='idioma'
                                    value={formSolicitud.idioma}
                                    onChange={handleInputSolicitud}
                                    values={
                                        [
                                            { id: 'calatan', name: 'Català' },
                                            { id: 'castellano', name: 'Castellà' },
                                            { id: 'angles', name: 'Anglès' },
                                        ]
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className='pt-2'>
                                <Typography variant='body1' className="d-flex flex-wrap align-items-center">
                                    Barcelona, <div style={{ 'width': '170px' }} className='px-2 mb-3'><InputText
                                        label=''
                                        name='fecha1'
                                        value={formSolicitud.fecha1}
                                        onChange={handleInputSolicitud}
                                    /></div> de: <div style={{ 'width': '170px' }} className='px-2 mb-3'><InputText
                                        label=''
                                        name='fecha2'
                                        value={formSolicitud.fecha2}
                                        onChange={handleInputSolicitud}
                                    /></div> de <div style={{ 'width': '170px' }} className='px-2 mb-3'><InputText
                                        label=''
                                        name='fecha3'
                                        value={formSolicitud.fecha3}
                                        onChange={handleInputSolicitud}
                                    /></div>
                                </Typography>

                                <Typography variant='body1' className="">
                                    En cas de necessitar un altre tipus de certificat, caldrà fer la petició a través del correu electrònic del Col·legi <a href="mailto:ebcn@ebcn.cat" className="color-primary">ebcn@ebcn.cat</a>, adjuntant documentació del requeriment del certificat.
                                </Typography>

                                <Typography variant='body1' className="mt-2">
                                    El pagament es pot fer mitjançant transferència bancària al número de compte del Col·legi:
                                </Typography>

                                <Typography variant='body1' className=" mt-3">
                                    <strong>Banc Sabadell</strong>
                                </Typography>

                                <Typography variant='body1' className="">

                                    <strong>Titular: Col·legi d'Enginyers Tècnics Industrials de Barcelona</strong>
                                </Typography>

                                <Typography variant='body1' className="">

                                    <strong>NIF: Q0870003A</strong>
                                </Typography>

                                <Typography variant='body1' className="">

                                    <strong>ES80 0081 7011 13 0001930699</strong>
                                </Typography>

                                <Typography variant='body1' className="mt-3">
                                    Cal indicar nom i cognoms i número de col·legiat i fer-nos arribar el comprovant per correu electrònic.
                                </Typography>
                            </Grid>

                        </Grid>

                        <div className="d-flex justify-content-between mt-4">
                            <Button color="primary" variant="contained" onClick={handleSubmitComunicacionPOST}>{Lang('ENVIAR_SOLICITUD')}</Button>
                            <Button color="primary" variant="outlined" onClick={handleResetFormSolicitud}>{Lang('LIMPIAR')}</Button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <ModalStatusServer
                fetchData={fetchComunicacionesPOSTData}
                activeLoading
                disableStatus
                messageStatus={
                    {
                        201:
                            <>
                                <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                <Typography sx={{ mt: 2 }}>
                                    S'ha enviat correctament la sol·licitud
                                </Typography>
                            </>
                    }
                }
            />
        </Box>
    )
}

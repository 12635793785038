import { List, ListItemButton, ListItemText } from "@mui/material"
import { useNavigate } from "react-router"

import './SubList.scss'

export const SubList = ({ listRoutes, parentPath }: any) => {

    const navigate = useNavigate()

    const isExactPath = (original: any) => {

        // const sublink = original.split(parentPath)?.[1] || ''
        // console.log(sublink, window.location.pathname.split(parentPath)?.[1]);

        return original === (window.location.pathname.split(parentPath)?.[1] || '/')
    }

    return (
        <List className="SubList">
            {
                listRoutes.map((item: any) => {
                    return (
                        <ListItemButton className='py-2 LayoutPrimary__Link'
                            onClick={() => navigate(`.${item.link}`)}
                            selected={isExactPath(item.link)}
                            key={item.link}
                        >
                            <ListItemText className='text-wrap'>{item.title}</ListItemText>
                        </ListItemButton>
                    )
                })
            }

        </List>
    )
}

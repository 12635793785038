import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Backdrop, Box, Button, Fade, Grid, Menu, MenuItem, Modal, SelectChangeEvent, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { getImage, validExiste, validIsEqual } from '../../../helpers';
// import logo from '../../../assets/images/logo.png'
import { useLang } from '../../../language';
import { RootState } from '../../../store';
import { logOut } from '../../../store/slices/auth';
import { useCookies } from 'react-cookie';

import './Navtop.scss'
import { InputText } from '../../Inputs/InputText';
import { useValidator } from '../../../hooks/useValidator';
import { useForm } from '../../../hooks/useForm';
import { UserEbcnServices } from '../../../controllers/services/user.services';
import { ModalCambiarPassword } from './ModalCambiarPassword';

const Navtop = ({ setshowNavTop, showNavTop, label, setshowNavLeft, showNavLeft }: any) => {

    const dispatch = useDispatch()

    const { auth } = useSelector((state: RootState) => state.auth)

    const Lang = useLang()

    const [cookies, setCookie, removeCookie] = useCookies(['CookieTokenEBCN']);

    const handleClickLogOut = (e: React.SyntheticEvent) => {

        e.preventDefault()
        removeCookie('CookieTokenEBCN', {
            path: '/',
            domain: process.env.REACT_APP_ENVIRONMENT === 'local' ? '' : process.env.REACT_APP_COOKIE_DOMAIN,
        })
        dispatch(logOut())
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Config Modal Cambiar password

    const [showModal_CambiarPassword, setShowModal_CambiarPassword] = useState({
        value: false,
        payload: null
    });

    const handleCloseModal_CambiarPassword = () => setShowModal_CambiarPassword({
        value: false,
        payload: null
    })

    const handleShowModal_CambiarPassword = (payload: any) => setShowModal_CambiarPassword({
        value: true,
        payload
    });



    return (
        <div>
            <section className='LayoutPrimary__Navtop d-flex justify-content-between'>

                <div className="LayoutPrimary__Navtop--left d-flex align-items-center d-md-none pointer"
                // onClick={() => setshowNavLeft(!showNavLeft)}
                >
                    <img src={getImage('./logo-EMC-negatiu.png')} alt="" className='ml-2' />
                </div>

                <div className="LayoutPrimary__Navtop--rigth d-flex align-items-center" id=''>

                    <Box className='LayoutPrimary__NavtopLinks'>
                        <Typography className='px-3' variant='body2'>
                            <a target="_blank" href="https://www.enginyersbcn.cat/" rel="noreferrer" className='d-block py-2 py-md-1 my-2 my-md-0 pointer'>
                                Web corporatiu
                            </a>
                        </Typography>
                        <Typography className='px-3' variant='body2'>
                            <Link to="/" color="inherit" className='LayoutPrimary__NavtopLinks--active d-block py-2 py-md-1 my-2 my-md-0 pointer pe-none'>
                                {Lang('ESPACIO_DE_TRABAJO')}
                            </Link>
                        </Typography>
                        <Typography className='px-3' variant='body2'>
                            <a target="_blank" href="https://xarxa.enginyersbcn.cat/" rel="noreferrer" className='d-block py-2 py-md-1 my-2 my-md-0 pointer'>
                                {Lang('XARXA_EBCN')}
                            </a>
                        </Typography>
                        <Typography className='px-3' variant='body2'>
                            <a target="_blank" href="https://ebcntv.enginyersbcn.cat/" rel="noreferrer" className='d-block py-2 py-md-1 my-2 my-md-0 pointer'>
                                EBCN TV
                            </a>
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Typography className='px-3 pointer LayoutPrimary__Navtop--user' onClick={handleClick}>{auth.name} <FontAwesomeIcon icon={faChevronDown} className='ml-1' /> </Typography>
                    </Box>

                    <div className="LayoutPrimary__NavtopIcon p-2 pointer" onClick={() => setshowNavTop(!showNavTop)}>
                        <FontAwesomeIcon icon={faBars} className='' style={{ 'fontSize': '1.3rem' }} />
                    </div>

                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleShowModal_CambiarPassword}>
                            {Lang('MODIFICAR_CONTRASENIA')}
                        </MenuItem>
                        <MenuItem onClick={handleClickLogOut}>
                            {Lang('CERRAR_SESION')}
                        </MenuItem>
                    </Menu>

                </div>
                <div id="LayoutPrimary__Navtop--backdrop" onClick={() => setshowNavTop(!showNavTop)}></div>

            </section>

            <ModalCambiarPassword 
                showModal_CambiarPassword={showModal_CambiarPassword}
                handleCloseModal_CambiarPassword={handleCloseModal_CambiarPassword}
            />

        </div>
    )
}

export default Navtop
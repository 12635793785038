import { useAxios } from "../../../../hooks/useAxios"
import { DatosAdapters, DatosProfesionalesAdapters } from "../adapters/datos.adapters"

const DatosServices = {

    GET: function () {

        return useAxios({ method: 'get', url: '/formulario/datos', type: 'json', adapters: DatosAdapters.GET })
    },

    PUT: function () {

        return useAxios({ method: 'put', url: '/formulario/datos-contacto', type: 'json', adapters: DatosAdapters.PUT })
    },

    PUT_ALTRES: function () {

        return useAxios({ method: 'put', url: '/formulario/datos-altres', type: 'json', adapters: DatosAdapters.PUT })
    },

    POST: function () {

        return useAxios({ method: 'post', url: '/formulario/datos-altres', type: 'json', adapters: DatosAdapters.POST })
    },

}

const DatosProfesionalesServices = {
    
    GET: function () {

        return useAxios({ method: 'get', url: '/ebcn/guia-profesionales/profesionales', type: 'json', adapters: DatosProfesionalesAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: '/ebcn/guia-profesionales/profesionales', type: 'json', adapters: DatosProfesionalesAdapters.POST })
    },

    PUT: function () {

        return useAxios({ method: 'put', url: '/ebcn/guia-profesionales/profesionales', type: 'json', adapters: DatosProfesionalesAdapters.POST })
    },

    POST_DOCUMENTO: function () {

        return useAxios({ method: 'post', url: '/ebcn/guia-profesionales/profesionales/documentos', type: 'json', adapters: DatosProfesionalesAdapters.POST_DOCUMENTO })
    },

    GET_DOCUMENTO: function () {

        return useAxios({ method: 'get', url: '/ebcn/guia-profesionales/profesionales/documentos', type: 'blob', adapters: DatosProfesionalesAdapters.GET_DOCUMENTO })
    },

    DELETE_DOCUMENTO: function () {

        return useAxios({ method: 'delete', url: '/ebcn/guia-profesionales/profesionales/documentos', type: 'json', adapters: DatosProfesionalesAdapters.DELETE_DOCUMENTO })
    },
}

export {
    DatosServices,
    DatosProfesionalesServices
}
const GENERALS = {
    MI_COLEGIO: 'El meu Col·legi',
    PARRAFO_LOGIN: 'El teu espai de treball; Actualitza les teves dades, consulta els teus tràmits i accedeix a tots els serveis del col·legi.',
    CATALAN: 'Català',
    ESPANIOL: 'Espanyol',
    PARRAFO_RECUPERAR_COLEGIADO: 'Si heu oblidat la clau, introduïu el vostre Número col·legiat i premi aquí',
    PARRAFO_RECUPERAR_PRECOLEGIADO: 'Si heu oblidat la clau, introduïu el vostre Número precol·legiat i feu clic aquí',
    PARRAFO_RECUPERAR_EXTERNO: 'Si heu oblidat la clau, introduïu el vostre NIF i feu clic aquí',
    IDENTIFICATE: `Identifica't`,
    ESPACIO_CORPORATIVO: 'Espai Corporatiu',
    ESPACIO_DE_TRABAJO: 'El meu col·legi',
    XARXA_EBCN: 'XARXA e-BCN',
    VUELVE_A_VER: 'Torna-la a Veure',
    DATOS_PROFESIONALES: 'Dades professionals',
    DATOS_ACCESO_CONSUMIDOR: `Dades d’accés al consumidor i usuari`,
    DATOS_GUIA_PROFESIONAL: 'Dades de la Guia de Professionals',
    DATOS_CONTACTO_RELACION_COLEGIO: 'Dades de contacte i relació amb el Col·legi',
    REGISTRO_TECNICOS_CERTIFICADORES: 'Registre de Tècnics Certificadors',
    SOLICITUD_CERTIFICADO_COLEGIACION: `Sol·licitud de Certificat de Col·legiació`,
    CORREO_ELECTRONICO_CORPORATIVO: 'Correu Electrònic Corporatiu',
    DL_ABSTRACT: 'DL Abstract',
    SOLICITUDES: 'Sol·licituds',
    SELLO_INGENIERO_GRADUADO_INDUSTRIAL: `Segell de l'enginyer graduat en l’àmbit industrial`,
    SELLO_INGENIERO_TECNICO_INDUSTRIAL: `Segell de l'enginyer tècnic industrial`,
    SELLO_INGENIERO_TECNICO_DISENIO_INDUSTRIAL: `Segell de l'enginyer tècnic en disseny industrial`,
    SELLO_SOCIEDADES_PROFESIONALES: `Segell de societats professionals d'enginyeria`,
    SOLICITUD_CARNET_COLEGIADO: `Sol·licitud de carnet de col·legiat`,
    SELLOS: 'Segells',
    NOMBRE_DE_EMPRESA: `Nom de l'empresa`,
    SECTOR: 'Sector',
    AREA_DE_aCTUACION: `Àrea d'actuació`,
    POSICION_O_CARGO: 'Posició o càrrec',
    DOMICILIO: 'Domicili',
    POBLACION: 'Població',
    TELEFONO_1: 'Telèfon 1',
    TELEFONO_2: 'Telèfon 2',
    TELEFONO_MOVIL: 'Telèfon Mòbil',
    TELEFONO_CONTACTO: 'Telèfon de contacte',
    FAX: 'FAX',
    EMAIL: 'Email',
    WEB: 'Web',
    ACTUALIZAR_DATOS: 'Actualitzar dades',
    ELIMINAR_DATOS: 'Eliminar dades',
    BORRAR_DATOS: 'Esborrar dades',
    MODIFICAR_CONTRASENIA: 'Modificar contrasenya',
    CERRAR_SESION: 'Tancar sessió',
    COLEGIADO: 'Col·legiat',
    PRECOLEGIADO: 'Precol·legiat',
    EXTERNO: 'Extern',
    NUMERO_COLEGIADO: 'Número col·legiat',
    CODIGO: 'Codi',
    CLAVE: 'Clau',
    NUMERO_PRECOLEGIADO: 'Número precol·legiat',
    NIF: 'NIF',

    BUSCADOR_DOCUMENTACION_SEGUIMIENTO: 'Cercador de documentació i coneixement',
    TECNOVISAT: 'Tecnovisat',
    GESTIONA_TUS_INSCRIPCIONES: 'Gestiona les teves inscripcions',
    CAMPUS_VIRTUAL: 'Campus virtual',
    BOLSA_DE_TRABAJO: 'Borsa de treball',
    ALTA_GUIA_PROFESIONALES: 'Alta Guia de professionals',
    ALTA_REGISTRO_CERTIFICADORES_EFICIENCIA_ENERGETICA: 'Alta registre de certificadors d’eficiència energètica',
    PERITOS_TERCEROS_ADMINISTRACION: 'Pèrits i tercers per a l’administració',
    SLAM: 'Slam',
    TOOLBOX: 'Tool Box',
    ACTUALIZACION_DATOS: 'Actualització de dades',
    COGNOMS: 'Cognoms',
    NOMS: 'Nom',
    Q_HABILITADO: 'Habilitat?',
    TITULACIONES: 'Titulacions',
    OTRAS_TITULACIONES: 'Altres titulacions',
    FECHA_NACIMIENTO: 'Data naixement',
    ESTADO: 'Estat',
    OTRO_TELEFONO: 'Altre telèfon',
    EMPRESAS: 'Empreses',
    ENVIAR_SOLICITUD: 'Enviar sol·licitud',
    LIMPIAR: 'Netejar',
    ENVIAR: 'Enviar',
    CONFIRMAR: 'Confirmar',
    DESCARGAR_SELLO: 'Descarregar segell',
    SOLICITAR_SELLO: 'Sol·licitar segell',
    ACTUALIZA: 'Actualitza',
    RESTABLECER_CLAVE: 'Restablir clau',
    USUARIO: 'Usuari',
    CONFIRMAR_CLAVE: 'Confirmar clau',
    PARRAFO_OPERACION_EXITO: `S'ha realitzat l'operació amb èxit`,
    CODIGO_POSTAL: 'Codi postal',
    PAIS: 'País',
    PROVINCIA: 'Província',
    RECORTAR_IMAGEN: 'Retallar imatge',
    MANTENER_ORIGINAL: 'Mantenir original',
    CONTRASENIA: 'Contrasenya',
    CONFIRMAR_CONTRASENIA: 'Confirmar contrasenya',
    PRESENTACION: 'Presentació',
    CAMPO_ENLACE: 'Camp enllaç',
    DAR_DE_BAJA: 'Donar de baixa',
    NOMBRE_EMPRESA_FORMACION_ADICIONAL: `Nom d'empresa i formació addicional`,
    INICIAR_SESION: 'Iniciar Sessió',
    COMARCA: 'Comarca',
    INSCRIPCION_LISTA_GENERAL_PERITOS_JUDICIALES: 'Inscripció al llistat general de perits judicials',
    DOCUMENTOS_ACCESO: 'Documents accés',
    INSCRIPCION_LISTA_PERITOS_JUDICIALES_ASISTENCIA_RECONOCIDA: `Inscripció al llistat de perits judicials en procediments amb assistència jurídica gratuïta reconeguda`,
    INSCRIPCION_LISTA_PERITOS_TERCEROS: `Inscripció al llistat de perits tercers per a valoracions i taxacions amb les administracions`,
    INSCRIPCION_LISTA_COLEGIADOS: 'Inscripció al llistat de col·legiats disposats a actuar com a perits en relació a l’article 50 de la Llei del Notariat',
    AREA_DE_COLEGIACION: 'Àrea de col·legiació',
    CATALOGO_DE_CURSOS: `Catàleg de cursos`,

}

const VALIDATIONS = {
    VALID_EXISTE_NUMERO_COLEGIADO: 'El número de col·legiat és obligatori.',
    VALID_EXISTE_CLAVE: 'La clau és obligatòria.',
    VALID_CONFIRMAR_CLAVE: 'Les claus no són les mateixes.',
    VALID_EXISTE_NOMBRE_SOCIEDAD: 'El nom de la societat és obligatori.',
    VALID_EXISTE_CAMPO_DEFAULT: 'El camp és obligatori.',
    VALID_CORRECT_EMAIL: 'Si us plau, entreu una adreça de correu vàlida.',
    VALID_CORRECT_MOVIL: 'El número de mòbil només pot contenir dígits (sense espais, punts, ...)',
}

const lang = {
    CA: { 
        ...GENERALS, 
        ...VALIDATIONS 
    }
}

export default lang;
import { sendString } from "./sends"

var LoginAdapters = {

    POST: {

        send: {

            schema: function (item: any = {}) {

                return {
                    // username: sendString(item.username),
                    // password: sendString(item.password),
                }
            },

            adapter: function (data: any) {

                const item = this.schema(data)

                return {
                    ...item
                }
            }
        },

        receive: {

            schema: function (item: any = {}) {

                return {
                    token: item.token
                }
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const res = this.schema(dataTemp)

                return res
            }
        },
    },

}

const PoblacionesAdapters = {

    GET: {

        receive: {

            schema: function (item: any = '') {

                return item
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const res = this.schema(dataTemp)

                return res
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                var data = {
                    password: sendString(item.password),
                    token: sendString(item.token),
                }

                return data
            },

            adapter: function (data: any) {

                const item = this.schema(data)

                return {
                    ...item
                }
            }
        },
    },
}

const PaisesAdapters = {

    GET: {

        receive: {

            schema: function (item: any = '') {

                return item
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const res = this.schema(dataTemp)

                return res
            }
        },
    },
}

const ComarcasAdapters = {

    GET: {

        receive: {

            schema: function (item: any = '') {

                return item
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const res = this.schema(dataTemp)

                return res
            }
        },
    },
}

export {
    LoginAdapters,
    PoblacionesAdapters,
    PaisesAdapters,
    ComarcasAdapters
}
import { styled } from '@mui/material/styles';

import { Box, Button, Grid, Paper, Typography } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCoffee, faFileImage, faFilePdf, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useLang } from '../../language';
import { InputText } from '../../components/Inputs/InputText';
import { InputSelect } from '../../components/Inputs/InputSelect';
import { BreadCrumbCustom } from '../../components/BreadCrumbCustom/BreadCrumbCustom';
import { InputTextarea } from '../../components/Inputs/InputTextarea';
import { ComarcasServices, PaisesServices, PoblacionesServices } from '../../controllers/services/ubicaciones.services';
import { useForm } from '../../hooks/useForm';
import { DatosProfesionalesServices, DatosServices } from '../ActualizacionDatos/controllers/services/datos.services';
import { DatosAdapters, DatosProfesionalesAdapters } from '../ActualizacionDatos/controllers/adapters/datos.adapters';
import InputImage from '../../components/Inputs/InputImage';
import { useValidator } from '../../hooks/useValidator';
import { isStatusSucess, validExiste } from '../../helpers';
import { ModalCropImage } from '../../components/ModalCropImage/ModalCropImage';
import { FamiliasServices, SubFamiliasServices } from '../../controllers/services/parametros.services';
import { cloneDeep } from 'lodash';
import { InputChecks } from '../../components/Inputs/InputChecks';
import { ModalStatusServer } from '../../components/ModalStatus/ModalStatusServer';
import { ListFiles } from '../../components/ListFiles/ListFiles';
import { ModalInfo } from '../../components/ModalInfo/ModalInfo';

export const AltaGuiaProfesionales = () => {

    const Lang = useLang()

    // Config de carga de imagenes

    const label__MaxSizeMB = '1 MB'
    const MaxSizeMB = 2000000

    // Config de carga de CV

    const label__MaxSizeMB__CV = '5 MB'
    const MaxSizeMB__CV = 5000000

    const BreadcrumbLineItems = [
        {
            title: Lang('ALTA_GUIA_PROFESIONALES'),
            link: '/alta-guia-professionals'
        }
    ]

    const [formGuiaProfesionales, handleInputGuiaProfesionales, handleFormGuiaProfesionales, handleResetFormGuiaProfesionales] = useForm({
        ...DatosProfesionalesAdapters.GET.receive.schema(),
        actividades: [],
        comarcasPreferentes: [],
        poblacion: '',
        provincia: '',
        direccion__codigoPostal: '',
        direccion__idpais: '',
        nombreEmpresa: '',
        presentacion: '',
        enlace: '',
        visible: [],
    })

    const [formDatosContacto, handleInputDatosContacto, handleFormDatosContacto, handleResetFormDatosContacto] = useForm({
        ...DatosAdapters.GET.receive.schema()
    })

    const [fetchDatosContactoGET, fetchDatosContactoGETData] = DatosServices.GET()

    const [fetchGuiaProfesionalesGET, fetchGuiaProfesionalesGETData] = DatosProfesionalesServices.GET()

    useEffect(() => {

        fetchDatosContactoGET({
            params: {
                'tipo-direccion': 'CONTACT'
            }
        })

        fetchGuiaProfesionalesGET()

    }, [])


    useEffect(() => {
        if (fetchDatosContactoGETData.status === 200) {

            handleFormDatosContacto({
                ...formDatosContacto,
                ...fetchDatosContactoGETData.data?.items?.[0]
            })
        }
    }, [fetchDatosContactoGETData])


    useEffect(() => {

        if (fetchGuiaProfesionalesGETData.status === 200) {

            handleFormGuiaProfesionales({
                ...fetchGuiaProfesionalesGETData.data,
                direccion__idpais: 3
            })

            handleFormSello({
                visible: fetchGuiaProfesionalesGETData.data?.visible ? ['visible'] : []
            })
        }

    }, [fetchGuiaProfesionalesGETData])


    const [fetchFamiliasGET, fetchFamiliasGETData] = FamiliasServices.GET()

    useEffect(() => {
        fetchPaisesGET()
        fetchFamiliasGET()
    }, [])

    // ---------------- Validaciones

    const [validComunicacion, handleValidationComunicacion] = useValidator({
        datosContacto__movil: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    const [isOkImage, setisOkImage] = useState(true)

    const handleValidationImage = () => {

        if (InputFile?.length === 0) {
            setisOkImage(false)
        } else {
            setisOkImage(true)
        }

        return InputFile?.length > 0
    }

    const [fetchGPImagenPOST, fetchGPImagenPOSTData] = DatosProfesionalesServices.POST_DOCUMENTO()

    useEffect(() => {

        if (String(fetchGPImagenPOSTData.status)?.[0] === '2') {

            fetchGuiaProfesionalesGET()
        }

    }, [fetchGPImagenPOSTData])

    // Modal Rercortar imagen

    const [InputFile, setInputFile] = useState<any>([])

    const [showModalCropImage, setShowModalCropImage] = useState({
        value: false,
        payload: null
    });

    const handleCloseModalCropImage = () => setShowModalCropImage({
        value: false,
        payload: null
    })

    const handleShowModalCropImage = (payload: any) => setShowModalCropImage({
        value: true,
        payload
    });

    const handleChangeInputImage = (e: any) => {

        if (e?.target?.files?.length > 0) {

            if (e.target.files[0]?.size > MaxSizeMB) {

                handleShowModal_InfoError({})
                e.target.value = null

            } else {

                handleShowModalCropImage(e.target.files[0])
            }
        }
    }

    // const [fetchPersonaDatGenPOST_SUBIR_IMAGEN,
    //     fetchPersonaDatGenPOST_SUBIR_IMAGENData,
    //     resetfetchPersonaDatGenPOST_SUBIR_IMAGEN] = PersonaDatosGeneralesServices.POST_SUBIR_IMAGEN(id)


    const handleSubirImagen = (tipo: any, file: any) => {

        // fetchPersonaDatGenPOST_SUBIR_IMAGEN({
        //     body: {
        //         tipo,
        //         imagen: file
        //     }
        // })

        fetchGPImagenPOST({
            params: {
                tipo
            },
            body: {
                adjunto: file
            }
        })
    }

    const handleEliminarImagen = (tipo: any) => {

        // fetchPersonaDatGenDELETE_IMAGEN({
        //     body: {
        //         tipo
        //     }
        // })
        fetchGPDocumentoDELETE({
            params: {
                tipo
            }
        })
    }


    // Modal Rercortar imagen

    const [InputFile2, setInputFile2] = useState<any>([])

    const [showModalCropImage2, setShowModalCropImage2] = useState({
        value: false,
        payload: null
    });

    const handleCloseModalCropImage2 = () => setShowModalCropImage2({
        value: false,
        payload: null
    })

    const handleShowModalCropImage2 = (payload: any) => setShowModalCropImage2({
        value: true,
        payload
    });

    const handleChangeInputImage2 = (e: any) => {

        if (e?.target?.files?.length > 0) {

            if (e.target.files[0]?.size > MaxSizeMB) {

                handleShowModal_InfoError({})
                e.target.value = null

            } else {

                handleShowModalCropImage2(e.target.files[0])
            }
        }
    }

    // const [fetchPersonaDatGenPOST_SUBIR_IMAGEN,
    //     fetchPersonaDatGenPOST_SUBIR_IMAGENData,
    //     resetfetchPersonaDatGenPOST_SUBIR_IMAGEN] = PersonaDatosGeneralesServices.POST_SUBIR_IMAGEN(id)


    const handleSubirImagen2 = (tipo: any, file: any) => {

        // fetchPersonaDatGenPOST_SUBIR_IMAGEN({
        //     body: {
        //         tipo,
        //         imagen: file
        //     }
        // })

        fetchGPImagenPOST({
            params: {
                tipo
            },
            body: {
                adjunto: file
            }
        })
    }

    const handleEliminarImagen2 = (tipo: any) => {

        fetchGPDocumentoDELETE({
            params: {
                tipo
            }
        })
    }

    const handleChangeSelectFamilia = (e: any, index: number) => {

        const formGuiaProfesionalesTemp = cloneDeep(formGuiaProfesionales)

        formGuiaProfesionalesTemp.actividades[index].idFamilia = e.target.value

        handleFormGuiaProfesionales({
            ...formGuiaProfesionales,
            actividades: [...formGuiaProfesionalesTemp.actividades],
        })

    }

    const handleChangeSelectSubFamilia = (e: any, index: number) => {

        const formGuiaProfesionalesTemp = cloneDeep(formGuiaProfesionales)

        formGuiaProfesionalesTemp.actividades[index].id = e.target.value

        handleFormGuiaProfesionales({
            ...formGuiaProfesionales,
            actividades: [...formGuiaProfesionalesTemp.actividades],
        })
    }

    const handleAgregarActividad = () => {

        handleFormGuiaProfesionales({
            ...formGuiaProfesionales,
            actividades: [...formGuiaProfesionales.actividades, { idFamilia: '', id: '' }],
        })
    }

    const handleDeleteActividad = (e: any, index: number) => {

        const formGuiaProfesionalesTemp = cloneDeep(formGuiaProfesionales)

        formGuiaProfesionalesTemp.actividades.splice(index, 1)

        handleFormGuiaProfesionales({
            ...formGuiaProfesionales,
            actividades: [...formGuiaProfesionalesTemp.actividades],
        })
    }

    // ------------------------ Ubicaciones

    const [activeEspana, setactiveEspana] = useState(false)

    const [fetchPoblacionesGET, fetchPoblacionesGETData] = PoblacionesServices.GET()

    const [fetchPaisesGET, fetchPaisesGETData] = PaisesServices.GET()

    useEffect(() => {

        if (formGuiaProfesionales.direccion__idpais === 3) {

            setactiveEspana(true)

            if (formGuiaProfesionales.direccion__codigoPostal?.length === 5) {

                fetchPoblacionesGET({
                    params: {
                        page: 0,
                        size: 200,
                        cpostal: formGuiaProfesionales.direccion__codigoPostal,
                    }
                })

            }

        } else {
            setactiveEspana(false)

            handleFormGuiaProfesionales({
                ...formGuiaProfesionales,
                direccion__poblacion: '',
                direccion__provincia: '',
                direccion__comarca: '',
            })
        }

    }, [formGuiaProfesionales.direccion__codigoPostal, formGuiaProfesionales.direccion__idpais])

    const handleMiddlewareSelectPoblacion = (e: any) => {

        const poblacionesTemp = [...fetchPoblacionesGETData.data?.elementos]

        const resp = poblacionesTemp.find(item => item.poblacion === e.target.value)

        handleFormGuiaProfesionales({
            ...formGuiaProfesionales,
            direccion__poblacion: resp?.poblacion || '',
            direccion__provincia: resp?.provincia || '',
            direccion__comarca: resp?.comarca || '',
        })
    }

    // ------------------------ End Ubicaciones

    const [formSello, handleInputSello, handleFormSello, handleResetFormSello] = useForm({
        visible: []
    })


    // Funciones helpers

    const handleChangeCheck = (e: any, name: string) => {

        let listaModulosTemp = cloneDeep(formSello[name])

        if (e.target.checked) {

            listaModulosTemp.push('visible')

        } else {

            listaModulosTemp = listaModulosTemp.filter((item: any) => item !== 'visible')
        }

        handleFormSello({
            ...formSello,
            [name]: listaModulosTemp
        })
    }


    // Actualizacion de datos de contacto

    const [fetchGuiaProfesionalesPOST, fetchGuiaProfesionalesPOSTData] = DatosProfesionalesServices.POST()

    const [fetchGuiaProfesionalesPUT, fetchGuiaProfesionalesPUTData] = DatosProfesionalesServices.PUT()

    const handleSubmitDatosPOSTPUT = (fetchData: any, darDeBaja: boolean) => {

        const body = {
            ...formGuiaProfesionales,
            visible: darDeBaja ? false : (formSello.visible?.length === 1 ? true : false),
            direccion__pais: fetchPaisesGETData.data?.find((item: any) => item.id === formGuiaProfesionales.direccion__idpais)?.nombre || '',
            direccion__provincia: fetchPoblacionesGETData.data?.elementos?.length > 0 ? formGuiaProfesionales.direccion__provincia : '',
        }

        if (fetchData.status === 404) {

            fetchGuiaProfesionalesPOST({
                body
            })

        } else {

            fetchGuiaProfesionalesPUT({
                body
            })
        }
    }

    // DELETE de archivos adjuntos

    const [fetchGPDocumentoDELETE, fetchGPDocumentoDELETEData] = DatosProfesionalesServices.DELETE_DOCUMENTO()

    // ------------ POST de archivos adjuntos

    const [fetchGPDocumentoPOST, fetchGPDocumentoPOSTData] = DatosProfesionalesServices.POST_DOCUMENTO()


    useEffect(() => {

        if (String(fetchGPDocumentoPOSTData.status)?.[0] === '2') {

            fetchGuiaProfesionalesGET()
        }

    }, [fetchGPDocumentoPOSTData])

    // fetchGuiaProfesionalesGET()

    useEffect(() => {

        if (String(fetchGuiaProfesionalesPOSTData.status)?.[0] === '2') {

            fetchGuiaProfesionalesGET()
        }

    }, [fetchGuiaProfesionalesPOSTData])

    useEffect(() => {

        if (String(fetchGuiaProfesionalesPUTData.status)?.[0] === '2') {

            fetchGuiaProfesionalesGET()
        }

    }, [fetchGuiaProfesionalesPUTData])

    const handleSubmitDocumento = (tipo: string) => {

        fetchGPDocumentoPOST({
            params: {
                tipo
            },
            body: {
                adjunto: inputFileFichero.current?.files[0]
            }
        })
    }

    const inputFileFichero: any = useRef(null)

    const handleFileChange = (e: any) => {

        if (e?.target?.files?.length > 0) {

            if (e.target.files[0]?.size > MaxSizeMB__CV) {

                handleShowModal_InfoError({})
                e.target.value = null

            } else {

                fetchGPDocumentoPOST({
                    params: {
                        tipo: 'CV'
                    },
                    body: {
                        adjunto: e.target.files[0]
                    }
                })
            }
        }
    };

    const handleDeleteAdjunto = () => {
        handleFormGuiaProfesionales({
            ...formGuiaProfesionales,
            curriculumUrl: ''
        })

        fetchGPDocumentoDELETE({
            params: {
                tipo: 'CV'
            }
        })
    }

    // Try: asignando select de pais by nombre

    useEffect(() => {

        if (String(fetchPaisesGETData.status)?.[0] === '2' && formGuiaProfesionales.direccion__pais) {
            handleFormGuiaProfesionales({
                ...formGuiaProfesionales,
                direccion__idpais: fetchPaisesGETData.data?.find((item: any) => item.nombre === formGuiaProfesionales.direccion__pais)?.id || ''
            })
        }

    }, [fetchPaisesGETData, formGuiaProfesionales.direccion__pais])

    // ---------- Modal Info Error

    const [dataModal_InfoError, setDataModal_InfoError] = useState({
        value: false,
        payload: null
    });

    const handleCloseModal_InfoError = () => setDataModal_InfoError({
        value: false,
        payload: null
    })

    const handleShowModal_InfoError = (payload: any) => setDataModal_InfoError({
        value: true,
        payload
    });

    // Seccion comarcas

    const [fetchComarcasGET, fetchComarcasGETData] = ComarcasServices.GET()

    useEffect(() => {
        fetchComarcasGET()
    }, []);

    const handleChangeSelectComarca = (e: any, index: number) => {

        const formGuiaProfesionalesTemp = cloneDeep(formGuiaProfesionales)

        formGuiaProfesionalesTemp.comarcasPreferentes[index].id = e.target.value

        handleFormGuiaProfesionales({
            ...formGuiaProfesionales,
            comarcasPreferentes: [...formGuiaProfesionalesTemp.comarcasPreferentes],
        })

    }

    const handleAgregarComarca = () => {

        handleFormGuiaProfesionales({
            ...formGuiaProfesionales,
            comarcasPreferentes: [...formGuiaProfesionales.comarcasPreferentes, { id: '' }],
        })
    }

    const handleDeleteComarca = (e: any, index: number) => {

        const formGuiaProfesionalesTemp = cloneDeep(formGuiaProfesionales)

        formGuiaProfesionalesTemp.comarcasPreferentes.splice(index, 1)

        handleFormGuiaProfesionales({
            ...formGuiaProfesionales,
            comarcasPreferentes: [...formGuiaProfesionalesTemp.comarcasPreferentes],
        })
    }


    // ---------- Modal Info Error - Al menos una comarca

    const [dataModal_InfoComarca, setDataModal_InfoComarca] = useState({
        value: false,
        payload: null
    });

    const handleCloseModal_InfoComarca = () => setDataModal_InfoComarca({
        value: false,
        payload: null
    })

    const handleShowModal_InfoComarca = (payload: any) => setDataModal_InfoComarca({
        value: true,
        payload
    });

    // Eliminando todas los datos

    const [fetchGPDocumentoAllDELETE, fetchGPDocumentoAllDELETEData] = DatosProfesionalesServices.DELETE_DOCUMENTO()
    const [fetchGPFotoAllDELETE, fetchGPFotoAllDELETEData] = DatosProfesionalesServices.DELETE_DOCUMENTO()
    const [fetchGPLogoAllDELETE, fetchGPLogoAllDELETEData] = DatosProfesionalesServices.DELETE_DOCUMENTO()

    const handleEliminarDatos = () => {

        fetchGPDocumentoAllDELETE({
            params: {
                tipo: 'CV'
            }
        })
    }

    useEffect(() => {
        if (fetchGPDocumentoAllDELETEData.status) {
            setInputFile([])
            fetchGPFotoAllDELETE({
                params: {
                    tipo: 'FOTO'
                }
            })
        }
    }, [fetchGPDocumentoAllDELETEData]);

    useEffect(() => {
        if (fetchGPFotoAllDELETEData.status) {
            setInputFile2([])
            fetchGPLogoAllDELETE({
                params: {
                    tipo: 'LOGO'
                }
            })
        }
    }, [fetchGPFotoAllDELETEData]);

    useEffect(() => {
        if (fetchGPLogoAllDELETEData.status) {

            const body = {
                actividades: [],
                comarcasPreferentes: [],
                poblacion: '',
                provincia: '',
                direccion__codigoPostal: '',
                direccion__idpais: '',
                nombreEmpresa: '',
                presentacion: '',
                enlace: '',
                // visible: [],

                visible: false,
                direccion__pais: '',
                direccion__provincia: '',
            }

            fetchGuiaProfesionalesPUT({
                body
            })

        }
    }, [fetchGPLogoAllDELETEData]);


    return (

        <div>
            <BreadCrumbCustom items={BreadcrumbLineItems} />

            <section className='LayoutPrimary__contentmain'>

                <Box>
                    <Grid container spacing={3} justifyContent="center" >

                        <Grid item xs={12} lg={12} xl={11} className='mb-4 pb-2 xxl-8'>
                            <Paper className='p-3 p-md-4'>

                                <Typography variant='h6' className="my-0 pb-2 fw-500 color-primary text-center">
                                    Actualització de les dades de la Guia de Professionals
                                </Typography>

                                <Typography variant='body1' className="mt-3">
                                    La Guia de Professionals del Col·legi és un servei públic d'accés als col·legiats que han autoritzat a publicar les seves dades de contacte professionals i les activitats que desenvolupen.
                                </Typography>

                                <Typography variant='body1' className="mt-3 mb-4">
                                    Aquest servei s'ofereix exclusivament a través del web corporatiu <a href="https://www.enginyersbcn.cat" className='color-primary'>www.enginyersbcn.cat</a>.
                                </Typography>

                                <Grid container spacing={3}>

                                    <Grid item xs={12} className=''>
                                        <div className="border-top"></div>
                                    </Grid>

                                    <Grid item lg={9}>

                                        <Grid container spacing={3}>

                                            <Grid item xs={12} className='pt-3'>
                                                <Typography variant='body1' className="fw-500 color-primary">
                                                    Adreça de contacte
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6} lg={3} xl={3}>
                                                <InputText
                                                    label={Lang('NUMERO_COLEGIADO')}
                                                    value={formDatosContacto.persCodiCole}
                                                    readOnly
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} lg={5} xl={4}>
                                                <InputText
                                                    label={Lang('COGNOMS')}
                                                    value={formDatosContacto.apellidos}
                                                    readOnly
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} lg={4} xl={3}>
                                                <InputText
                                                    label={Lang('NOMS')}
                                                    value={formDatosContacto.nombre}
                                                    readOnly
                                                />
                                            </Grid>

                                            <Grid item xs={12} className='p-0'></Grid>

                                            <Grid item xs={12} lg={7} xl={6}>
                                                <InputText
                                                    label={Lang('DOMICILIO')}
                                                    name='direccion__direccion'
                                                    value={formGuiaProfesionales.direccion__direccion}
                                                    onChange={handleInputGuiaProfesionales}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6} lg={5} xl={4}>
                                                <InputText
                                                    label={Lang('CODIGO_POSTAL')}
                                                    name='direccion__codigoPostal'
                                                    value={formGuiaProfesionales.direccion__codigoPostal}
                                                    onChange={handleInputGuiaProfesionales}
                                                    type='number'
                                                />
                                            </Grid>


                                            {
                                                (activeEspana && fetchPoblacionesGETData.data?.elementos?.length > 0) ?
                                                    <Grid item xs={12} md={6} lg={6} xl={4}>
                                                        <InputSelect
                                                            label={Lang('POBLACION')}
                                                            name='direccion__poblacion'
                                                            onChange={handleMiddlewareSelectPoblacion}
                                                            value={formGuiaProfesionales.direccion__poblacion}
                                                            values={fetchPoblacionesGETData.data?.elementos?.map((item: any) => {
                                                                return {
                                                                    id: item.poblacion,
                                                                    name: item.poblacion
                                                                }
                                                            }) || []}
                                                        />
                                                    </Grid>
                                                    :
                                                    <Grid item xs={12} md={6} lg={6} xl={4}>
                                                        <InputText
                                                            label={Lang('POBLACION')}
                                                            name='direccion__poblacion'
                                                            value={formGuiaProfesionales.direccion__poblacion}
                                                            onChange={handleInputGuiaProfesionales}
                                                            disabled
                                                        />
                                                    </Grid>
                                            }

                                            <Grid item xs={12} md={6} lg={6} xl={4}>
                                                <InputText
                                                    label={Lang('COMARCA')}
                                                    name='direccion__comarca'
                                                    value={formGuiaProfesionales.direccion__comarca}
                                                    onChange={handleInputGuiaProfesionales}
                                                    disabled
                                                />

                                            </Grid>

                                        </Grid>

                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>

                                        <Grid item xs={12}>

                                            <InputImage
                                                label='Fotografia del col·legiat:'
                                                width='100%'
                                                height='13rem'
                                                onChange={handleChangeInputImage}
                                                InputFile={InputFile}
                                                setInputFile={setInputFile}
                                                urlImage={formGuiaProfesionales.fotoPersonalUrl}
                                                handleEliminarImagen={() => handleEliminarImagen('FOTO')}
                                            />
                                            <small className='text-center mt-2 d-block'>Màx. {label__MaxSizeMB}.</small>
                                            {
                                                !isOkImage && <span className='d-block mt-1' style={{ 'color': '#d32f2f' }}>{Lang('VALID_EXISTE_CAMPO_DEFAULT')}</span>
                                            }

                                        </Grid>

                                    </Grid>

                                    <Grid item xs={12}></Grid>

                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <InputText
                                            label={Lang('TELEFONO_MOVIL')}
                                            name='datosContacto__movil'
                                            value={formGuiaProfesionales.datosContacto__movil}
                                            onChange={handleInputGuiaProfesionales}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <InputText
                                            label={Lang('OTRO_TELEFONO')}
                                            name='datosContacto__telefono'
                                            value={formGuiaProfesionales.datosContacto__telefono}
                                            onChange={handleInputGuiaProfesionales}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} lg={4} xl={3}>
                                        <InputText label={Lang('TELEFONO_2')} />
                                    </Grid> */}


                                    {/* <Grid item xs={12} lg={4} xl={3}>
                                        <InputText label={Lang('FAX')} />
                                    </Grid> */}

                                    <Grid item xs={12} md={8} lg={6} xl={4}>
                                        <InputText
                                            label={Lang('EMAIL')}
                                            name='datosContacto__email'
                                            value={formGuiaProfesionales.datosContacto__email}
                                            onChange={handleInputGuiaProfesionales}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} lg={6} xl={4}>
                                        <InputText label={Lang('WEB')} />
                                    </Grid> */}


                                    {/* <Grid item xs={12} className=''>
                                        <div className="border-top"></div>
                                    </Grid> */}

                                    <Grid item xs={12} className='pt-3 mt-3'>
                                        <Typography variant='body1' className="fw-500 color-primary">
                                            Dades professionals
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} xl={12} className='pt-3'>
                                        <InputTextarea
                                            label={Lang('PRESENTACION')}
                                            className='pt-0'
                                            rows={4}
                                            maxLength={1024}
                                            name='presentacion'
                                            onChange={handleInputGuiaProfesionales}
                                            value={formGuiaProfesionales.presentacion}
                                        />
                                        <small className='mt-2 d-block'>Màx. 1024 caràcters de text.</small>

                                    </Grid>

                                    <Grid item xs={12} lg={7} xl={7}>
                                        <InputText
                                            label='+Info a:'
                                            name='enlace'
                                            onChange={handleInputGuiaProfesionales}
                                            value={formGuiaProfesionales.enlace}
                                        />
                                        <small>Indica l'adreça web de la teva empresa o del teu perfil a xarxes (recomanem fer ús del perfil de Linkedin, com a xarxa professional, àmpliament reconeguda)</small>
                                    </Grid>

                                    <Grid item xs={12} className='p-0'></Grid>

                                    <Grid item xs={12} lg={4}>
                                        <section className='d-flex justify-content-between mb-2'>
                                            <span>CV (PDF):</span>
                                        </section>
                                        {
                                            formGuiaProfesionales.curriculumUrl ?
                                                <ListFiles
                                                    list={[{
                                                        titulo: 'Currículum',
                                                        url: formGuiaProfesionales.curriculumUrl
                                                    }]}
                                                    isBlob={false}
                                                    onClickDelete={() => handleDeleteAdjunto()}
                                                    extensionDefault='pdf'
                                                /> :
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    color="secondary"
                                                >
                                                    <FontAwesomeIcon icon={faFilePdf} className='mr-2' /> Adjuntar CV
                                                    <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        hidden
                                                        ref={inputFileFichero}
                                                        onChange={handleFileChange}
                                                    />
                                                </Button>
                                        }
                                        <small className='mt-2 d-block'>Màx. {label__MaxSizeMB__CV}.</small>

                                    </Grid>

                                    <Grid item xs={12}></Grid>

                                    <Grid item xs={12} xl={9}>
                                        <InputTextarea
                                            label={Lang('EMPRESAS')}
                                            rows={2}
                                            name='nombreEmpresa'
                                            onChange={handleInputGuiaProfesionales}
                                            value={formGuiaProfesionales.nombreEmpresa}
                                        />
                                        <small>Indica el nom de la teva empresa o empreses i afegeix el logotip, si ho desitges. Els usuaris que realitzin una cerca podran veure aquesta informació.</small>
                                    </Grid>

                                    <Grid item xs={12} md={6} xl={3}>

                                        <Grid item xs={12}>

                                            <InputImage
                                                label='Logo empresa:'
                                                width='100%'
                                                height='13rem'
                                                onChange={handleChangeInputImage2}
                                                InputFile={InputFile2}
                                                setInputFile={setInputFile2}
                                                urlImage={formGuiaProfesionales.logoEmpresaUrl}
                                                handleEliminarImagen={() => handleEliminarImagen2('LOGO')}
                                            />
                                            <small className='text-center mt-2 d-block'>Màx. {label__MaxSizeMB}.</small>
                                            {
                                                !isOkImage && <span className='d-block mt-1' style={{ 'color': '#d32f2f' }}>{Lang('VALID_EXISTE_CAMPO_DEFAULT')}</span>
                                            }

                                        </Grid>

                                    </Grid>

                                    <Grid item xs={12} className='pt-3 mt-3'>
                                        <Typography variant='body1' className="fw-500 color-primary">
                                            Activitats professionals
                                        </Typography>
                                        <small>Si vols aparèixer a la Guia de Professionals, afegeix les activitats professionals que desenvolupes. Selecciona un camp de la família principal i tria l’especialitat dins la subfamília.
                                            <br />

                                            Fes clic al botó AFEGIR ACTIVITAT per anar afegint els tipus de feina que pots oferir.</small>
                                    </Grid>

                                    <Grid item xs={12}>

                                        {
                                            formGuiaProfesionales.actividades?.map((item: any, index: number) => {
                                                return (
                                                    <Grid container spacing={3} alignItems='center' className='mb-3'>

                                                        <Grid item xs={12} lg={4} xl={4}>

                                                            <InputSelect
                                                                label='Familia'
                                                                name='familia'
                                                                onChange={(e: React.SyntheticEvent) => handleChangeSelectFamilia(e, index)}
                                                                value={item.idFamilia}
                                                                values={fetchFamiliasGETData.data?.map((item: any) => {
                                                                    return {
                                                                        id: item.id,
                                                                        name: item.familia
                                                                    }
                                                                }) || []}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} lg={4} xl={5}>
                                                            <InputSelect
                                                                label='Subfamilia'
                                                                value={item.id}
                                                                onChange={(e: React.SyntheticEvent) => handleChangeSelectSubFamilia(e, index)}
                                                                values={fetchFamiliasGETData.data?.find((itemF: any) => itemF.id === item.idFamilia)?.subfamilias?.map((item2: any) => {
                                                                    return {
                                                                        id: item2.id,
                                                                        name: item2.actividadProfesional
                                                                    }
                                                                }) || []}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} lg={2} xl={1}>
                                                            <Button color="primary" variant="contained" onClick={(e) => handleDeleteActividad(e, index)}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </Button>

                                                        </Grid>

                                                    </Grid>
                                                )
                                            })
                                        }

                                        {
                                            formGuiaProfesionales.actividades?.length === 0 &&
                                            <Grid container spacing={3} alignItems='center'>

                                                <Grid item xs={12}>

                                                    <div style={{ backgroundColor: '#f3f3f3' }} className='text-center'>

                                                        <h4 className='m-0 py-4'>No hi ha dades per mostrar</h4>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        }

                                    </Grid>

                                    <Grid item xs={12}>

                                        <Grid container spacing={3}>

                                            <Grid item xs={12}>

                                                <div className="d-flex justify-content-end">

                                                    <Button color="secondary" variant="contained" onClick={handleAgregarActividad}>
                                                        <FontAwesomeIcon icon={faPlus} className='mr-2' /> Afegir activitat
                                                    </Button>
                                                </div>

                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* Seccion comarcas */}

                                    <Grid item xs={12} className='pt-3 mt-3'>
                                        <Typography variant='body1' className="fw-500 color-primary">
                                            On ofereixo els meus serveis?
                                        </Typography>
                                        <small>
                                            Selecciona les comarques on vols oferir els teus serveis com a enginyer/a.
                                            <br />
                                            Fes clic a afegir comarca, cal que seleccionis com mínim una comarca de la llista.
                                            <br />
                                            Tingues present que la cerca per territori en el Web corporatiu no és obligatòria. Només s’aplicarà aquest filtre, si l’usuari que busca un enginyer col·legiat així ho decideix”.
                                        </small>
                                    </Grid>

                                    <Grid item xs={12}>

                                        {
                                            formGuiaProfesionales.comarcasPreferentes?.map((item: any, index: number) => {
                                                return (
                                                    <Grid container spacing={3} alignItems='center' justifyContent='center' className='mb-3'>

                                                        <Grid item xs={12} lg={4} xl={4}>

                                                            <InputSelect
                                                                label='Comarca'
                                                                name='comarca'
                                                                onChange={(e: React.SyntheticEvent) => handleChangeSelectComarca(e, index)}
                                                                value={item.id}
                                                                values={fetchComarcasGETData.data?.map((item: any) => {
                                                                    return {
                                                                        id: item.id,
                                                                        name: item.nombre
                                                                    }
                                                                }) || []}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} lg={2} xl={1}>
                                                            <Button color="primary" variant="contained" onClick={(e) => handleDeleteComarca(e, index)}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </Button>

                                                        </Grid>

                                                    </Grid>
                                                )
                                            })
                                        }

                                        {
                                            formGuiaProfesionales.comarcasPreferentes?.length === 0 &&
                                            <Grid container spacing={3} alignItems='center'>

                                                <Grid item xs={12}>

                                                    <div style={{ backgroundColor: '#f3f3f3' }} className='text-center'>

                                                        <h4 className='m-0 py-4'>No hi ha dades per mostrar</h4>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        }

                                    </Grid>


                                    <Grid item xs={12}>

                                        <Grid container spacing={3}>

                                            <Grid item xs={12}>

                                                <div className="d-flex justify-content-end">

                                                    <Button color="secondary" variant="contained" onClick={handleAgregarComarca}>
                                                        <FontAwesomeIcon icon={faPlus} className='mr-2' /> Afegir comarca
                                                    </Button>
                                                </div>

                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} className='py-0 mt-4'>
                                        <div className="border-top"></div>
                                    </Grid>

                                </Grid>
                                <div className='d-flex mt-3'>
                                    <InputChecks
                                        values={[
                                            {
                                                id: 0,
                                                checked: formSello.visible?.length > 0,
                                                label:
                                                    <>
                                                        <Typography variant='body2'>
                                                            Desitjo formar part de la Guia de professionals, amb les feines indicades en aquest formulari i accepto les condicions d’ús del servei.
                                                        </Typography>
                                                    </>
                                            }
                                        ]}
                                        onChange={(e: any) => handleChangeCheck(e, 'visible')}
                                    />
                                </div>

                                <div className="d-flex justify-content-between flex-wrap mt-4 mb-4">

                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => formGuiaProfesionales.comarcasPreferentes?.[0]?.id ? handleSubmitDatosPOSTPUT(fetchGuiaProfesionalesGETData, false) : handleShowModal_InfoComarca({})}
                                        disabled={formSello.visible?.length === 0}
                                    >
                                        {Lang('ACTUALIZAR_DATOS')}
                                    </Button>
                                    <div>
                                        {
                                            fetchGuiaProfesionalesGETData?.data?.visible &&
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={() => handleSubmitDatosPOSTPUT(fetchGuiaProfesionalesGETData, true)}
                                            >
                                                Desactivació temporal
                                            </Button>
                                        }
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            className='ml-2'
                                            onClick={handleEliminarDatos}
                                        >
                                            Baixa de la Guia
                                        </Button>
                                    </div>


                                </div>

                                <Typography variant='body2' className="mt-4">
                                    <strong>Condicions d’inscripció a la Guia de professionals:</strong>
                                </Typography>
                                <Typography variant='body2' className="">
                                    <br />
                                    És imprescindible emplenar les dades professionals públiques.
                                    <br />

                                    Els requisits d’aquest servei són estar donat d’alta com a col·legiat i en situació professional actiu.
                                    <br />

                                    Per formar part de la Guia de Professionals també s’ha d’emplenar com a mínim una feina de la fitxa d’activitats.
                                    <br />

                                    Les activitats professionals codificades en aquest registre serviran per poder elaborar la Guia de Professionals.
                                    <br />

                                    El col·legiat/da és coneixedor i accepta totes les condicions establertes al present formulari.
                                    <br />

                                    D’acord amb el que estableix la Llei Orgànica 15/1999 de protecció de dades de caràcter personal, informem que les dades professionals seran incloses dins un fitxer automatitzat sota la responsabilitat del COL·LEGI D’ENGINYERS TÈCNICS INDUSTRIALS DE BARCELONA, amb la finalitat de poder inscriure al col·legiat corresponent en el servei de la Guia de Professionals.
                                    <br />

                                    El titular de les dades autoritza al COL·LEGI D’ENGINYERS TÈCNICS INDUSTRIALS DE BARCELONA la cessió d’aquestes dades a la Guia de Professionals i a persones o empreses que ho sol·licitin per a fins professionals.
                                    <br />

                                    Així mateix es compromet a comunicar per escrit al COL·LEGI D’ENGINYERS TÈCNICS INDUSTRIALS DE BARCELONA qualsevol modificació que es produeixi en les dades aportades i podrà, en qualsevol moment, exercir el dret d’accés, rectificació, cancel·lació i oposició segons els termes establerts per la Llei Orgànica 15/1999 al responsable del fitxer, COL·LEGI D’ENGINYERS TÈCNICS INDUSTRIALS DE BARCELONA, amb domicili a CARRER CONSELL DE CENT, 365 08009 BARCELONA.
                                </Typography>

                            </Paper>
                        </Grid>
                    </Grid>
                </Box>

                <ModalCropImage
                    show={showModalCropImage}
                    handleClose={handleCloseModalCropImage}
                    height='500px'
                    setInputFile={setInputFile}
                    handleSubirImagen={handleSubirImagen}
                    tipo='FOTO'
                    escalas={[
                        { x: 10, y: 10, label: 'Cuadrado' }
                    ]}
                />

                <ModalCropImage
                    show={showModalCropImage2}
                    handleClose={handleCloseModalCropImage2}
                    height='500px'
                    setInputFile={setInputFile2}
                    handleSubirImagen={handleSubirImagen2}
                    tipo='LOGO'
                    escalas={[
                        { x: 10, y: 10, label: 'Cuadrado' }
                    ]}
                />

                <ModalStatusServer
                    fetchData={fetchGuiaProfesionalesPOSTData}
                    activeLoading
                    disableStatus
                    messageStatus={
                        {
                            204:
                                <>
                                    <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                    <Typography sx={{ mt: 2 }}>
                                        S'han actualitzat correctament les dades
                                    </Typography>
                                </>
                        }
                    }
                />

                <ModalStatusServer
                    fetchData={fetchGuiaProfesionalesPUTData}
                    activeLoading
                    disableStatus
                    messageStatus={
                        {
                            204:
                                <>
                                    <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                    <Typography sx={{ mt: 2 }}>
                                        S'han actualitzat correctament les dades
                                    </Typography>
                                </>
                        }
                    }
                />

                <ModalStatusServer
                    fetchData={fetchGPDocumentoPOSTData}
                    activeLoading
                    disableStatus
                    messageStatus={
                        {
                            204:
                                <>
                                    <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                    <Typography sx={{ mt: 2 }}>
                                        S'han actualitzat correctament les dades
                                    </Typography>
                                </>
                        }
                    }
                />

                <ModalStatusServer
                    fetchData={fetchGPImagenPOSTData}
                    activeLoading
                    disableStatus
                    messageStatus={
                        {
                            204:
                                <>
                                    <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                    <Typography sx={{ mt: 2 }}>
                                        S'han actualitzat correctament les dades
                                    </Typography>
                                </>,
                            500:
                                <>
                                    <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                    <Typography sx={{ mt: 2 }}>
                                        La imatge ha excedit el pes permès
                                    </Typography>
                                </>
                        }
                    }
                />

                <ModalInfo
                    data={dataModal_InfoError}
                    handleClose={handleCloseModal_InfoError}
                    message={`S'ha excedit el pes permès`}
                />

                <ModalInfo
                    data={dataModal_InfoComarca}
                    handleClose={handleCloseModal_InfoComarca}
                    message={`Heu de seleccionar almenys una comarca`}
                />

            </section>
        </div>
    )
}

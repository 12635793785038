import { styled } from '@mui/material/styles';

import {
    Alert,
    Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup,
    FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { BreadCrumbCustom } from '../../../components/BreadCrumbCustom/BreadCrumbCustom';
import { useLang } from '../../../language';
import { InputText } from '../../../components/Inputs/InputText';
import { InputSelect } from '../../../components/Inputs/InputSelect';

export const AccesoDocumentos = ({ setBreadcrumbLineItems, route }: any) => {

    const Lang = useLang()

    useEffect(() => {

        setBreadcrumbLineItems((item: any) => [item[0], route])

    }, [])


    const handleSubmitDownloadFile = () => {

        window.open('https://documentacio.enginyersbcn.cat/coneixement/docs/serveis-tecnics/declaracio-responsable-registre-de-tecnics-de-licaen', '_blank')?.focus();
    }

    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" >

                <Grid item xs={12} className='mb-4 pb-2'>
                    <Paper className='p-3 p-md-4'>
                        <Typography variant='h6' className="my-0 pb-1 text-center fw-500">
                            Documents accés
                        </Typography>
                        <Typography variant='body1' className="my-2 mb-4">
                            S’haurà d’emplenar i signar el formulari adjunt i enviar-ho per correu electrònic a la següent adreça: <a href="mailto:ebcn@ebcn.cat" className='color-primary'>ebcn@ebcn.cat</a>.
                        </Typography>

                        <div className="d-flex justify-content-center">

                            <Button color="primary" variant="contained" onClick={handleSubmitDownloadFile}>Descarregar formulari</Button>
                        </div>

                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

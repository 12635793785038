import { Box, FormControl, Input, InputLabel, TextField, Typography } from '@mui/material'
import React from 'react'

export const InputTextarea = ({ label, readOnly, value, rows, onChange, name, placeholder, maxLength }: any) => {

    const propsNative: any = {}
    const inputProps: any = {}

    if (maxLength) {
        inputProps.maxLength = maxLength
        propsNative.inputProps = inputProps
    }

    return (
        <FormControl fullWidth variant="standard">
            {
                readOnly ?
                    <>
                        <Typography variant='body2' className='mb-1' component="span">
                            {label}
                        </Typography>
                        <Typography variant='body1' className="my-0 pb-1" component="span">
                            {value}
                        </Typography>
                    </> :
                    <>
                        <TextField
                            id="standard-multiline-static"
                            label={label}
                            multiline
                            rows={rows}
                            value={value}
                            name={name}
                            onChange={onChange}
                            variant="standard"
                            placeholder={placeholder}
                            {...propsNative}
                        />
                    </>
            }
        </FormControl>
    )
}

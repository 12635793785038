import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './routes/AppRouter';
import { RootState } from './store';
import { CookiesProvider, useCookies } from 'react-cookie';
import axios from 'axios';
import { logOut } from './store/slices/auth';
import { TokenServices } from './controllers/services/auth.services';

function App() {

  console.log(process.env.REACT_APP_ENVIRONMENT)

  const { auth } = useSelector((state: RootState) => state.auth)

  console.log(auth);

  // Or Create your Own theme:
  const theme = createTheme({
    palette: {
      primary: {
        main: '#9e0054'
      },
      secondary: {
        main: '#ffc107'
      }
    },
    typography: {
      fontFamily: [
        'Ubuntu',
        'arial',
      ].join(','),
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={process.env.REACT_APP_SUBDOMAIN}>
        <CookiesProvider>

          <AppRouter />
        </CookiesProvider>
      </BrowserRouter>
    </ThemeProvider >
  );
}

export default App;

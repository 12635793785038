import { useState } from 'react';

export const useValidator = (initialState:any) => {

    const [valuesValid, setvaluesValid] = useState(initialState)

    const handleVerifiedValues = (objValues:any) => {

        const valuesValidTemp = { ...valuesValid }

        for (const key in valuesValidTemp) {

            for (let i = 0; i < valuesValidTemp[key].length; i++) {
                
                valuesValidTemp[key][i]['isOk'] = valuesValidTemp[key][i].functionValid ? valuesValidTemp[key][i].functionValid(objValues[key]) : valuesValidTemp[key][i].functionValidMultipleKeys(objValues)
            }
        }

        setvaluesValid({
            ...valuesValidTemp
        })

        const objParse = JSON.stringify(valuesValidTemp)

        if (objParse.indexOf('"isOk":false') === -1)
            return true

        return false
    }

    const resetValuesValid = () => {

        const valuesValidTemp = { ...valuesValid }

        for (const key in valuesValidTemp) {

            valuesValidTemp[key]['isOk'] = true
        }

        setvaluesValid({
            ...valuesValidTemp
        })
    }

    return [valuesValid, handleVerifiedValues, resetValuesValid]

}
import { styled } from '@mui/material/styles';

import { Alert, Box, Button, Grid, Paper, Typography } from "@mui/material"
import { useEffect } from "react"
import { useLang } from '../../../language';
import { InputText } from '../../../components/Inputs/InputText';
import { InputSelect } from '../../../components/Inputs/InputSelect';

export const DatosProfesionales = ({ setBreadcrumbLineItems, route }: any) => {

    const Lang = useLang()

    useEffect(() => {

        setBreadcrumbLineItems( (item: any) => [item[0], route])

    }, [])


    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" >

                <Grid item xs={12} className='mb-4 pb-2'>
                    <Paper className='p-3 p-md-4'>
                        
                        <Alert severity="info" className='mb-3'>
                            El Col·legi us convida a ampliar, de forma voluntària, les vostres dades professionals (sector, àrea, càrrec, etc.) per tal que el Col·legi disposi de més informació d’ús intern sobre els seus col·legiats i poder millorar la comunicació i els serveis que us ofereix.
                        </Alert>
                        
                        <Typography variant='h6' className="my-0 pb-1 fw-500 color-primary text-center">
                            Dades professionals
                        </Typography>

                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <InputText label={Lang('NOMBRE_DE_EMPRESA')} />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputSelect
                                    values={[
                                        { id: '1', name: 'prueba select' }
                                    ]}
                                    label={Lang('SECTOR')}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputSelect
                                    values={[
                                        { id: '1', name: 'prueba select' }
                                    ]}
                                    label={Lang('AREA_DE_aCTUACION')}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputSelect
                                    values={[
                                        { id: '1', name: 'prueba select' }
                                    ]}
                                    label={Lang('POSICION_O_CARGO')}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputText label={Lang('DOMICILIO')} />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputSelect
                                    values={[
                                        { id: '1', name: 'prueba select' }
                                    ]}
                                    label={Lang('POBLACION')}
                                />
                            </Grid>

                        </Grid>

                        <Typography variant='body1' className="mt-4">
                            Si no trobes la població o codi postal, selecciona l’opció <strong>Altres poblacions</strong> i entra les dades directament.
                        </Typography>

                        <Grid container spacing={3}>

                            <Grid item xs={12} lg={4}>
                                <InputText label={Lang('TELEFONO_1')} />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <InputText label={Lang('TELEFONO_2')} />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <InputText label={Lang('TELEFONO_MOVIL')} />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <InputText label={Lang('FAX')} />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputText label={Lang('EMAIL')} />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputText label={Lang('WEB')} />
                            </Grid>

                        </Grid>

                        <Typography variant='body2' className="mt-4">
                            ENGINYERS BCN es compromet a complir amb les disposicions del Reglament 2016/679, de 27 d’abril de 2016, sobre la protecció de les persones físiques pel que fa al tractament de dades personals i a la lliure circulació d’aquestes dades, així com la normativa estatal de protecció de dades vigent.
                        </Typography>

                        <Typography variant='body2' className="mt-3">
                            ENGINYERS BCN s'obliga a mantenir la més estricta reserva i confidencialitat sobre tota la informació a la qual tingui accés, comprometent-se a utilitzar aquesta informació únicament per al desenvolupament dels serveis propis d’aquest col·legi professional.
                        </Typography>

                        <Typography variant='body2' className="mt-3">
                            Podreu accedir, rectificar i suprimir les dades, així com limitar el tractament, oposar-vos al mateix o exercir el dret a la portabilitat de les dades. Aquests drets estan regulats en el Reglament (UE) 679/2016, i poden ser exercits, aportant còpia de DNI, al domicili Carrer Consell de Cent, 365 – 08009 de Barcelona, o a través del següent correu electrònic: protecciodades@ebcn.cat
                        </Typography>

                        <div className="d-flex justify-content-between mt-4">
                            <Button color="primary" variant="contained">{Lang('ACTUALIZAR_DATOS')}</Button>
                            <Button color="primary" variant="outlined">{Lang('BORRAR_DATOS')}</Button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

import { styled } from '@mui/material/styles';

import {
    Alert,
    Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup,
    FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { BreadCrumbCustom } from '../../../components/BreadCrumbCustom/BreadCrumbCustom';
import { useLang } from '../../../language';
import { InputText } from '../../../components/Inputs/InputText';
import { InputSelect } from '../../../components/Inputs/InputSelect';
import { InputRadio } from '../../../components/Inputs/InputRadio';
import { InputTextarea } from '../../../components/Inputs/InputTextarea';
import { useForm } from '../../../hooks/useForm';
import { DatosAdapters } from '../../ActualizacionDatos/controllers/adapters/datos.adapters';
import { useValidator } from '../../../hooks/useValidator';
import { validExiste } from '../../../helpers';
import { ComunicacionServices } from '../../../controllers/services/comunicaciones.services';
import { ModalStatusServer } from '../../../components/ModalStatus/ModalStatusServer';

export const SolicitudCorreoCorporativo = ({ setBreadcrumbLineItems, route, formContact }: any) => {

    const Lang = useLang()

    useEffect(() => {

        setBreadcrumbLineItems((item: any) => [item[0], route])

    }, [])


    const [formSolicitud, handleInputSolicitud, handleFormSolicitud, handleResetFormSolicitud] = useForm({
        ...DatosAdapters.GET.receive.schema(),
        emailDesignado: ''
    })

    useEffect(() => {
        if (formContact.status === 200) {

            handleFormSolicitud({
                ...formContact.data?.items?.[0]
            })
        }
    }, [formContact])


    // ---------------- Validaciones

    const [validComunicacion, handleValidationComunicacion] = useValidator({
        emailDesignado: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    // Envio de solicitud por EMAIL

    const [fetchComunicacionesPOST, fetchComunicacionesPOSTData] = ComunicacionServices.POST()

    const handleSubmitComunicacionPOST = () => {

        if (handleValidationComunicacion(formSolicitud)) {

            const date = new Date();

            const todayDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
            const todayHour = `${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`

            const bodyTemp = {
                // adjunto: new File([""], "filename"),
                comunicacion: {
                    asunto: `[web] Formulari de la web: Alta adreces @ebcn.cat`,
                    cuerpo: `
                    <h3 style="margin-bottom: 15px">Alta adreces @ebcn.cat</h3>

                    <table lang="CA" border="1" width="100%" cellspacing="4" cellpadding="0" style="margin-bottom: 15px">
                        <tbody lang="CA">
                            <tr>
                                <td colspan="2" style="padding: 7px">
                                    <p style="text-align: center; margin: 0">
                                        <strong>Dades personals</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Nom:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.nombre} ${formSolicitud.apellidos}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>DNI:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.persCifNif}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Numero colegiat:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.persCodiCole}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Email desitjat:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.emailDesignado}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Telefon:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.datosContacto__movil}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Email de contacte:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.datosContacto__email}
                                    </p>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    `,
                    remitente: `${formSolicitud.nombre} ${formSolicitud.apellidos}`,
                    cuerpoHTML: true,
                    emailRemitente: `${formSolicitud.datosContacto__email}`,
                    destinatarios: [
                        {
                            email: process.env.REACT_APP_EMAIL_SOLICITUD_CORREO_CORPORATIVO,
                            destinatario: process.env.REACT_APP_BUSINESS_NAME,
                            variables: {}
                        },
                    ]
                }
            }

            fetchComunicacionesPOST({
                body: bodyTemp
            })
        }
    }

    const handleReset2 = () => {

        handleFormSolicitud({
            ...formSolicitud,
            emailDesignado: '',
            datosContacto__movil: '',
            datosContacto__email: '',
        })
    }


    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" >

                <Grid item xs={12} className='mb-4 pb-2'>
                    <Paper className='p-3 p-md-4'>

                        <Typography variant='h6' className="mt-0 mb-3 pb-1 fw-500 color-primary text-center">
                            Alta adreces @ebcn.cat

                        </Typography>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant='body1' className="mt-2 fw-500 color-primary">
                                    Dades personals
                                </Typography>
                            </Grid>


                            <Grid item xs={12} sm={6} lg={4}>
                                <InputText
                                    label={Lang('NOMS')}
                                    name='nombre'
                                    value={formSolicitud.nombre}
                                    onChange={handleInputSolicitud}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={5}>
                                <InputText
                                    label={Lang('COGNOMS')}
                                    name='apellidos'
                                    value={formSolicitud.apellidos}
                                    onChange={handleInputSolicitud}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={3}>
                                <InputText
                                    label='DNI'
                                    name='persCifNif'
                                    value={formSolicitud.persCifNif}
                                    onChange={handleInputSolicitud}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={3}>
                                <InputText
                                    label={Lang('NUMERO_COLEGIADO')}
                                    name='persCodiCole'
                                    value={formSolicitud.persCodiCole}
                                    onChange={handleInputSolicitud}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} className='p-0'></Grid>

                            <Grid item xs={12} lg={6}>
                                <InputText
                                    label='E-mail desitjat (combinació de nom i cognoms)'
                                    name='emailDesignado'
                                    value={formSolicitud.emailDesignado}
                                    onChange={handleInputSolicitud}
                                    localValidation={validComunicacion.emailDesignado}
                                />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <InputText
                                    label='Telèfon de contacte'
                                    name='datosContacto__movil'
                                    value={formSolicitud.datosContacto__movil}
                                    onChange={handleInputSolicitud}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputText
                                    label='Email de contacte'
                                    name='datosContacto__email'
                                    value={formSolicitud.datosContacto__email}
                                    onChange={handleInputSolicitud}
                                />
                            </Grid>

                            <Grid item xs={12} className='p-0'></Grid>

                        </Grid>

                        <div className="d-flex justify-content-between mt-4">
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSubmitComunicacionPOST}
                            >
                                {Lang('ENVIAR')}
                            </Button>
                            <Button color="primary" variant="outlined" onClick={handleReset2}>{Lang('LIMPIAR')}</Button>
                        </div>

                        <div className="mt-3">
                            <a href="https://documentacio.enginyersbcn.cat/coneixement/docs/el-collegi/configuracio-de-ebcncat" target='_blank' rel="noreferrer" className='color-primary'>Manual de configuració de @ebcn.cat</a>.
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <ModalStatusServer
                fetchData={fetchComunicacionesPOSTData}
                activeLoading
                disableStatus
                messageStatus={
                    {
                        201:
                            <>
                                <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                <Typography sx={{ mt: 2 }}>
                                    S'ha enviat correctament la sol·licitud
                                </Typography>
                            </>
                    }
                }
            />
        </Box>
    )
}

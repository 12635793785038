import { styled } from '@mui/material/styles';

import {
    Alert,
    Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup,
    FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCoffee, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { BreadCrumbCustom } from '../../../components/BreadCrumbCustom/BreadCrumbCustom';
import { useLang } from '../../../language';
import { InputTextarea } from '../../../components/Inputs/InputTextarea';
import { InputText } from '../../../components/Inputs/InputText';
import { InputSelect } from '../../../components/Inputs/InputSelect';
import { InputRadio } from '../../../components/Inputs/InputRadio';
import { InputChecks } from '../../../components/Inputs/InputChecks';
import { useForm } from '../../../hooks/useForm';
import { DatosAdapters } from '../../ActualizacionDatos/controllers/adapters/datos.adapters';
import { cloneDeep } from 'lodash';
import { useValidator } from '../../../hooks/useValidator';
import { validCorrectEmail, validExiste } from '../../../helpers';
import { SellosServices } from '../controllers/services/sellos.services';
import { ComunicacionServices } from '../../../controllers/services/comunicaciones.services';
import { ModalStatusServer } from '../../../components/ModalStatus/ModalStatusServer';

export const SelloSociedadesProfesionales = ({ setBreadcrumbLineItems, route, formContact }: any) => {

    const Lang = useLang()

    useEffect(() => {

        setBreadcrumbLineItems((item: any) => [item[0], route])

    }, [])

    const [formDatosContacto, handleInputDatosContacto, handleFormDatosContacto, handleResetFormDatosContacto] = useForm({
        ...DatosAdapters.GET.receive.schema(),
        nombreSociedad: '',
    })

    // ---------------- Validaciones

    useEffect(() => {
        if (formContact.status === 200) {

            handleFormDatosContacto({
                ...formDatosContacto,
                ...formContact.data?.items?.[0]
            })
        }
    }, [formContact])

    const [formSello, handleInputSello, handleFormSello, handleResetFormSello] = useForm({
        lopd: []
    })

    // Funciones helpers

    const handleChangeCheck = (e: any, name: string) => {

        let listaModulosTemp = cloneDeep(formSello[name])

        if (e.target.checked) {

            listaModulosTemp.push(e.target.name)

        } else {

            listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormSello({
            ...formSello,
            [name]: listaModulosTemp
        })
    }

    // ---------------- Validaciones

    const [validSello, handleValidationSello] = useValidator({
        nombreSociedad: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_NOMBRE_SOCIEDAD'), isOk: true },
        ],
        datosContacto__email: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
            { functionValid: validCorrectEmail, msgError: Lang('VALID_CORRECT_MOVIL'), isOk: true },
        ],
    })

    const [fetchComunicacionesPOST, fetchComunicacionesPOSTData] = ComunicacionServices.POST()

    const handleSubmitLoginPOST = () => {

        if (handleValidationSello(formDatosContacto)) {

            const date = new Date();

            const todayDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
            const todayHour = `${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`

            const bodyTemp = {
                // adjunto: new File([""], "filename"),
                comunicacion: {
                    asunto: `[web] Sol·licitud de Segell de societats professionals d’enginyeria`,
                    cuerpo: `<p>El/la Sr./Sra. ${formDatosContacto.nombre} ${formDatosContacto.apellidos}, núm. col. ${formDatosContacto.persCodiCole}, en nom de la
                    Societat professional ${formDatosContacto.nombreSociedad} registrada en ENGINYES BCN
                    (${formDatosContacto.datosContacto__email}), ha sol·licitat el Segell de Societats
                    Professionals el dia ${todayDate} a les ${todayHour} i ha acceptat les
                    normes i condicions del reglament d'ús de la marca col·lectiva:
                    Logotip professional "Societat Professional d'Enginyeria".</p>`,
                    remitente: `${formDatosContacto.nombre} ${formDatosContacto.apellidos}`,
                    cuerpoHTML: true,
                    emailRemitente: `${formDatosContacto.datosContacto__email}`,
                    destinatarios: [
                        {
                            email: process.env.REACT_APP_EMAIL_SELLO_SOCIEDADES_PROFESIONALES,
                            destinatario: process.env.REACT_APP_BUSINESS_NAME,
                            variables: {}
                        },
                    ]
                }
            }

            fetchComunicacionesPOST({
                body: bodyTemp
            })
        }
    }


    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" >

                <Grid item xs={12} className='mb-4 pb-2'>
                    <Paper className='p-3 p-md-4'>

                        <Typography variant='h6' className="my-0 pb-1 fw-500 color-primary text-center">
                            {Lang('SELLO_SOCIEDADES_PROFESIONALES')}
                        </Typography>

                        <Typography variant='body1' className="my-2 mb-3">
                            Per obtenir el Segell de societats professionals d'enginyeria, cal que la societat professional estigui inscrita en el Registre de Societats Professionals del Col·legi, acceptar el reglament d'ús de la marca i emplenar el següent formulari:

                        </Typography>

                        <Grid container spacing={2} className='mb-4'>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="mt-2 fw-500 color-primary">
                                    Dades personals
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} lg={6}>
                                <InputText
                                    label='Nom de la societat'
                                    name='nombreSociedad'
                                    value={formDatosContacto.nombreSociedad}
                                    onChange={handleInputDatosContacto}
                                    localValidation={validSello.nombreSociedad}
                                />
                            </Grid>

                            <Grid item xs={12} className='py-0'></Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <InputText
                                    label={Lang('NOMS')}
                                    name='nombre'
                                    value={formDatosContacto.nombre}
                                    onChange={handleInputDatosContacto}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={5}>
                                <InputText
                                    label={Lang('COGNOMS')}
                                    name='apellidos'
                                    value={formDatosContacto.apellidos}
                                    onChange={handleInputDatosContacto}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={3}>
                                <InputText
                                    label={Lang('NUMERO_COLEGIADO')}
                                    name='persCodiCole'
                                    value={formDatosContacto.persCodiCole}
                                    onChange={handleInputDatosContacto}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={6}>
                                <InputText
                                    label='Correu electrònic'
                                    name='datosContacto__email'
                                    value={formDatosContacto.datosContacto__email}
                                    onChange={handleInputDatosContacto}
                                    localValidation={validSello.datosContacto__email}
                                />
                            </Grid>

                        </Grid>

                        <InputChecks
                            values={[
                                {
                                    id: 0,
                                    checked: formSello.lopd?.length > 0,
                                    label:
                                        <>
                                            <Typography variant='body2'>Accepto les normes i condicions del <a rel="noreferrer" target='_blank' href="https://documentacio.enginyersbcn.cat/coneixement/docs/el-collegi/docs/el-collegi/segell-de-lenginyer-tecnic-industrial/reglament-dus-de-la-marca-collectiva-logotip-professional-societat-professional-denginyeria" className='color-primary'>reglament d'ús de la marca col·lectiva: Segell de societats professionals d'enginyeria"</a></Typography>
                                        </>
                                }
                            ]}
                            onChange={(e: any) => handleChangeCheck(e, 'lopd')}
                        />

                        <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={formSello.lopd?.length === 0}
                                onClick={handleSubmitLoginPOST}
                            >
                                {Lang('SOLICITAR_SELLO')}
                            </Button>
                            <Typography variant='body2' className="mt-3">
                                El Segell de societats professionals d'enginyeria s'enviarà al correu electrònic indicat durant els propers dies.

                            </Typography>
                            <Typography variant='body2' className="mt-2">
                                Per a més informació, podeu contactar amb <a rel="noreferrer" target='_blank' href="https://webpre.enginyersbcn.cat/el-collegi/la-seu/" className='color-primary'>Àrea de Col·legiació</a>.
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <ModalStatusServer
                fetchData={fetchComunicacionesPOSTData}
                activeLoading
                disableStatus
                messageStatus={
                    {
                        201:
                            <>
                                <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                <Typography sx={{ mt: 2 }}>
                                    S'ha enviat correctament la sol·licitud
                                </Typography>
                            </>
                    }
                }
            />
        </Box>
    )
}

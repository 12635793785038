import { LoginAdapters, ResetEmailAdapters, TokenAdapters } from "../adapters/auth.adapters"
import { useAxios } from "../../hooks/useAxios"
import { UserEbcnAdapters } from "../adapters/user.adapters"

const UserEbcnServices = {

    PUT_RESET_PASSWORD: function () {

        return useAxios({ method: 'put', url: '/users/usuarios-ebcn/reset-own-password', type: 'json', adapters: UserEbcnAdapters.PUT_RESET_PASSWORD })
    },

    PUT_TEMPORAL: function () {

        return useAxios({ method: 'put', url: '/users/usuarios-ebcn/reset-rol', type: 'json', adapters: TokenAdapters.POST })
    }
}

export {
    UserEbcnServices
}
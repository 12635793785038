import { styled } from '@mui/material/styles';

import {
    Alert,
    Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup,
    FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { useLang } from '../../language';
import { useNavigate } from 'react-router';
import { isColegiado } from '../../routes/helpers';

export const Home = ({ setBreadcrumbLineItems, rol }: any) => {

    const Lang = useLang()

    useEffect(() => {

        setBreadcrumbLineItems((item: any) => [item[0]])

    }, [])

    const navigate = useNavigate()

    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" className='mb-4 pb-3' >

                {
                    isColegiado(rol) &&
                    <>
                        {/* <Grid item xs={12} className=''>
                            <Paper className='p-3 p-md-4'>

                                <Typography variant='h6' className="my-0 pb-2 fw-500 color-primary">
                                    {Lang('DATOS_PROFESIONALES')}
                                </Typography>

                                <Typography variant='body1' className="my-0">
                                    El Col·legi us convida a ampliar, de forma voluntària, les vostres dades professionals (sector, àrea, càrrec, etc.) per tal que el Col·legi disposi de més informació d’ús intern sobre els seus col·legiats i poder millorar la comunicació i els serveis que us ofereix.
                                </Typography>

                                <div className="d-flex justify-content-end mt-2">
                                    <Button color="primary" variant="contained" onClick={() => navigate('./datos-profesionales')}>{Lang('ACTUALIZA')}</Button>
                                </div>
                            </Paper>
                        </Grid> */}

                        <Grid item xs={12} className=''>
                            <Paper className='p-3 p-md-4'>

                                <Typography variant='h6' className="my-0 pb-2 fw-500 color-primary">
                                    {Lang('DATOS_ACCESO_CONSUMIDOR')}
                                </Typography>

                                <Typography variant='body1' className="my-0 pb-1">
                                    El Col·legi té l’obligació imposada per Llei de fer accessible, i per tant públiques, algunes dades professionals dels/de les col·legiats/col·legiades als consumidors i usuaris (article 40 bis, relatiu a la finestreta única, de la Llei 7/2006, del 31 de maig, de l’exercici de professions titulades i dels col·legis professionals).

                                </Typography>
                                <Typography variant='body1' className="mt-2 mb-0">
                                    Aquestes dades hauran d’estar permanentment actualitzades.
                                </Typography>
                                <div className="d-flex justify-content-end mt-2">
                                    <Button color="primary" variant="contained" onClick={() => navigate('./dades-acces-consumidor')}>{Lang('ACTUALIZA')}</Button>
                                </div>
                            </Paper>
                        </Grid>
                    </>
                }

                <Grid item xs={12} className=''>
                    <Paper className='p-3 p-md-4'>

                        <Typography variant='h6' className="my-0 pb-2 fw-500 color-primary">
                            {Lang('DATOS_CONTACTO_RELACION_COLEGIO')}
                        </Typography>

                        <Typography variant='body1' className="my-0 pb-1">
                            D’acord amb els vigents estatuts col·legials, els/les col·legiats/col·legiades hauran d’actualitzar les seves dades de contacte i seran eficaces les notificacions que s’hi adrecin al domicili que hi consti a la Secretaria o al web del Col·legi.
                        </Typography>

                        <div className="d-flex justify-content-end mt-2">
                            <Button color="primary" variant="contained" onClick={() => navigate('./dades-contacte-relacio-col-legi')}>{Lang('ACTUALIZA')}</Button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

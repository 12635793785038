import { LoginAdapters, ResetEmailAdapters, TokenAdapters } from "../adapters/auth.adapters"
import { useAxios } from "../../hooks/useAxios"

const LoginServices = {

    POST: function () {

        return useAxios({ method: 'post', url: '/auth/usuarios-ebcn', type: 'json', adapters: LoginAdapters.POST })
    }
}

const TokenServices = {

    POST: function () {

        return useAxios({ method: 'post', url: '/auth/validate', type: 'json', adapters: TokenAdapters.POST })
    }
}

const ResetEmailServices = {

    GET: function () {

        return useAxios({ method: 'get', url: 'users/usuarios-ebcn/reset-email', type: 'json', adapters: ResetEmailAdapters.GET })
    },

    POST: function () {

        return useAxios({ method: 'post', url: '/users/usuarios-ebcn/reset-email', type: 'json', adapters: ResetEmailAdapters.POST })
    }
}

export {
    LoginServices,
    ResetEmailServices,
    TokenServices
}
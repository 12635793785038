import { styled } from '@mui/material/styles';

import {
    Alert,
    Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup,
    FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { BreadCrumbCustom } from '../../../components/BreadCrumbCustom/BreadCrumbCustom';
import { useLang } from '../../../language';
import { InputTextarea } from '../../../components/Inputs/InputTextarea';
import { InputText } from '../../../components/Inputs/InputText';
import { InputSelect } from '../../../components/Inputs/InputSelect';
import { InputRadio } from '../../../components/Inputs/InputRadio';
import { InputChecks } from '../../../components/Inputs/InputChecks';
import { DatosAdapters } from '../../ActualizacionDatos/controllers/adapters/datos.adapters';
import { useForm } from '../../../hooks/useForm';
import { cloneDeep } from 'lodash';

export const SelloIngenieroTecnicoDisenioIndustrial = ({ setBreadcrumbLineItems, route, formContact }: any) => {

    const Lang = useLang()

    useEffect(() => {

        setBreadcrumbLineItems((item: any) => [item[0], route])

    }, [])


    const [formDatosContacto, handleInputDatosContacto, handleFormDatosContacto, handleResetFormDatosContacto] = useForm({
        ...DatosAdapters.GET.receive.schema()
    })

    useEffect(() => {
        if (formContact.status === 200) {
            
            handleFormDatosContacto({
                ...formDatosContacto,
                ...formContact.data?.items?.[0]
            })
        }
    }, [formContact])

    const [formSello, handleInputSello, handleFormSello, handleResetFormSello] = useForm({
        lopd: []
    })

    // Funciones helpers

    const handleChangeCheck = (e: any, name: string) => {

        let listaModulosTemp = cloneDeep(formSello[name])

        if (e.target.checked) {

            listaModulosTemp.push(e.target.name)

        } else {

            listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormSello({
            ...formSello,
            [name]: listaModulosTemp
        })
    }


    const handleSubmitDownloadFile = () => {

        window.open('https://documentacio.enginyersbcn.cat/coneixement/docs/el-collegi/docs/el-collegi/segell-de-lenginyer-tecnic-industrial/segell-de-lenginyer-tecnic-en-disseny-industrial-descarrega', '_blank')?.focus();
    }

    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" >

                <Grid item xs={12} className='mb-4 pb-2'>
                    <Paper className='p-3 p-md-4'>

                        <Typography variant='h6' className="my-0 pb-1 fw-500 color-primary text-center">
                            {Lang('SELLO_INGENIERO_TECNICO_DISENIO_INDUSTRIAL')}
                        </Typography>

                        <Typography variant='body1' className="my-2">
                            Per obtenir el Segell de l’enginyer tècnic en disseny industrial cal ser col·legiat, acceptar el reglament d’ús de la marca i d’ús del logotip professional.

                        </Typography>
                        <Typography variant='body1' className="my-2">
                            En fer-ho, podreu descarregar-vos un document zip amb:

                        </Typography>
                        <Typography variant='body1' className="">
                            <ul className='ul-default'>
                                <li>
                                    El segell en format jpg, eps, Freehand i Illustrator en les versions masculina i femenina i catalana i castellana
                                </li>
                                <li>
                                    El reglament d’ús i la normativa gràfica en format PDF
                                </li>
                            </ul>
                        </Typography>

                        <Grid container spacing={2} className='mb-4'>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="mt-2 fw-500 color-primary">
                                    Dades personals
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} lg={3}>
                                <InputText
                                    label={Lang('NUMERO_COLEGIADO')}
                                    value={formDatosContacto.persCodiCole}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <InputText
                                    label={Lang('NOMS')}
                                    value={formDatosContacto.nombre}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={5}>
                                <InputText
                                    label={Lang('COGNOMS')}
                                    value={formDatosContacto.apellidos}
                                    readOnly
                                />
                            </Grid>

                        </Grid>

                        <InputChecks values={[
                            {
                                id: 0,
                                checked: formSello.lopd?.length > 0,
                                label:
                                    <>
                                        <Typography variant='body2'>Accepto les normes i condicions del <a rel="noreferrer" target='_blank' href="https://documentacio.enginyersbcn.cat/coneixement/docs/el-collegi/docs/el-collegi/segell-de-lenginyer-tecnic-industrial/reglament-dus-de-la-marca-collectiva-logotip-professional-enginyer-tecnic-en-disseny-industrialenginyera-tecnica-en-disseny-industrial" className='color-primary'>reglament d'ús de la marca col·lectiva: Logotip professional "Enginyer Tècnic en Disseny Industrial" / "Enginyera Tècnica en Disseny Industrial"</a></Typography>
                                    </>
                            }
                        ]}
                            onChange={(e: any) => handleChangeCheck(e, 'lopd')}

                        />

                        <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                            <Button color="primary" variant="contained"
                                disabled={formSello.lopd?.length === 0}
                                onClick={handleSubmitDownloadFile}
                            >{Lang('DESCARGAR_SELLO')}</Button>
                            <Typography variant='body2' className="mt-3">
                                Per a més informació, podeu contactar amb <a rel="noreferrer" target='_blank' href="https://webpre.enginyersbcn.cat/el-collegi/la-seu/" className='color-primary'>Àrea de Col·legiació</a>.
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

import { Box, Button, Grid, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useLang } from '../../../language';
import { InputText } from '../../../components/Inputs/InputText';
import { InputSelect } from '../../../components/Inputs/InputSelect';
import { InputRadio } from '../../../components/Inputs/InputRadio';
import { useForm } from '../../../hooks/useForm';
import { DatosServices } from '../controllers/services/datos.services';
import { DatosAdapters } from '../controllers/adapters/datos.adapters';
import { PaisesServices, PoblacionesServices } from '../../../controllers/services/ubicaciones.services';
import { ModalStatusServer } from '../../../components/ModalStatus/ModalStatusServer';
import { useValidator } from "../../../hooks/useValidator";
import { validCorrectEmail, validCorrectMovil, validExiste } from "../../../helpers";

export const DatosContactoRelacionColegio = ({ setBreadcrumbLineItems, route, formContact }: any) => {

    const Lang = useLang()

    useEffect(() => {

        setBreadcrumbLineItems((item: any) => [item[0], route])

    }, [])

    const [validForm, handleValidationForm] = useValidator({
        direccion__direccion: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        direccion__codigoPostal: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        direccion__poblacion: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        datosContacto__movil: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
            { functionValid: validCorrectMovil, msgError: Lang('VALID_CORRECT_MOVIL'), isOk: true },
        ],
        datosContacto__email: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
            { functionValid: validCorrectEmail, msgError: Lang('VALID_CORRECT_EMAIL'), isOk: true },
        ],
    })

    // Formulario principal

    const [formDatosContacto, handleInputDatosContacto, handleFormDatosContacto, handleResetFormDatosContacto] = useForm({
        ...DatosAdapters.GET.receive.schema()
    })

    const [formDatosFacturacion, handleInputDatosFacturacion, handleFormDatosFacturacion, handleResetFormDatosFacturacion] = useForm({
        ...DatosAdapters.GET.receive.schema()
    })

    const [fetchDatosFacturacionGET, fetchDatosFacturacionGETData] = DatosServices.GET()

    useEffect(() => {

        fetchDatosGET({
            params: {
                'tipo-direccion': 'CONTACT'
            }
        })

        fetchDatosFacturacionGET({
            params: {
                'tipo-direccion': 'FACTURACIO'
            }
        })

        fetchPaisesGET()

    }, [])



    const [fetchDatosGET, fetchDatosGETData] = DatosServices.GET()

    useEffect(() => {
        if (fetchDatosGETData.status === 200) {
            
            handleFormDatosContacto({
                ...formDatosContacto,
                ...fetchDatosGETData.data?.items?.[0],
                direccion__idpais: 3
            })
        }
    }, [fetchDatosGETData])


    useEffect(() => {
        if (fetchDatosFacturacionGETData.status === 200) {
            
            handleFormDatosFacturacion({
                ...formDatosFacturacion,
                ...fetchDatosFacturacionGETData.data?.items?.[0]
            })

        }
    }, [fetchDatosFacturacionGETData])

    // Actualizacion de datos de contacto

    const [fetchDatosPUT, fetchDatosPUTData] = DatosServices.PUT()

    const handleSubmitDatosPUT = () => {

        if (handleValidationForm(formDatosContacto)) {

            fetchDatosPUT({
                body: {
                    ...formDatosContacto,
                    tipoDireccion: 'CONTACT',
                    lopd: true,
                    direccion__pais: fetchPaisesGETData.data?.find((item: any) => item.id === formDatosContacto.direccion__idpais)?.nombre || '',
                    direccion__provincia: fetchPoblacionesGETData.data?.elementos?.length > 0 ? formDatosContacto.direccion__provincia : '',
                }
            })
        }

    }

    // ------------------------ Ubicaciones

    const [activeEspana, setactiveEspana] = useState(false)

    const [fetchPoblacionesGET, fetchPoblacionesGETData] = PoblacionesServices.GET()

    const [fetchPaisesGET, fetchPaisesGETData] = PaisesServices.GET()

    useEffect(() => {

        if (formDatosContacto.direccion__idpais === 3) {

            setactiveEspana(true)

            if (formDatosContacto.direccion__codigoPostal?.length === 5) {

                fetchPoblacionesGET({
                    params: {
                        page: 0,
                        size: 200,
                        cpostal: formDatosContacto.direccion__codigoPostal,
                    }
                })

            }

        } else {
            setactiveEspana(false)

            handleFormDatosContacto({
                ...formDatosContacto,
                direccion__poblacion: '',
                direccion__provincia: '',
                direccion__comarca: '',
            })
        }

    }, [formDatosContacto.direccion__codigoPostal, formDatosContacto.direccion__idpais])

    const handleMiddlewareSelectPoblacion = (e: any) => {

        const poblacionesTemp = [...fetchPoblacionesGETData.data?.elementos]

        const resp = poblacionesTemp.find(item => item.poblacion === e.target.value)

        handleFormDatosContacto({
            ...formDatosContacto,
            direccion__poblacion: resp?.poblacion || '',
            direccion__provincia: resp?.provincia || '',
            direccion__comarca: resp?.comarca || '',
        })
    }

    // ------------------------ End Ubicaciones

    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" >

                <Grid item xs={12} className='mb-4 pb-2'>
                    <Paper className='p-3 p-md-4'>

                        <Typography variant='h6' className="my-0 pb-1 fw-500 color-primary text-center">
                            {Lang('DATOS_CONTACTO_RELACION_COLEGIO')}
                        </Typography>

                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="mt-2 fw-500 color-primary">
                                    Dades personals
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} lg={3}>
                                <InputText
                                    label={Lang('CODIGO')}
                                    value={formDatosContacto.persCodiCole}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={5}>
                                <InputText
                                    label={Lang('COGNOMS')}
                                    value={formDatosContacto.apellidos}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <InputText
                                    label={Lang('NOMS')}
                                    value={formDatosContacto.nombre}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <InputText
                                    label={Lang('NIF')}
                                    value={formDatosContacto.persCifNif}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <InputText
                                    label={Lang('FECHA_NACIMIENTO')}
                                    value={formDatosContacto.fechaNacimiento}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <InputText
                                    label={Lang('ESTADO')}
                                    value={formDatosContacto.estat}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="fw-500 color-primary">
                                    Adreça de contacte
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className=''>
                                <Typography variant='body1' className="mb-0">
                                    És l'adreça que el Col·legi utilitza per comunicar-se amb els col·legiats.
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <InputText
                                    label={Lang('DOMICILIO')}
                                    name='direccion__direccion'
                                    value={formDatosContacto.direccion__direccion}
                                    onChange={handleInputDatosContacto}
                                    localValidation={validForm.direccion__direccion}
                                />
                            </Grid>

                            {/* <Grid item xs={12} lg={6} xl={4}>
                                <InputSelect
                                    label={Lang('PAIS')}
                                    name='direccion__idpais'
                                    onChange={handleInputDatosContacto}
                                    value={formDatosContacto.direccion__idpais}
                                    values={fetchPaisesGETData.data?.map((item: any) => {
                                        return {
                                            id: item.id,
                                            name: item.nombre
                                        }
                                    }) || []}
                                />
                            </Grid> */}

                            <Grid item xs={12} lg={6} xl={4}>
                                <InputText
                                    label={Lang('CODIGO_POSTAL')}
                                    name='direccion__codigoPostal'
                                    value={formDatosContacto.direccion__codigoPostal}
                                    onChange={handleInputDatosContacto}
                                    type='number'
                                    localValidation={validForm.direccion__codigoPostal}
                                />
                            </Grid>

                            {
                                (activeEspana && fetchPoblacionesGETData.data?.elementos?.length > 0) ?
                                    <Grid item xs={12} lg={6} xl={4}>
                                        <InputSelect
                                            label={Lang('POBLACION')}
                                            name='direccion__poblacion'
                                            onChange={handleMiddlewareSelectPoblacion}
                                            value={formDatosContacto.direccion__poblacion}
                                            values={fetchPoblacionesGETData.data?.elementos?.map((item: any) => {
                                                return {
                                                    id: item.poblacion,
                                                    name: item.poblacion
                                                }
                                            }) || []}
                                            localValidation={validForm.direccion__poblacion}

                                        />
                                    </Grid>
                                    :
                                    <Grid item xs={12} lg={6} xl={4}>
                                        <InputText
                                            label={Lang('POBLACION')}
                                            name='direccion__poblacion'
                                            value={formDatosContacto.direccion__poblacion}
                                            onChange={handleInputDatosContacto}
                                            disabled
                                            localValidation={validForm.direccion__poblacion}
                                        />
                                    </Grid>
                            }

                            <Grid item xs={12} lg={6} xl={4}>
                                <InputText
                                    label={Lang('COMARCA')}
                                    name='direccion__comarca'
                                    value={formDatosContacto.direccion__comarca}
                                    onChange={handleInputDatosContacto}
                                    disabled
                                />

                            </Grid>

                            {/* <Grid item xs={12} className=''>
                                <Typography variant='body1' className="mb-0">
                                    Si no trobes la població o codi postal, selecciona l’opció <strong>Altres poblacions</strong> i entra les dades directament.
                                </Typography>
                            </Grid> */}

                            <Grid item xs={12} lg={4}>
                                <InputText
                                    label={Lang('TELEFONO_MOVIL') + ' *'}
                                    name='datosContacto__movil'
                                    value={formDatosContacto.datosContacto__movil}
                                    onChange={handleInputDatosContacto}
                                    localValidation={validForm.datosContacto__movil}
                                />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <InputText
                                    label={Lang('OTRO_TELEFONO')}
                                    name='datosContacto__telefono'
                                    value={formDatosContacto.datosContacto__telefono}
                                    onChange={handleInputDatosContacto}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputText
                                    label={Lang('EMAIL')}
                                    name='datosContacto__email'
                                    value={formDatosContacto.datosContacto__email}
                                    onChange={handleInputDatosContacto}
                                    localValidation={validForm.datosContacto__email}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputText
                                    label={Lang('WEB')}
                                    name='datosContacto__web'
                                    value={formDatosContacto.datosContacto__web}
                                    onChange={handleInputDatosContacto}
                                />
                            </Grid>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="fw-500 color-primary">
                                    Rebre comunicats
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputRadio
                                    label='Vols rebre el butlletí electrònic'
                                    name='butlleti'
                                    value={formDatosContacto.butlleti}
                                    onChange={handleInputDatosContacto}
                                    // onChange={(e: any) => handleChangeCheck(e, 'butlleti')}
                                    values={
                                        [
                                            { id: true, name: 'Si' },
                                            { id: false, name: 'No' },
                                        ]
                                    } />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputRadio
                                    label='Vols rebre la revista Theknos en paper?'
                                    name='theknos'
                                    value={formDatosContacto.theknos}
                                    // onChange={(e: any) => handleChangeCheck(e, 'theknos')}
                                    onChange={handleInputDatosContacto}
                                    values={
                                        [
                                            { id: true, name: 'Si' },
                                            { id: false, name: 'No' },
                                        ]
                                    } />
                            </Grid>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="fw-500 color-primary">
                                    Adreça de facturació
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <InputText
                                    label={Lang('DOMICILIO')}
                                    value={formDatosFacturacion.direccion__direccion}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputText
                                    label={Lang('POBLACION')}
                                    name='direccion__poblacion'
                                    value={formDatosFacturacion.direccion__poblacion}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <InputText
                                    label={Lang('TELEFONO_MOVIL')}
                                    value={formDatosFacturacion.datosContacto__movil}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <InputText
                                    label={Lang('TELEFONO_1')}
                                    value={formDatosFacturacion.datosContacto__telefono}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <InputText
                                    label={Lang('TELEFONO_2')}
                                    value={formDatosFacturacion.datosContacto__telefono2}
                                    readOnly
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputText
                                    label={Lang('FAX')}
                                    value={formDatosFacturacion.datosContacto__telefonoFax}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputText
                                    label={Lang('EMAIL')}
                                    value={formDatosFacturacion.datosContacto__email}
                                    readOnly
                                />
                            </Grid>

                            {/* <Grid item xs={12} lg={6}>
                                <InputText
                                    label={Lang('WEB')}
                                    value={formDatosFacturacion.datosContacto__web}
                                    readOnly
                                />
                            </Grid> */}

                            <Grid item xs={12} className=''>
                                <Typography variant='body1' className="mb-0">
                                    Si voleu canviar les dades, si us plau, poseu-vos en contacte amb el departament del Col·legi amb el qual heu contractat el servei.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="fw-500 color-primary">
                                    Dades bancàries
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className='pt-3'>
                                <Typography variant='body1' className="mt-0">
                                    Per raons de seguretat, les dades bancàries no són susceptibles de modificació via web.
                                </Typography>
                                <Typography variant='body1' className="mt-3">
                                    Si voleu canviar-les o afegir-ne de noves, si us plau,
                                    empleneu el <a target='_blank' rel="noreferrer" href="https://documentacio.enginyersbcn.cat/storage/app/uploads/public/636/b97/3e3/636b973e3255c990500530.pdf" className='color-primary'>Document d'autorització</a> i feu-lo arribar, signat, al Col·legi,
                                    digitalitzat al c/e <a href="mailto:ebcn@ebcn.cat" className='color-primary'>ebcn@ebcn.cat</a> o per correu ordinari.
                                </Typography>
                            </Grid>
                            {
                                formDatosFacturacion.serviciosActivos &&
                                <>
                                    <Grid item xs={12} className=''>
                                        <div className="border-top"></div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant='body1' className="fw-500 color-primary">
                                            Serveis contractats
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} className="pt-3">
                                        <Typography variant='body1'>
                                            <ul className='ul-default my-0'>
                                                {
                                                    formDatosFacturacion.serviciosActivos?.split('\n')?.map((item: any) => {
                                                        return (
                                                            <li>{item}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </Typography>
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12} className='pt-3'>
                                <Typography variant='body2' className="mt-0">
                                    ENGINYERS BCN es compromet a complir amb les disposicions del Reglament 2016/679, de 27 d’abril de 2016, sobre la protecció de les persones físiques pel que fa al tractament de dades personals i a la lliure circulació d’aquestes dades, així com la normativa estatal de protecció de dades vigent.
                                </Typography>
                                <Typography variant='body2' className="mt-3">
                                    ENGINYERS BCN s'obliga a mantenir la més estricta reserva i confidencialitat sobre tota la informació a la qual tingui accés, comprometent-se a utilitzar aquesta informació únicament per al desenvolupament dels serveis propis d’aquest col·legi professional.
                                </Typography>
                                <Typography variant='body2' className="mt-3">
                                    Podreu accedir, rectificar i suprimir les dades, així com limitar el tractament, oposar-vos al mateix o exercir el dret a la portabilitat de les dades. Aquests drets estan regulats en el Reglament (UE) 679/2016, i poden ser exercits, aportant còpia de DNI, al domicili Carrer Consell de Cent, 365 – 08009 de Barcelona, o a través del següent correu electrònic: <a href="mailto:ebcn@ebcn.cat" className='color-primary'>ebcn@ebcn.cat</a>
                                </Typography>
                            </Grid>

                        </Grid>

                        <div className="d-flex justify-content-start mt-4">
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSubmitDatosPUT}
                            >
                                {Lang('ACTUALIZAR_DATOS')}
                            </Button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <ModalStatusServer
                fetchData={fetchDatosPUTData}
                activeLoading
                disableStatus
                messageStatus={
                    {
                        204:
                            <>
                                <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                <Typography sx={{ mt: 2 }}>
                                    S'han actualitzat correctament les dades
                                </Typography>
                            </>
                    }
                }
            />
        </Box>
    )
}

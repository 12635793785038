import { faArrowDown, faBars, faChevronDown, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import logo from '../../../assets/images/icon.png'
import { useDispatch, useSelector } from 'react-redux'
import { logOut } from '../../../store/slices/auth';
import { RootState } from '../../../store';
import { Avatar, Box, Divider, FormControl, FormHelperText, ListItemIcon, Menu, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLang } from '../../../language';

const Body = ({ children, setshowNavLeft = (() => false), showNavLeft, label = '', setshowNavTop, showNavTop }: any) => {

    const dispatch = useDispatch()

    const { auth } = useSelector((state: RootState) => state.auth)

    const Lang = useLang()

    const [age, setAge] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

    const handleClickLogOut = (e: React.SyntheticEvent) => {

        e.preventDefault()
        dispatch(logOut())
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <section className="LayoutPrimary__contentright">
            <main>
                {children}
            </main>

        </section>
    )
}

export default Body
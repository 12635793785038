// import { Modal, Button, InputGroup, Form } from 'react-bootstrap'
import { Button, Fade, Grid, Modal, Slider } from '@mui/material'
import { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import { dataURLtoFile } from '../../helpers'
import { useLang } from '../../language'
import getCroppedImg from './CropImage'

export const ModalCropImage = ({ show, handleClose, height, setInputFile, escalas, handleSubirImagen, tipo }: any) => {

    const Lang = useLang()

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState<any>(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    // const [croppedImage, setCroppedImage] = useState(null)
    const [escala, setEscala] = useState(escalas[0])

    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {

        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {

        try {
            
            if (show.payload) {

                const img = URL.createObjectURL(show.payload)
                const croppedImage = await getCroppedImg(
                    img,
                    croppedAreaPixels,
                    rotation
                )

                // setCroppedImage(croppedImage)

                // let file = new File([croppedImage], "img.png",{type:"image/png", lastModified:new Date().getTime()});
                // let container = new DataTransfer();
                // container.items.add(file);

                let file = dataURLtoFile(croppedImage, "img.png")

                // inputFileRef.current.files = container.files

                setInputFile([file])
                if (handleSubirImagen) {
                    handleSubirImagen(tipo, file)
                }
                handleClose()
            }


        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const showCroppedImageDefault = async () => {

        if (show.payload) {

            setInputFile([show.payload])
            if (handleSubirImagen) {
                handleSubirImagen(tipo, show.payload)
            }
            handleClose()
        }
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 650,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 3,
    };

    // const handleSliderChange = (event: Event, newValue: number | number[]) => {
    //     setValue(newValue);
    //   };

    return (
        <Modal
            open={show.value}
            onClose={handleClose}
        >
            <Fade in={show.value}>
                <Grid container spacing={1} sx={style} className='z-depth-4'>

                    <Grid item xs={12}>
                        <section className='ModalHeader border-0'>
                            <h3 className='mt-0'>
                                {Lang('RECORTAR_IMAGEN')}
                            </h3>
                        </section>
                    </Grid>

                    <Grid item xs={12}>
                        <section className='ModalBody d-flex flex-column' style={{'height': '500px'}}>
                            <div className="position-relative flex-grow-1">
                                {
                                    show.payload && <Cropper
                                        image={URL.createObjectURL(show.payload)}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={escala.x / escala.y}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                }
                            </div>
                            {
                                <div className='mt-3'>
                                    <div>Zoom</div>
                                    {/* <Form.Range
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        onChange={(e) => setZoom(e.target.value)}
                                    /> */}

                                    <Slider
                                        value={zoom}
                                        onChange={(e: any) => setZoom(e.target.value)}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="input-slider"
                                    />

                                </div>
                            }
                        </section>
                    </Grid>

                    <Grid item xs={12}>
                        <section className='ModalFooter'>
                            <div className="d-flex justify-content-end">
                                <Button variant="outlined" onClick={showCroppedImageDefault}>
                                    {Lang('MANTENER_ORIGINAL')}
                                </Button>
                                <Button variant="contained" onClick={showCroppedImage} className='ml-3'>
                                    {Lang('RECORTAR_IMAGEN')}
                                </Button>
                            </div>
                        </section>
                    </Grid>
                </Grid>


            </Fade>
        </Modal>
    )
}
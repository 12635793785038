import './Sellos.scss'
import { BreadCrumbCustom } from '../../components/BreadCrumbCustom/BreadCrumbCustom';
import { Box, Button, Grid, List, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLang } from '../../language';
import { Navigate, Route, Routes } from 'react-router';
import { SubList } from '../../components/SubList/SubList';
import { DatosContactoRelacionColegio } from './DatosContactoRelacionColegio';
import { AccesoDocumentos } from './AccesoDocumentos';
import { DatosServices } from '../ActualizacionDatos/controllers/services/datos.services';

export const AltaRegistroCertificadores = ({ onlyPath }: any) => {

    const Lang = useLang()

    const [BreadcrumbLineItems, setBreadcrumbLineItems] = useState<any>([
        {
            title: Lang('ALTA_REGISTRO_CERTIFICADORES_EFICIENCIA_ENERGETICA'),
            link: onlyPath
        }
    ])

    const listRoutes = [
        {
            title: 'Documents accés',
            link: '/accessos-document'
        },
        {
            title: 'Inscripció',
            link: '/inscripcion'
        },
    ]

    
    const [fetchDatosGET, fetchDatosGETData] = DatosServices.GET()

    useEffect(() => {

        fetchDatosGET({
            params: {
                'tipo-direccion': 'CONTACT'
            }
        })

    }, [])

    return (
        <div>
            <BreadCrumbCustom items={BreadcrumbLineItems} />

            <section className='LayoutPrimary__contentmain'>

                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className='Paper__SubList'>

                                <SubList
                                    listRoutes={listRoutes}
                                    parentPath={onlyPath}
                                />

                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>

                            <Routes>

                                <Route
                                    path="/accessos-document"
                                    element={<AccesoDocumentos formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[0]} />}
                                />

                                <Route
                                    path="/inscripcion"
                                    element={<DatosContactoRelacionColegio formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[1]} />}
                                />

                                <Route path="*" element={<Navigate to={`${onlyPath}/accessos-document`} replace />} />
                                
                            </Routes>

                        </Grid>
                    </Grid>
                </Box>
            </section>
        </div>
    )
}

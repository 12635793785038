import { styled } from '@mui/material/styles';

import {
    Alert,
    Box, Button, Grid, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { BreadCrumbCustom } from '../../../components/BreadCrumbCustom/BreadCrumbCustom';
import { useLang } from '../../../language';
import { InputText } from '../../../components/Inputs/InputText';
import { InputSelect } from '../../../components/Inputs/InputSelect';
import { InputRadio } from '../../../components/Inputs/InputRadio';
import { InputTextarea } from '../../../components/Inputs/InputTextarea';
import { useForm } from '../../../hooks/useForm';
import { DatosAdapters } from '../controllers/adapters/datos.adapters';
import { DatosServices } from '../controllers/services/datos.services';
import { PaisesServices, PoblacionesServices } from '../../../controllers/services/ubicaciones.services';
import { ModalStatusServer } from '../../../components/ModalStatus/ModalStatusServer';

export const DatosAccesoConsumidor = ({ setBreadcrumbLineItems, route, formContact }: any) => {

    const Lang = useLang()

    useEffect(() => {

        setBreadcrumbLineItems((item: any) => [item[0], route])

    }, [])


    const [formDatosContacto, handleInputDatosContacto, handleFormDatosContacto, handleResetFormDatosContacto] = useForm({
        ...DatosAdapters.GET.receive.schema()
    })

    const [formDatosOmnibus, handleInputDatosOmnibus, handleFormDatosOmnibus, handleResetFormDatosOmnibus] = useForm({
        ...DatosAdapters.GET.receive.schema()
    })

    useEffect(() => {
        if (formContact.status === 200) {
            
            handleFormDatosContacto({
                ...formDatosContacto,
                ...formContact.data?.items?.[0]
            })
        }
    }, [formContact])

    const [fetchDatosOmnibusGET, fetchDatosOmnibusGETData] = DatosServices.GET()

    useEffect(() => {

        fetchDatosOmnibusGET({
            params: {
                'tipo-direccion': 'OMNIBUS'
            }
        })

        fetchPaisesGET()

    }, [])


    useEffect(() => {
        if (fetchDatosOmnibusGETData.status === 200) {
            
            handleFormDatosOmnibus({
                ...formDatosOmnibus,
                ...fetchDatosOmnibusGETData.data?.items?.[0],
                direccion__idpais: 3
            })

        }
    }, [fetchDatosOmnibusGETData])


    // Actualizacion de datos de OMBINUS

    const [fetchDatosPUT, fetchDatosPUTData] = DatosServices.PUT_ALTRES()

    const handleSubmitDatosPUT = () => {

        fetchDatosPUT({
            body: {
                ...formDatosOmnibus,
                tipoDireccion: 'OMNIBUS',
                lopd: true,
                direccion__pais: fetchPaisesGETData.data?.find((item: any) => item.id === formDatosOmnibus.direccion__idpais)?.nombre || '',
                direccion__provincia: fetchPoblacionesGETData.data?.elementos?.length > 0 ? formDatosOmnibus.direccion__provincia : '',
            }
        })
    }

    useEffect(() => {

        if (String(fetchDatosPUTData.status)?.[0] === '2') {

            fetchDatosOmnibusGET({
                params: {
                    'tipo-direccion': 'OMNIBUS'
                }
            })
        }

    }, [fetchDatosPUTData])

    // Actualizacion de datos de OMBINUS

    const [fetchDatosPOST, fetchDatosPOSTData] = DatosServices.POST()

    const handleSubmitDatosPOST = () => {

        fetchDatosPOST({
            body: {
                ...formDatosOmnibus,
                tipoDireccion: 'OMNIBUS',
                lopd: true,
                persCodiCole: formDatosContacto.persCodiCole,
                identificador: formDatosContacto.identificador,
                direccion__pais: fetchPaisesGETData.data?.find((item: any) => item.id === formDatosOmnibus.direccion__idpais)?.nombre || '',
                direccion__provincia: fetchPoblacionesGETData.data?.elementos?.length > 0 ? formDatosContacto.direccion__provincia : '',
            }
        })
    }

    useEffect(() => {

        if (String(fetchDatosPOSTData.status)?.[0] === '2') {

            fetchDatosOmnibusGET({
                params: {
                    'tipo-direccion': 'OMNIBUS'
                }
            })
        }

    }, [fetchDatosPOSTData])

    // ------------------------ Ubicaciones

    const [activeEspana, setactiveEspana] = useState(false)

    const [fetchPoblacionesGET, fetchPoblacionesGETData] = PoblacionesServices.GET()

    const [fetchPaisesGET, fetchPaisesGETData] = PaisesServices.GET()

    useEffect(() => {

        if (formDatosOmnibus.direccion__idpais === 3) {

            setactiveEspana(true)

            if (formDatosOmnibus.direccion__codigoPostal?.length === 5) {

                fetchPoblacionesGET({
                    params: {
                        page: 0,
                        size: 200,
                        cpostal: formDatosOmnibus.direccion__codigoPostal,
                    }
                })

            }

        } else {
            setactiveEspana(false)

            handleFormDatosOmnibus({
                ...formDatosOmnibus,
                direccion__poblacion: '',
                direccion__provincia: '',
                direccion__comarca: '',
            })
        }

    }, [formDatosOmnibus.direccion__codigoPostal, formDatosOmnibus.direccion__idpais])

    const handleMiddlewareSelectPoblacion = (e: any) => {

        const poblacionesTemp = [...fetchPoblacionesGETData.data?.elementos]

        const resp = poblacionesTemp.find(item => item.poblacion === e.target.value)

        handleFormDatosOmnibus({
            ...formDatosOmnibus,
            direccion__poblacion: resp?.poblacion || '',
            direccion__provincia: resp?.provincia || '',
            direccion__comarca: resp?.comarca || '',
        })
    }

    // ------------------------ End Ubicaciones


    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" >

                <Grid item xs={12} className='mb-4 pb-2'>
                    <Paper className='p-3 p-md-4'>

                        <Typography variant='h6' className="mt-0 mb-3 pb-1 fw-500 color-primary text-center">
                            {Lang('DATOS_ACCESO_CONSUMIDOR')}
                        </Typography>

                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={6} lg={3}>
                                <InputText
                                    label={Lang('NUMERO_COLEGIADO')}
                                    value={formDatosContacto.persCodiCole}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={5}>
                                <InputText
                                    label={Lang('COGNOMS')}
                                    value={formDatosContacto.apellidos}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <InputText
                                    label={Lang('NOMS')}
                                    value={formDatosContacto.nombre}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <InputRadio label={Lang('Q_HABILITADO')} value={formDatosContacto.habilitado} values={
                                    [
                                        { id: true, name: 'Si' },
                                        { id: false, name: 'No' },
                                    ]
                                } readOnly />
                            </Grid>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12}>
                                <InputText
                                    label={Lang('DOMICILIO')}
                                    name='direccion__direccion'
                                    value={formDatosOmnibus.direccion__direccion}
                                    onChange={handleInputDatosOmnibus}
                                />
                            </Grid>

                            {/* <Grid item xs={12} lg={6} xl={4}>
                                <InputSelect
                                    label={Lang('PAIS')}
                                    name='direccion__idpais'
                                    onChange={handleInputDatosOmnibus}
                                    value={formDatosOmnibus.direccion__idpais}
                                    values={fetchPaisesGETData.data?.map((item: any) => {
                                        return {
                                            id: item.id,
                                            name: item.nombre
                                        }
                                    }) || []}
                                />
                            </Grid> */}

                            <Grid item xs={12} lg={6} xl={4}>
                                <InputText
                                    label={Lang('CODIGO_POSTAL')}
                                    name='direccion__codigoPostal'
                                    value={formDatosOmnibus.direccion__codigoPostal}
                                    onChange={handleInputDatosOmnibus}
                                    type='number'
                                />
                            </Grid>

                            {
                                (activeEspana && fetchPoblacionesGETData.data?.elementos?.length > 0) ?
                                    <Grid item xs={12} lg={6} xl={4}>
                                        <InputSelect
                                            label={Lang('POBLACION')}
                                            name='direccion__poblacion'
                                            onChange={handleMiddlewareSelectPoblacion}
                                            value={formDatosOmnibus.direccion__poblacion}
                                            values={fetchPoblacionesGETData.data?.elementos?.map((item: any) => {
                                                return {
                                                    id: item.poblacion,
                                                    name: item.poblacion
                                                }
                                            }) || []}
                                        />
                                    </Grid>
                                    :
                                    <Grid item xs={12} lg={6} xl={4}>
                                        <InputText
                                            label={Lang('POBLACION')}
                                            name='direccion__poblacion'
                                            value={formDatosOmnibus.direccion__poblacion}
                                            onChange={handleInputDatosOmnibus}
                                            disabled
                                        />
                                    </Grid>
                            }

                            <Grid item xs={12} lg={6} xl={4}>
                                <InputText
                                    label={Lang('COMARCA')}
                                    name='direccion__comarca'
                                    value={formDatosOmnibus.direccion__comarca}
                                    onChange={handleInputDatosOmnibus}
                                    disabled
                                />

                            </Grid>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12}>
                                <InputText
                                    label={Lang('TITULACIONES')}
                                    name='titulos'
                                    onChange={handleInputDatosOmnibus}
                                    value={formDatosOmnibus.titulos}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputTextarea
                                    label={Lang('OTRAS_TITULACIONES')}
                                    onChange={handleInputDatosOmnibus}
                                    name='comentario'
                                    value={formDatosOmnibus.comentario}
                                    rows={4}
                                />
                            </Grid>

                        </Grid>

                        <Typography variant='body2' className="mt-4">
                            El Col·legi té l’obligació imposada per Llei de fer accessible, i per tant públiques, algunes dades professionals dels/de les col·legiats/col·legiades als consumidors i usuaris (article 40 bis, relatiu a la finestreta única, de la Llei 7/2006, del 31 de maig, de l’exercici de professions titulades i dels col·legis professionals).
                        </Typography>

                        <Typography variant='body2' className="mt-3">
                            Aquestes dades hauran d’estar permanentment actualitzades.
                        </Typography>

                        <div className="d-flex justify-content-between mt-4">
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={fetchDatosOmnibusGETData.data?.items?.length > 0 ? handleSubmitDatosPUT : handleSubmitDatosPOST}
                            >
                                {Lang('ACTUALIZAR_DATOS')}
                            </Button>
                            {/* <Button
                                color="primary"
                                variant="outlined"
                                onClick={handleResetFormDatosOmnibus}
                            >
                                {Lang('BORRAR_DATOS')}
                            </Button> */}
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <ModalStatusServer
                fetchData={fetchDatosPOSTData}
                activeLoading
                disableStatus
                messageStatus={
                    {
                        204:
                            <>
                                <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                <Typography sx={{ mt: 2 }}>
                                    S'han actualitzat correctament les dades
                                </Typography>
                            </>
                    }
                }
            />

            <ModalStatusServer
                fetchData={fetchDatosPUTData}
                activeLoading
                disableStatus
                messageStatus={
                    {
                        204:
                            <>
                                <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                <Typography sx={{ mt: 2 }}>
                                    S'han actualitzat correctament les dades
                                </Typography>
                            </>
                    }
                }
            />
        </Box>
    )
}

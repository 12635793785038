import { faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useLang } from '../../language';
import axios from 'axios'
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export const ListFiles = ({ list, onClickDelete, isBlob, extensionDefault }: any) => {

    const Lang = useLang()

    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);

    const { auth } = useSelector((state: RootState) => state.auth)

    const token = auth.token

    // const [fetchDocumento, fetchDocumentoData] = useAxios({ 'get', `/dapcons/${idDapcon}/form/doc-adjuntos-obligatorios/obtener-documento`, '', 'blob'})
    const [fetchDocumentoData, setFetchDocumentoData] = useState<any>({ data: null, loading: null, error: null, status: null });

    const isMounted = useRef(true)

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    const handleFetchFile = (url: any) => {

        setFetchDocumentoData({ data: null, loading: true, error: null, status: null })

        const config: any = { 
            responseType: 'blob' ,
            headers: {
                Authorization: token ? token : '',
            }
        };

        axios.get(url, config).then((response: any) => {
            setFetchDocumentoData({
                loading: false,
                error: null,
                data: response,
                status: response,
            });
        });
    }

    useEffect(() => {

        if (fetchDocumentoData.data) {

            // 2. Create blob link to download
            const docData = fetchDocumentoData.data

            const filename = docData?.headers?.['content-disposition']?.split('filename=')?.[1] || ('default.' + extensionDefault)
            const blobData = docData?.data

            const instanceBlob = new Blob([blobData])

            const date = new Date()

            const url = window.URL.createObjectURL(instanceBlob);
            const link: any = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}_${filename}`);
            // 3. Append to html page
            document.body.appendChild(link);

            // 4. Force download
            link.click();

            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);

            setFetchDocumentoData({ data: null, loading: true, error: null, status: null })

        }
    }, [fetchDocumentoData])

    return (
        <List dense={dense}>
            {
                list?.map((item: any) => {
                    return (
                        <ListItem
                            className='border'
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={(e: any) => onClickDelete(null, item)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <FontAwesomeIcon icon={faFilePdf} />
                                </Avatar>
                            </ListItemAvatar>
                            {
                                isBlob ? <ListItemText
                                    onClick={() => handleFetchFile(item.url)}
                                    className='pointer'
                                    primary={item.titulo}
                                    secondary={secondary ? 'Secondary text' : null}
                                /> :
                                <ListItemText
                                    onClick={() => window.open(item.url || '', '_blank')?.focus()
                                }
                                    className='pointer'
                                    primary={item.titulo}
                                    secondary={secondary ? 'Secondary text' : null}
                                />
                            }

                        </ListItem>
                    )
                })
            }

        </List>
    )
}

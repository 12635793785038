import { faBars, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from '@mui/material';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { getImage } from '../../../helpers';
// import logo from '../../../assets/images/logo.png'
import { useLang } from '../../../language';

import './Navleft.scss'

interface INavleft {
    navLinks: Array<any>;
    setshowNavLeft?: (boolean: boolean) => boolean,
    showNavLeft?: boolean,
    label?: string
}



const Navleft = ({ navLinks, setshowNavLeft = (() => false), showNavLeft, label }: INavleft) => {

    const navigate = useNavigate();

    const handleClickNavigateTo = (url: string) => navigate(`/${url}`)

    const Lang = useLang()

    const getPathExact = () => {

        if (process.env.REACT_APP_SUBDOMAIN === '/') {
            return (window.location.pathname).replace(process.env.REACT_APP_SUBDOMAIN || '', '')
        } else {
            return (window.location.pathname).replace(process.env.REACT_APP_SUBDOMAIN || '', '')
        }
    }

    const isExactPath = (original: any) => {
        if (process.env.REACT_APP_SUBDOMAIN === '/') {
            return original === (window.location.pathname || '/')
        } else {
            return original === ((window.location.pathname).replace(process.env.REACT_APP_SUBDOMAIN || '', '') || '/')
        }
    }

    const isIncludePath = (original: any) => {
        if (process.env.REACT_APP_SUBDOMAIN === '/') {
            return (window.location.pathname || '/').includes(original)
        } else {
            return ((window.location.pathname).replace(process.env.REACT_APP_SUBDOMAIN || '', '') || '/').includes(original)
        }
    }

    return (
        <div className={`LayoutPrimary__Navleft--container`}>
            <div className="LayoutPrimary__NavleftIconBar d-flex align-items-center d-md-none pointer p-2"
                onClick={() => setshowNavLeft(!showNavLeft)}
            >
                <FontAwesomeIcon icon={faBars} className='' style={{ 'fontSize': '1.3rem' }} />
            </div>

            <section className={`LayoutPrimary__Navleft d-flex flex-column`}>
                {/* <img src={logo} alt="" width={150} /> */}

                {/* <Typography variant='body1' className="my-0 py-1 text-center fw-500 mt-3 LayoutPrimary__TextLink">
                    {Lang('MI_COLEGIO')}
                </Typography> */}
                <nav className='LayoutPrimary__Navlinks mb-auto mt-3'>
                    <List>
                        {
                            navLinks.map((item: any) => {
                                return (
                                    <ListItemButton className={`LayoutPrimary__Link ${item.disabled ? 'LayoutPrimary__Link--disabled' : ''}`}
                                        selected={isIncludePath(item.link)}
                                        onClick={() => {
                                            if (window.innerWidth < 900) {
                                                setshowNavLeft(!showNavLeft)
                                            }
                                            if (item.external) {
                                                window.open(item.link)
                                            } else {
                                                navigate(item.link)
                                            }
                                        }}
                                        key={item.link}
                                    >
                                        <ListItemIcon className='LayoutPrimary__IconLink'>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText className='LayoutPrimary__TextLink text-wrap'>{item.title}</ListItemText>
                                    </ListItemButton>
                                )
                            })
                        }
                    </List>
                </nav>

                <img src={getImage('./logov2.png')} alt="" className='LayoutPrimary__Navleft--logo2 d-block mx-auto mb-4' width={200} />

                {/* <section className='LayoutPrimary__navmenu' onClick={() => handleClickNavigateTo('')}>
                    <FontAwesomeIcon icon={faThLarge} className='me-2 fs-5' /> {Lang('MENU_PRINCIPAL')}
                </section> */}
            </section>
            <div id="LayoutPrimary__Navleft--backdrop" onClick={() => setshowNavLeft(!showNavLeft)}></div>
        </div>
    )
}

export default Navleft
import { Backdrop, Box, Button, CircularProgress, Fade, Grid, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import { useLang } from '../../language';

// import './Modal.scss'

export const ModalStatusServer = ({ fetchData, onlySuccess, onlyError, message2 = '', activeLoading, 
messageStatus, disableStatus }: any) => {

    const [showStatus, setShowStatus] = useState(false);

    const [status, setStatus] = useState<any>({
        status: '',
        message: ''
    });

    const handleCloseStatus = () => {
        setShowStatus(false)
    };

    const handleShowStatus = () => setShowStatus(true);

    const Lang = useLang()

    useEffect(() => {

        if (fetchData.loading && activeLoading) {

            setStatus({
                status: '',
                message: <>
                    <p>{<CircularProgress color="primary" />}</p>
                    {/* <p>{fetchData.error?.data?.errors}</p> */}
                </>
            })
            handleShowStatus()

        } else {

            if (onlyError) {

                if (fetchData.error && fetchData.error?.message !== 'form_error') {
                    setStatus({
                        status: fetchData.error?.status || 'Error',
                        message: <>
                            <p>{messageStatus?.[fetchData.status] || fetchData.error?.error}</p>
                            {/* <p>{fetchData.error?.data?.errors}</p> */}
                        </>
                    })
                    handleShowStatus()
                }

            } else if (onlySuccess) {

                if (String(fetchData.status)?.[0] === '2') {
                    setStatus({
                        status: fetchData.data?.status,
                        message: messageStatus?.[fetchData.status] || fetchData.data?.message || Lang('PARRAFO_OPERACION_EXITO')
                    })
                    handleShowStatus()
                }

            } else {


                if (fetchData.error && fetchData.error?.message !== 'form_error') {
                    setStatus({
                        status: fetchData.error?.status || 'Error',
                        message: <>
                            <p>{messageStatus?.[fetchData.status] || fetchData.error?.error}</p>
                            {/* <p>{fetchData.error?.data?.errors}</p> */}
                        </>
                    })
                    handleShowStatus()
                }

                if (fetchData.data) {
                    
                    setStatus({
                        status: fetchData.data?.status,
                        message: messageStatus?.[fetchData.status] || fetchData.data?.message || Lang('PARRAFO_OPERACION_EXITO')
                    })
                    handleShowStatus()
                }
            }

        }

    }, [fetchData])

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 3,
    };


    return (
        <div>
            <Modal
                open={showStatus}
                onClose={handleCloseStatus}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={showStatus}>

                    <Grid container spacing={1} sx={style} className='z-depth-4'>
                        <Grid item xs={12}>
                            <section className='ModalHeader border-0'>
                                <Typography variant="h6" component="h2">
                                    {disableStatus ? '' : status.status}
                                </Typography>
                            </section>
                        </Grid>
                        <Grid item xs={12}>
                            <section className='ModalBody text-center d-flex flex-column'>
                                <div>
                                    {status.message}
                                </div>
                            </section>
                        </Grid>
                        <Grid item xs={12}>
                            <section className='ModalFooter border-0 d-flex justify-content-between mt-2'>
                                <div></div>
                                {
                                    !fetchData.loading &&
                                    <Button variant="outlined" onClick={handleCloseStatus}>
                                        Sortir
                                    </Button>
                                }
                            </section>
                        </Grid>
                    </Grid>
                </Fade>

            </Modal>
        </div>
    )
}

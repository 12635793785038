import { Box, Button, Grid, Paper, Typography } from "@mui/material"
import { useEffect } from "react"
import { useLang } from '../../../language';

export const InscripcionListaPeritosJudicialesAsistenciaReconocida = ({ setBreadcrumbLineItems, route, formContact }: any) => {
    
    const Lang = useLang()

    useEffect(() => {

        setBreadcrumbLineItems((item: any) => [item[0], route])

    }, [])

    const handleClickDocumentGET1 = () => {

        window.open('https://documentacio.enginyersbcn.cat/coneixement/docs/serveis-tecnics/perits-i-tercers-ladministracio/inscripciobaixamodificacio-llista-als-jutjats-segons-lordre-jus4192009', '_blank')?.focus();
    }

    const handleClickDocumentGET2 = () => {

        window.open('https://documentacio.enginyersbcn.cat/coneixement/docs/serveis-tecnics/perits-i-tercers-ladministracio/manual-de-lusuari-servei-de-perits-judicials-i-tercers-ladministracio', '_blank')?.focus();
    }

    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" >

                <Grid item xs={12} className='mb-4 pb-2'>
                    <Paper className='p-3 p-md-4'>

                        <Typography variant='h6' className="mt-2 mb-0 pb-1 fw-500 text-center">
                            {Lang('DOCUMENTOS_ACCESO')}
                        </Typography>

                        <Typography variant='body1' className="my-2">
                            S’haurà d’emplenar i signar el formulari adjunt i enviar-ho per correu electrònic a la següent adreça: <a href="mailto:ebcn@ebcn.cat" className='color-primary'>ebcn@ebcn.cat</a>.
                        </Typography>

                        <div className="d-flex flex-wrap justify-content-around align-items-center mt-4">
                            <Button className='mb-3' color="primary" variant="contained" onClick={handleClickDocumentGET1}>Descarregar formulari</Button>
                            <Button className='mb-3' color="primary" variant="contained" onClick={handleClickDocumentGET2}>Manual de l’usuari</Button>
                        </div>
                        
                    </Paper>
                </Grid>
                
            </Grid>
        </Box>
    )
}

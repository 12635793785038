import { Navigate, Outlet } from "react-router";

interface IRolRoute{
    isAllowedRol: boolean,
    onlyPath: string
}

export const RolRoute = ({ isAllowedRol, onlyPath }: IRolRoute) => {

    return (isAllowedRol) ? <Outlet /> : <Navigate to={`${onlyPath}/`} replace />
}

import { FormControl, Input, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'

export const InputSelect = ({ label, value, values = [], readOnly, onChange, name }: any) => {
    
    return (
        <FormControl fullWidth variant="standard">
            {
                readOnly ? <>
                    <Typography variant='body2' className='mb-1' component="span">
                        {label}
                    </Typography>
                    <Typography variant='body1' className="my-0 pb-1" component="span">
                        {values?.find((item: any) => item.id === value)?.name || '-'}
                    </Typography>
                </> :
                    <>
                        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={value}
                            name={name}
                            onChange={onChange}
                            label="Age"
                        >
                            <MenuItem value="">{' '}</MenuItem>
                            {
                                values.map((item: any) => {
                                    return (
                                        <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </>
            }

        </FormControl>
    )
}

import { faBox, faBriefcase, faCopy, faCube, faFileAlt, faFileMedical, faGlobe, faKey, faLayerGroup, faSearch, faSignature, faUser, faUserFriends, faUserGraduate, faUserPlus, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React, { lazy, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import LayoutPrimary from '../components/Layouts/LayoutPrimary/LayoutPrimary';
import { useLang } from '../language';
import { Dashboard } from '../pages/Dashboard';
import { DatosProfesionales } from '../pages/ActualizacionDatos/DatosProfesionales';
import { DLAbstract } from '../pages/DLAbstract';
import { Login } from '../pages/Login';
import { Sellos } from '../pages/Sellos';
import { Solicitudes } from '../pages/Solicitudes';
import { RootState } from '../store';
import { isAdministrador, isColegiado, isPreColegiado } from './helpers';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { ActualizacionDatos } from '../pages/ActualizacionDatos';
import { AltaGuiaProfesionales } from '../pages/AltaGuiaProfesionales';
import { AltaRegistroCertificadores } from '../pages/AltaRegistroCertificadores';
import { RolRoute } from './RolRoute';
import { PeritosTercerosAdministracion } from '../pages/PeritosTercerosAdministracion';
// Modulos de administracion

export const PrivateRouter = () => {

    const { auth } = useSelector((state: RootState) => state.auth)

    const isLogged = !isEmpty(auth)

    const Lang = useLang()

    const navLinks = [
        {
            icon: <FontAwesomeIcon icon={faUser} />,
            title: Lang('MI_COLEGIO'),
            link: '/el-meu-col-legi',
            show: true
        },
        {
            icon: <FontAwesomeIcon icon={faCopy} />,
            title: Lang('BUSCADOR_DOCUMENTACION_SEGUIMIENTO'),
            link: 'https://documentacio.enginyersbcn.cat/coneixement',
            external: true,
            show: isColegiado(auth.rol) || isPreColegiado(auth.rol)
        },
        {
            icon: <FontAwesomeIcon icon={faSearch} />,
            title: Lang('TECNOVISAT'),
            link: 'https://tecnovisat.enginyersbcn.cat/colegiado/index.php',
            external: true,
            show: isColegiado(auth.rol)
        },
        {
            icon: <FontAwesomeIcon icon={faFileMedical} />,
            title: Lang('GESTIONA_TUS_INSCRIPCIONES'),
            link: 'https://activitats.enginyersbcn.cat/ac_inicio.php',
            external: true,
            show: true
        },
        {
            icon: <FontAwesomeIcon icon={faGlobe} />,
            title: Lang('CAMPUS_VIRTUAL'),
            link: 'https://tecnoaula.enginyersbcn.cat/formacio/auth/jwt/custom_login.php?auto_login=true',
            external: true,
            show: true
        },
        {
            icon: <FontAwesomeIcon icon={faLayerGroup} />,
            title: Lang('CATALOGO_DE_CURSOS'),
            link: 'https://tecnoaula.enginyersbcn.cat/cataleg.php',
            external: true,
            show: true
        },
        {
            icon: <FontAwesomeIcon icon={faBriefcase} />,
            title: Lang('BOLSA_DE_TRABAJO'),
            link: 'https://borsatreball.enginyersbcn.cat/colegiado/inicio.php',
            external: true,
            show: isColegiado(auth.rol) || isPreColegiado(auth.rol)
        },
        {
            icon: <FontAwesomeIcon icon={faUserPlus} />,
            title: Lang('ALTA_GUIA_PROFESIONALES'),
            link: '/alta-guia-professionals',
            show: isColegiado(auth.rol)
        },
        {
            icon: <FontAwesomeIcon icon={faFileMedical} />,
            title: Lang('ALTA_REGISTRO_CERTIFICADORES_EFICIENCIA_ENERGETICA'),
            link: '/certificador-eficiencia-energetica',
            show: isColegiado(auth.rol)
        },
        {
            icon: <FontAwesomeIcon icon={faUserFriends} />,
            title: Lang('PERITOS_TERCEROS_ADMINISTRACION'),
            link: '/perits-tercers-administracio',
            show: isColegiado(auth.rol)
        },
        {
            icon: <FontAwesomeIcon icon={faKey} />,
            title: Lang('SLAM'),
            link: 'https://webpre.enginyersbcn.cat/serveis/serveis-tecnics/slam-servei-de-lloguer-daparells-de-mesura/',
            external: true,
            show: isColegiado(auth.rol)
        },
        {
            icon: <FontAwesomeIcon icon={faBox} />,
            title: Lang('DL_ABSTRACT'),
            link: 'http://www.ictonline.es/bt/enter-page.xml?i=1&id_bt=153&url_desti=http://nol.infocentre.es/ictnol/ictnol?accio=login',
            external: true,
            show: isColegiado(auth.rol)
        },
        {
            icon: <FontAwesomeIcon icon={faUserGraduate} />,
            title: Lang('AREA_DE_COLEGIACION'),
            link: 'https://webpre.enginyersbcn.cat/collegiat/em-vull-collegiar/',
            external: true,
            show: !isColegiado(auth.rol)
        },
        {
            icon: <FontAwesomeIcon icon={faCube} />,
            title: Lang('TOOLBOX'),
            link: 'https://toolbox.cogiti.es/',
            external: true,
            show: isColegiado(auth.rol)
        },
        {
            icon: <FontAwesomeIcon icon={faUser} />,
            title: Lang('ACTUALIZACION_DATOS'),
            link: '/actualitzacio-dades',
            show: isColegiado(auth.rol) || isPreColegiado(auth.rol)
        },
        {
            icon: <FontAwesomeIcon icon={faFileAlt} />,
            title: Lang('SOLICITUDES'),
            link: '/sol-licituds',
            show: isColegiado(auth.rol)
        },
        {
            icon: <FontAwesomeIcon icon={faSignature} />,
            title: Lang('SELLOS'),
            link: '/segells',
            show: isColegiado(auth.rol)
        },
    ]

    return (
        <section className='LayoutPrimary animate__animated animate__fadeIn'>

            <LayoutPrimary label={Lang('AREA_DE_ADMINISTRACION')}>

                <LayoutPrimary.Navtop />

                <LayoutPrimary.Navleft navLinks={navLinks?.filter((item: any) => item.show)} />

                <LayoutPrimary.Body>

                    <Routes>

                        <Route path='/actualitzacio-dades/*' element={<RolRoute isAllowedRol={isColegiado(auth.rol) || isPreColegiado(auth.rol)} onlyPath='' />}>

                            <Route path='*' element={<ActualizacionDatos onlyPath='/actualitzacio-dades' />} />

                        </Route>

                        <Route
                            path='/el-meu-col-legi'
                            element={<Dashboard />}
                        />

                        <Route path='/alta-guia-professionals' element={<RolRoute isAllowedRol={isColegiado(auth.rol)} onlyPath='' />}>

                            <Route index element={<AltaGuiaProfesionales />} />

                        </Route>

                        <Route path='/certificador-eficiencia-energetica/*' element={<RolRoute isAllowedRol={isColegiado(auth.rol)} onlyPath='' />}>

                            <Route path='*' element={<AltaRegistroCertificadores onlyPath='/certificador-eficiencia-energetica' />} />

                        </Route>

                        <Route path='/perits-tercers-administracio/*' element={<RolRoute isAllowedRol={isColegiado(auth.rol)} onlyPath='' />}>

                            <Route path='*' element={<PeritosTercerosAdministracion onlyPath='/perits-tercers-administracio' />} />

                        </Route>


                        <Route path='/segells/*' element={<RolRoute isAllowedRol={isColegiado(auth.rol)} onlyPath='' />}>

                            <Route path='*' element={<Sellos onlyPath='/segells' />} />

                        </Route>

                        <Route path='/sol-licituds/*' element={<RolRoute isAllowedRol={isColegiado(auth.rol)} onlyPath='' />}>

                            <Route path='*' element={<Solicitudes onlyPath='/sol-licituds' />} />

                        </Route>

                        <Route
                            path="*"
                            element={<Navigate to="/el-meu-col-legi" replace />}
                        />

                    </Routes>

                </LayoutPrimary.Body>

            </LayoutPrimary>

        </section>
    )
}
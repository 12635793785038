import { styled } from '@mui/material/styles';

import {
    Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup,
    FormLabel, Grid, Link, Paper, Radio, RadioGroup, Tab, Tabs, TextField, Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

import './RestablecerClave.scss'
import { getImage, validExiste, validIsEqual } from '../../helpers';
import { useLang } from '../../language';
import { changeLanguage } from '../../store/slices/language';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store';
import { logIn, logOut } from '../../store/slices/auth';
import { LoginServices, ResetEmailServices } from '../../controllers/services/auth.services';
import { InputText } from '../../components/Inputs/InputText';
import { useValidator } from '../../hooks/useValidator';
import { useForm } from '../../hooks/useForm';
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';

export const RestablecerClave = () => {

    const Lang = useLang()

    // let params = useParams();

    const location = useLocation()

    const params = queryString.parse(location.search)


    const [value, setvalue] = useState(0)

    const [fetchResetEmailPOST, fetchResetEmailPOSTData] = ResetEmailServices.POST()

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setvalue(newValue);
    };

    const dispatch = useDispatch()

    const { language } = useSelector((state: RootState) => state.language)

    const [validLogin, handleValidationLogin] = useValidator({
        password: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        password_confirmation: [
            { functionValidMultipleKeys: (form: any) => validIsEqual(form.password, form.password_confirmation), msgError: Lang('VALID_CONFIRMAR_CLAVE'), isOk: true },
        ],
    })

    const [formLogin, handleInputLogin, handleFormLogin, handleResetFormLogin] = useForm({
        password: '',
        password_confirmation: '',
    })

    const handleClickLogIn = () => {

        if (handleValidationLogin(formLogin)) {

            fetchResetEmailPOST({
                params: {
                    password: formLogin.password,
                    token: params?.token || ''
                }
            })
        }
    }

    const [fetchLoginPOST, fetchLoginPOSTData] = LoginServices.POST()

    const [cookies, setCookie] = useCookies(['CookieTokenEBCN']);

    useEffect(() => {

        if (fetchResetEmailPOSTData.status === 202) {

            fetchLoginPOST({
                params: {
                    username: params.username,
                    password: formLogin.password
                }
            })
        }

    }, [fetchResetEmailPOSTData])

    const navigate = useNavigate()

    useEffect(() => {

        if (fetchLoginPOSTData.status === 200) {

            setCookie('CookieTokenEBCN', fetchLoginPOSTData.data.token, {
                path: '/',
                domain: process.env.REACT_APP_ENVIRONMENT === 'local' ? '' : process.env.REACT_APP_COOKIE_DOMAIN,
                maxAge: 14400
            })

            const dataToken: any = jwt_decode(fetchLoginPOSTData.data.token)

            if (params.url_redirect) {

                localStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE || '', JSON.stringify({
                    username: dataToken.sub,
                    name: dataToken.name,
                    rol: dataToken.rol,
                    exp: dataToken.exp,
                    token: fetchLoginPOSTData.data.token
                }))

                const url_redirectTemp: any = params.url_redirect || ''

                window.location.href = String(decodeURIComponent(url_redirectTemp || ''))

            } else {

                dispatch(logIn({
                    username: dataToken.sub,
                    name: dataToken.name,
                    rol: dataToken.rol,
                    exp: dataToken.exp,
                    token: fetchLoginPOSTData.data.token
                }))

                navigate('/')
            }

        }

    }, [fetchLoginPOSTData])


    return (
        <Box className='Restablecer'>
            <Grid container className='Restablecer__Container' justifyContent='center' alignItems='center' flexDirection='column'>
                <Grid item className='Restablecer__left z-depth-4 d-flex flex-column'>

                    <div className="Restablecer__Body px-3 px-sm-3 px-lg-4">
                        <div>
                            <Typography variant='h5' className="my-3 py-1 text-center text-white fw-500">
                                {Lang('RESTABLECER_CLAVE')}
                            </Typography>
                            {/* <Typography className="my-0 py-1 text-center text-white opacity-8">
                                <small>{Lang('PARRAFO_LOGIN')}</small>
                            </Typography> */}
                            <Box sx={{ width: '100%' }}>

                                <Box
                                    component="form"
                                    noValidate
                                    autoComplete="off"
                                >
                                    {/* <InputText
                                        label={Lang('USUARIO')}
                                        name='username'
                                        value={formLogin.username}
                                        onChange={handleInputLogin}
                                        localValidation={validLogin.username}
                                        className='my-2 w-100'
                                    /> */}
                                    {/* <TextField id="standard-basic" label={Lang('NUMERO_COLEGIADO')} variant="standard"
                                            className=''
                                        /> */}

                                    <InputText
                                        label={Lang('CLAVE')}
                                        type='password'
                                        name='password'
                                        value={formLogin.password}
                                        onChange={handleInputLogin}
                                        localValidation={validLogin.password}
                                        className='mt-2 mb-3 w-100'
                                    />

                                    <InputText
                                        label={Lang('CONFIRMAR_CLAVE')}
                                        type='password'
                                        name='password_confirmation'
                                        value={formLogin.password_confirmation}
                                        onChange={handleInputLogin}
                                        localValidation={validLogin.password_confirmation}
                                        className='mt-2 mb-3 w-100'
                                    />

                                    {/* <TextField id="standard-basic" label={Lang('CLAVE')} variant="standard" type='password'
                                            className='mt-2 mb-3 w-100'
                                        /> */}


                                    <Button variant="outlined" className='btn_Restablecer_form my-3 border-0' onClick={handleClickLogIn}>
                                        {Lang('RESTABLECER_CLAVE')}
                                    </Button>

                                </Box>

                            </Box>
                        </div>
                    </div>

                    <div className="Restablecer__Footer d-flex flex-column align-items-center mt-4 mb-4">
                        <img src={getImage('./logo-white.png')} alt="" />
                        <div className='d-flex justify-content-center d-none'>
                            <Typography className={`py-1 ${language === 'CA' ? 'color-secondary' : 'text-white opacity-8'}`}>
                                <small>
                                    <Link onClick={() => dispatch(changeLanguage('CA'))} color="inherit" underline="hover" className='mx-2 pointer'>
                                        {Lang('CATALAN')}
                                    </Link>
                                </small>
                            </Typography>
                            <Typography className={`py-1 ${language === 'ES' ? 'color-secondary' : 'text-white opacity-8'}`}>
                                <small>
                                    <Link onClick={() => dispatch(changeLanguage('ES'))} color="inherit" underline="hover" className='mx-2 pointer'>
                                        {Lang('ESPANIOL')}
                                    </Link>
                                </small>
                            </Typography>
                        </div>
                    </div>


                </Grid>
                <Grid item className='Restablecer__bottom d-flex align-items-end text-center'>
                    <footer className='py-2'>
                        <Typography className="my-0 py-1 px-3">
                            © Col·legi d’Enginyers Graduats i Enginyers Tècnics Industrials de Barcelona - Tots els drets reservats
                        </Typography>
                        <div className='d-flex justify-content-center flex-column flex-sm-row'>
                            <Typography className="py-1">
                                <Link href="https://www.enginyersbcn.cat/" color="inherit" underline="hover" className='mx-2'>
                                    ENGINYERS BCN
                                </Link>
                            </Typography>
                            <Typography className="py-1">
                                <Link href="https://www.enginyersbcn.cat/avis-legal.html" color="inherit" underline="hover" className='mx-2'>
                                    Avís Legal
                                </Link>
                            </Typography>
                            <Typography className="py-1">
                                <Link href="https://www.enginyersbcn.cat/accessibilitat.html" color="inherit" underline="hover" className='mx-2'>
                                    Accessibilitat
                                </Link>
                            </Typography>
                            <Typography className="py-1">
                                <Link href="https://www.enginyersbcn.cat/informacio-cookies.html" color="inherit" underline="hover" className='mx-2'>
                                    Cookies
                                </Link>
                            </Typography>
                        </div>
                    </footer>

                </Grid>
                <div className="Restablecer__ContainerBackgroundImage">
                    <img src={getImage('./edifici.jpg')} alt="" />
                </div>

            </Grid>
        </Box>
    )
}


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

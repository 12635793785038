import { sendString } from "./sends"

const UserEbcnAdapters = {

    PUT_RESET_PASSWORD: {

        send: {

            schema: function (item: any = {}) {

                return {
                    username: sendString(item.username),
                    newPassword: sendString(item.newPassword),
                }
            },

            adapter: function (data: any) {

                const item = this.schema(data)

                return {
                    ...item
                }
            }
        },

        receive: {

            schema: function (item: any = {}) {

                return {
                    token: item.token
                }
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const res = this.schema(dataTemp)

                return res
            }
        },
    },

}

export {
    UserEbcnAdapters
}
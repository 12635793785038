export function isAdministrador(rol: string){
    return rol === 'ROLE_ADMINISTRADOR'
}

export function isColegiado(rol: string){
    return rol === 'ROLE_COLEGIADO'
}

export function isPreColegiado(rol: string){
    return rol === 'ROLE_PRE_COLEGIADO'
}
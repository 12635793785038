export function receiveString(value: any) {

    return value || ''
}

export function receiveNumber(value: any) {

    return value !== undefined ? value : 0
}

export function receiveObject(value: any) {

    return value !== undefined ? value : {}
}

export function receiveBoolean(value: any) {

    return (value === 'true' || value === true) ? true : false
}

export function parseString(value: any) {

    return value || ''
}

export function parseNumber(value: any) {

    return value !== undefined ? value : 0
}

export function parseObject(value: any) {

    return value !== undefined ? value : {}
}

export function parseArray(value: any) {

    return value !== undefined ? value : []
}


export function parseBoolean(value: any) {

    return (value === 'true' || value === true) ? true : false
}

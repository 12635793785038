import { isEmpty } from 'lodash';
import React, { lazy, useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { TokenServices } from '../controllers/services/auth.services';
import { Dashboard } from '../pages/Dashboard';
import { Login } from '../pages/Login';
import { LoginPublico } from '../pages/LoginPublico';
import { RestablecerClave } from '../pages/RestablecerClave/RestablecerClave';
import { RootState } from '../store';
import { logOut } from '../store/slices/auth';
import { isAdministrador } from './helpers';
import { PrivateRoute } from './PrivateRoute';
import { PrivateRouter } from './PrivateRouter';
import { PublicRoute } from './PublicRoute';
// Modulos de administracion

export const AppRouter = () => {

    const { auth } = useSelector((state: RootState) => state.auth)

    const isLogged = !isEmpty(auth)
    const dispatch = useDispatch()

    const [fetchValidateTokenPOST, fetchValidateTokenPOSTData] = TokenServices.POST()
    const [cookies, setCookie, removeCookie] = useCookies(['CookieTokenEBCN']);

    const handleClickLogOut = () => {

        removeCookie('CookieTokenEBCN', {
            path: '/',
            domain: process.env.REACT_APP_ENVIRONMENT === 'local' ? '' : process.env.REACT_APP_COOKIE_DOMAIN,
        })
        dispatch(logOut())
    }


    useEffect(() => {
        if (isLogged) {
            fetchValidateTokenPOST({
                params: {
                    token: auth.token
                }
            })
        }
        
    }, [])

    useEffect(() => {

        if (fetchValidateTokenPOSTData.status === 200) {

            if (!fetchValidateTokenPOSTData.data?.data) {
                
                handleClickLogOut()
            }
        }
    }, [fetchValidateTokenPOSTData])


    return (
        <Routes>

            <Route path='login' element={<PublicRoute isAuthenticated={isLogged} />}>

                <Route index element={<Login />} />

            </Route>

            <Route path='iniciar-sessio' element={<LoginPublico />} />

            <Route path='restablecer' element={<RestablecerClave />} />

            <Route path='/*' element={<PrivateRoute isAuthenticated={isLogged} isAllowedRol={true} />}>

                <Route path='*' element={<PrivateRouter />} />

            </Route>

            <Route path='default' element={<Login />} />

            <Route path="*" element={<Navigate to="./" replace />} />

        </Routes>
    )
}
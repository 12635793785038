import { lazy, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'

let languageImported: any = {}

export const useLang = () => {
    
    const { language } = useSelector((state: RootState) => state.language)

    const Lang = (text: any) => {

        if (language) {

            if (languageImported[language]) {

                return languageImported[language][text]

            } else {

                languageImported = require(`./${language}`).default;
                // languageImported = lazy(() => import(`./${language}Lang`).then((module: any) => (
                //     { default: module.default }
                // )));
            }

            return languageImported[language]?.[text]
        }
    }

    return Lang;
}

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Backdrop, Box, Button, CircularProgress, Fade, Grid, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import { useLang } from '../../language';

// import './Modal.scss'

export const ModalInfo = ({ data, handleClose, message }: any) => {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 3,
    };

    return (
        <div>
            <Modal
                open={data.value}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={data.value}>

                    <Grid container spacing={1} sx={style} className='z-depth-4'>
                        <Grid item xs={12}>
                            <section className='ModalHeader border-0 text-center'>
                                <Typography variant="h6" component="h2">
                                    <FontAwesomeIcon icon={faInfoCircle} className='fa-2x' />
                                </Typography>
                            </section>
                        </Grid>
                        <Grid item xs={12}>
                            <section className='ModalBody text-center d-flex flex-column'>
                                <div>
                                    {message}
                                </div>
                            </section>
                        </Grid>
                    </Grid>
                </Fade>

            </Modal>
        </div>
    )
}

import { BreadCrumbCustom } from '../../components/BreadCrumbCustom/BreadCrumbCustom';
import { Box, Button, Grid, List, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLang } from '../../language';
import { SolicitudCertificado } from './SolicitudCertificado';
import { Navigate, Route, Routes } from 'react-router';
import { SubList } from '../../components/SubList/SubList';
import { SolicitudCarnetColegiado } from './SolicitudCarnetColegiado';
import { SolicitudCorreoCorporativo } from './SolicitudCorreoCorporativo';
import { DatosServices } from '../ActualizacionDatos/controllers/services/datos.services';

export const Solicitudes = ({ onlyPath }: any) => {

    const Lang = useLang()

    const [BreadcrumbLineItems, setBreadcrumbLineItems] = useState<any>([
        {
            title: Lang('SOLICITUDES'),
            link: onlyPath
        }
    ])

    const listRoutes = [
        {
            title: Lang('SOLICITUD_CERTIFICADO_COLEGIACION'),
            link: '/sol-licitud-certificat'
        },
        {
            title: Lang('CORREO_ELECTRONICO_CORPORATIVO'),
            link: '/sol-licitud-correu-corporatiu'
        },
        {
            title: Lang('SOLICITUD_CARNET_COLEGIADO'),
            link: '/sol-licitud-carnet-col-legiat'
        },
    ]

    
    const [fetchDatosGET, fetchDatosGETData] = DatosServices.GET()

    useEffect(() => {

        fetchDatosGET({
            params: {
                'tipo-direccion': 'CONTACT'
            }
        })

    }, [])


    return (
        <div>
            <BreadCrumbCustom items={BreadcrumbLineItems} />

            <section className='LayoutPrimary__contentmain'>

                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className='Paper__SubList'>

                                <SubList
                                    listRoutes={listRoutes}
                                    parentPath={onlyPath}
                                />

                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>

                            <Routes>

                                <Route
                                    path={listRoutes[0].link}
                                    element={<SolicitudCertificado formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[0]} />}
                                />

                                <Route
                                    path={listRoutes[1].link}
                                    element={<SolicitudCorreoCorporativo formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[1]} />}
                                />

                                <Route
                                    path={listRoutes[2].link}
                                    element={<SolicitudCarnetColegiado formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[2]} />}
                                />

                                <Route path="*" element={<Navigate to={`${onlyPath}${listRoutes[0].link}`} replace />} />
                                
                            </Routes>

                        </Grid>
                    </Grid>
                </Box>
            </section>
        </div>
    )
}

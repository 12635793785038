import { isEqual } from "lodash"

export function getImage(relativePath: string) {

  let image: any = {}

  image = require.context('../assets/images', true)

  let res = image(relativePath)

  return res.default || res
}

// Validations

export function validExiste(value: string) {

  return value !== undefined && value !== null && value !== ''
}

export function validIsEqual(value: string, value2: string) {

  return isEqual(value, value2)
}

export function validCorrectEmail(value: string) {

  const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

  return value.match(regExp) !== null
}

export function validCorrectMovil(value: string) {

  const regExp = "^[0-9]+$"

  return value.match(regExp) !== null
}

// Helpers

export function dataURLtoFile(dataurl: any, filename: any) {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function removeDuplicatesObject(arr: any) {

  console.log('arr', arr);
  const result = Object.values(arr.reduce((acc: any, obj: any) => ({ ...acc, [obj.id]: obj }), {}));

  console.log(result);
  return result
}

export function isStatusSucess(status: any) {

  return String(status)?.[0] === '2'
}
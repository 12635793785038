import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useCookies } from 'react-cookie';

interface UseAxios {
    method: string,
    url: string,
    type: any,
    adapters: any,
}

interface IState {
    data: any,
    loading: any,
    error: any,
    status: any
}


interface IReturn {
    data: any,
    loading: any,
    error: any,
    status: any
}

export const useAxios = ({ method, url, type, adapters }: UseAxios) => {

    // const { user: { token, lang }, dispatch } = useContext(AuthContext);

    const { auth } = useSelector((state: RootState) => state.auth)

    const token = auth.token
    const navigate = useNavigate();

    const isMounted = useRef(true)

    const [state, setState] = useState<IState>({ data: null, loading: null, error: null, status: null });

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    const existenErroresAuth = (data: any) => {

        if (data && data.status === 'Error' &&
            (data.message === 'El token ha expirat' ||
                data.message === `No s'ha trobat el token` ||
                data.message === `La sessió no existeix` ||
                data.message === `El token és invàlid` ||
                data.message === `Unauthenticated.` ||
                data.message === `No autoritzat`)) {
                    
            // navigate(process.env.REACT_APP_PUBLIC_URL_PORTAL + '/login.php');
            localStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE || '', '{}')
            window.location.replace(process.env.REACT_APP_PUBLIC_URL_PORTAL + '/login.php');
            return true
        }
        return false
    }

    const handleFetch = (values?: any) => {

        setState({ data: null, loading: true, error: null, status: null })

        axios.request<IState>({
            method,
            url: values?.id ? url + '/' + values.id : url,
            headers: {
                // Authorization: token ? 'Bearer ' + token : '',
                Authorization: token ? token : '',
            },
            responseType: type,
            data: values?.body ? adapters['send']?.['adapter'] ? adapters['send']?.['adapter'](values.body) : values.body : {},
            params: values?.params
        })
            .then((res: any) => {
                
                if (!existenErroresAuth(res) && isMounted.current) {
                    const dataAdapted = adapters['receive']?.['adapter'] ? adapters['receive']?.['adapter'](res) : res
                    
                    setState({
                        loading: false,
                        error: null,
                        data: dataAdapted,
                        status: res.status,
                    });
                }
            })
            .catch((err) => {
                
                existenErroresAuth(err?.response?.data)

                setState({
                    data: null,
                    loading: false,
                    error: err?.response?.data,
                    status: err?.response?.status
                })
            })
            .finally(() => {
                // setloading(false);
            });

    }

    const handleResetData = () => {
        setState({ data: null, loading: null, error: null, status: null });
    }

    return [handleFetch, state, handleResetData] as const;
}

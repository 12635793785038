import { styled } from '@mui/material/styles';

import {
    Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup,
    FormLabel, Grid, Paper, Radio, RadioGroup, Tab, Tabs, TextField, Typography
} from "@mui/material"
import { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCopy, faFile, faFileAlt, faUser, faUserAlt, faUserCheck, faUsers } from '@fortawesome/free-solid-svg-icons';
import { BreadCrumbCustom } from '../../components/BreadCrumbCustom/BreadCrumbCustom';
import { useLang } from '../../language';

import './index.scss'
import { getImage } from '../../helpers';

export const Dashboard = () => {

    const Lang = useLang()

    const BreadcrumbLineItems = [
        {
            title: Lang('MI_COLEGIO'),
            link: '/el-meu-col-legi'
        }
    ]

    return (

        <div className='ContainerDashboard'>

            {/* <div className='ContainerDashboard__background'>
                <img src={getImage('./edifici.jpg')} alt="" id='' />
            </div> */}

            <BreadCrumbCustom items={BreadcrumbLineItems} />

            <section className='LayoutPrimary__contentmain grow-1 p-3 p-lg-5'>

                <Box>
                    <Grid container spacing={3} justifyContent="center" >

                        <Grid item xs={12} md={10} lg={10} xl={8} className='mb-2 pb-2'>

                            <Grid item xs={12} className='mb-2 pb-2'>

                                <Paper sx={{ width: '100%', maxWidth: '100%' }} className='DashboardCard mx-auto'>

                                    <div className="DashboardCard__header p-3">
                                        <Typography variant='h6' className="my-0 fw-500 color-primary-2 text-center">
                                            BENVINGUT A EL MEU COL·LEGI, EL NOU ESPAI WEB D'ENGINYERS BCN
                                        </Typography>
                                    </div>

                                    <div className="DashboardCard__body p-3 pt-4">

                                        <div className="Item__info d-flex">
                                            <div className='Item__icon'>
                                                <FontAwesomeIcon icon={faUser} className='color-primary' />
                                            </div>
                                            <Typography variant='body1' className="my-0 pb-3 fw-500">
                                                Accedeix a les plataformes del col·legi que tens disponibles, mitjançant el menú lateral. Plega'l o desplega'l en funció de les teves necessitats.
                                            </Typography>
                                        </div>

                                        <div className="Item__info d-flex">
                                            <div className='Item__icon'>
                                                <FontAwesomeIcon icon={faUsers} className='color-primary' />
                                            </div>
                                            <Typography variant='body1' className="my-0 pb-3 fw-500">
                                                Gestiona les teves dades. Si ets col·legiat, dona't d'alta o modifica el teu perfil a la Guia de professionals i el Registre de Certificadors d'Eficiència Energètica en Edificis.
                                            </Typography>
                                        </div>

                                        <div className="Item__info d-flex">
                                            <div className='Item__icon'>
                                                <FontAwesomeIcon icon={faFileAlt} className='color-primary' />
                                            </div>
                                            <Typography variant='body1' className="my-0 pb-3 fw-500">
                                                Descarrega't els Segells del col·legi, sol·licita un certificat de col·legiació o un duplicat del teu carnet de col·legiat.
                                            </Typography>
                                        </div>

                                        <div className="Item__info d-flex">
                                            <div className='Item__icon'>
                                                <FontAwesomeIcon icon={faCopy} className='color-primary' />
                                            </div>
                                            <Typography variant='body1' className="my-0 pb-3 fw-500">
                                                Localitza tota la documentació de manera àgil, fent servir el cercador per paraula clau o el sistema de carpetes i subcarpetes, segons el tema. El nou Cercador de documentació i coneixement també inclou les preguntes freqüents amb les respostes generades pels diferents serveis.
                                            </Typography>
                                        </div>

                                        <div className="Item__info d-flex">
                                            <div className='Item__icon'>
                                                <FontAwesomeIcon icon={faUserCheck} className='color-primary' />
                                            </div>
                                            <div>
                                            <Typography variant='body1' className="my-0 pb-0 fw-500">
                                                Ara realitzar les teves gestions amb el Col·legi és molt senzill. 
                                            </Typography>
                                            <Typography variant='body1' className="my-0 pb-3 fw-500">
                                                I si tens cap consulta, posa’t en contacte amb nosaltres a <a href="mailto:ebcn@ebcn.cat" className='color-primary'>ebcn@ebcn.cat</a>
                                            </Typography>
                                            </div>
                                        </div>

                                    </div>

                                </Paper>

                            </Grid>
                        </Grid>


                    </Grid>
                </Box>
            </section>
        </div>
    )
}

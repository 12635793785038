import { cloneDeep } from "lodash";

// Tipos de adaptador de RECEIVE

export function receiveDefault(value: any) {

    return value?.[0] ? true : false
}



export function sendCheck(value: any) {

    return value?.[0] ? true : false
}

export function receiveCheck(value: boolean, name: string) {

    return value ? [`${name}`] : []
}

export function receiveFecha(value: string) {

    if (value?.indexOf('T') !== -1) {
        return value?.split('T')?.[0] || ''

    } else {

        return value?.split(' ')?.[0] || ''
    }
}

export function receiveFechaFormat2(value: string) {

    // Recibiendo ya en formato yyyy-mm-dd

    return value.split('-').reverse().join('/')

}

export function receiveDoubleBackslash(value: string) {

    return value.replace('\\', '&#92;&#92;')
}

export function sendDoubleBackslash(value: string) {

    return value.replace('&#92;&#92;', '\\')
}

export function sendMultipartEmail(items: any) {

    const json = JSON.stringify(items?.['comunicacion']);

    const blob = new Blob([json], {
        type: 'application/json'
    });

    const data = new FormData();

    data.append("comunicacion", blob);

    if (items['adjunto']) {
        data.append("adjunto", items?.['adjunto']);
    }

    return data
}

export function sendMultipart(items: any) {

    let formData = new FormData();

    for (const key in items) {
        formData.append(key, items[key])
    }

    return formData
}


// Helpers para Clear Fields

export function clearMultiple(data: any, ...res: any) {

    let dataTemp = cloneDeep(data)

    for (let i = 0; i < res.length; i++) {

        dataTemp = res[i](dataTemp)
    }

    return dataTemp
}

export function clearFieldsDependentAbona(data: any) {

    const dataTemp = cloneDeep(data)

    if (dataTemp.abona === 'E') {

        if (!dataTemp['idpersona']) delete dataTemp['idpersona']

    } else if (dataTemp.abona === 'P') {

        if (!dataTemp['identidad']) delete dataTemp['identidad']
        if (!dataTemp['identidadsede']) delete dataTemp['identidadsede']
    }

    return dataTemp
}

export function clearFieldsEmptyIdFactura(data: any) {

    const dataTemp = cloneDeep(data)

    if (!dataTemp['idfactura']) delete dataTemp['idfactura']

    return dataTemp
}

export function clearFieldsDependentRemesa(data: any) {

    const dataTemp = cloneDeep(data)

    // Diferente del id remesa

    if (dataTemp.idcondicionpago !== 'f2dfe1b6-72a6-c14a-a18e-ba59af0d05ba') {

        delete dataTemp['cuentacorriente']
        delete dataTemp['iban']
        delete dataTemp['idbanco']
        delete dataTemp['sucursal']
        delete dataTemp['titularcuenta']
        delete dataTemp['digitocontrol']
        delete dataTemp['idcuentabancaria']
    }

    return dataTemp
}


export function clearFieldsEmptyEstadoCobro(data: any) {

    const dataTemp = cloneDeep(data)

    // Diferente del id remesa

    if (dataTemp.estado !== 'C') {

        delete dataTemp['fechacobro']
    }

    return dataTemp
}

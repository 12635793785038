import { useAxios } from "../../hooks/useAxios"
import { ComunicacionAdapters } from "../adapters/comunicaciones.adapters"

const ComunicacionServices = {

    POST: function () {

        return useAxios({ method: 'post', url: '/emails/comunicaciones', type: 'json', adapters: ComunicacionAdapters.POST })
    },
}

export {
    ComunicacionServices
}
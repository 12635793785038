import { configureStore } from '@reduxjs/toolkit'

import { authSlice } from './slices/auth'
// import { counterSlice } from './slices/counter'
import { languageSlice } from './slices/language'

export const store = configureStore({
  reducer: {
    auth : authSlice.reducer,
    // counter : counterSlice.reducer,
    language : languageSlice.reducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
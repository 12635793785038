import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Backdrop, Box, Button, Fade, Grid, Modal, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { UserEbcnServices } from '../../../controllers/services/user.services';
import { validExiste, validIsEqual } from '../../../helpers';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { useLang } from '../../../language';
import { RootState } from '../../../store';
import { InputText } from '../../Inputs/InputText';
import { ModalStatusServer } from '../../ModalStatus/ModalStatusServer';
import axios from 'axios';

export const ModalCambiarPassword = ({ showModal_CambiarPassword, handleCloseModal_CambiarPassword }: any) => {

    const Lang = useLang()

    const { auth } = useSelector((state: RootState) => state.auth)


    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 3,
    };

    // Servicio cambiar password

    const [fetchCambiarPasswordPOST, fetchCambiarPasswordPOSTData] = UserEbcnServices.PUT_RESET_PASSWORD()

    const [formCambiarPassword, handleInputCambiarPassword, handleCambiarPassword, handleResetCambiarPassword] = useForm({
        password: '',
        newPassword: '',
    })

    const [validLogin, handleValidationLogin] = useValidator({
        password: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        newPassword: [
            { functionValidMultipleKeys: (form: any) => validIsEqual(form.password, form.newPassword), msgError: Lang('VALID_CONFIRMAR_CLAVE'), isOk: true },
        ],
    })

    const handleClickLogIn = () => {

        if (handleValidationLogin(formCambiarPassword)) {

            fetchCambiarPasswordPOST({
                params: {
                    newPassword: formCambiarPassword.newPassword,
                    // username: auth?.username || '',
                    // username: '32000' || '',
                }
            })
        }
    }


    useEffect(() => {

        if (String(fetchCambiarPasswordPOSTData.status)?.[0] === '2') {

            handleCloseModal_CambiarPassword()
        }

    }, [fetchCambiarPasswordPOSTData])


    return (
        <div>
            <Modal
                open={showModal_CambiarPassword.value}
                onClose={handleCloseModal_CambiarPassword}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={showModal_CambiarPassword.value}>

                    <Grid container spacing={1} sx={style} className='z-depth-4'>
                        <Grid item xs={12}>
                            <section className='ModalHeader border-0'>
                                <Typography variant="h6" component="h2">
                                    {Lang('MODIFICAR_CONTRASENIA')}
                                </Typography>
                            </section>
                        </Grid>
                        <Grid item xs={12}>
                            <section className='ModalBody text-center d-flex flex-column'>
                                <div>
                                    <Box sx={{ width: '100%' }}>

                                        <Box
                                            component="form"
                                            noValidate
                                            autoComplete="off"
                                        >

                                            <InputText
                                                label={Lang('CONTRASENIA')}
                                                type='password'
                                                name='password'
                                                value={formCambiarPassword.password}
                                                onChange={handleInputCambiarPassword}
                                                localValidation={validLogin.password}
                                                className='mt-2 mb-3 w-100'
                                            />

                                            <InputText
                                                label={Lang('CONFIRMAR_CONTRASENIA')}
                                                type='password'
                                                name='newPassword'
                                                value={formCambiarPassword.newPassword}
                                                onChange={handleInputCambiarPassword}
                                                localValidation={validLogin.newPassword}
                                                className='mt-2 mb-3 w-100'
                                            />

                                        </Box>

                                    </Box>
                                </div>
                            </section>
                        </Grid>
                        <Grid item xs={12}>
                            <section className='ModalFooter border-0 d-flex justify-content-between align-items-center mt-2'>
                                <Button variant="outlined" onClick={handleCloseModal_CambiarPassword}>
                                    Sortir
                                </Button>
                                <Button variant="contained" className='btn_Restablecer_form border-0' onClick={handleClickLogIn}>
                                    {Lang('RESTABLECER_CLAVE')}
                                </Button>
                            </section>
                        </Grid>
                    </Grid>
                </Fade>

            </Modal>

            <ModalStatusServer
                fetchData={fetchCambiarPasswordPOSTData}
                activeLoading
                disableStatus
                messageStatus={
                    {
                        201:
                            <>
                                <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                <Typography sx={{ mt: 2 }}>
                                    S'ha canviat correctament la contrasenya
                                </Typography>
                            </>
                    }
                }
            />
        </div>
    )
}

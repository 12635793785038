import { styled } from '@mui/material/styles';

import {
    Alert,
    Box, Button, ButtonGroup, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup,
    FormLabel, Grid, Link, Modal, Paper, Radio, RadioGroup, Tab, Tabs, TextField, Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCoffee, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import './Index.scss'
import { getImage, validExiste } from '../../helpers';
import { useLang } from '../../language';
import { changeLanguage } from '../../store/slices/language';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store';
import { logIn, logOut } from '../../store/slices/auth';
import { LoginServices, ResetEmailServices } from '../../controllers/services/auth.services';
import { InputText } from '../../components/Inputs/InputText';
import { useValidator } from '../../hooks/useValidator';
import { useForm } from '../../hooks/useForm';
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import queryString from 'query-string';
import { ModalStatusServer } from '../../components/ModalStatus/ModalStatusServer';

export const Login = () => {

    const Lang = useLang()

    const [value, setvalue] = useState(0)

    const [fetchLoginPOST, fetchLoginPOSTData] = LoginServices.POST()

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setvalue(newValue);
    };

    const dispatch = useDispatch()

    const { language } = useSelector((state: RootState) => state.language)

    const [validLogin, handleValidationLogin] = useValidator({
        username: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        password: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CLAVE'), isOk: true },
        ],
    })


    const location = useLocation()

    const params = queryString.parse(location.search)


    const [formLogin, handleInputLogin, handleFormLogin, handleResetFormLogin] = useForm({
        username: '',
        password: '',
    })

    const handleClickLogIn = () => {

        if (handleValidationLogin(formLogin)) {

            fetchLoginPOST({
                params: {
                    ...formLogin
                }
            })
        }
    }
    const [cookies, setCookie, removeCookie] = useCookies(['CookieTokenEBCN']);

    useEffect(() => {

        if (fetchLoginPOSTData.status === 200) {

            setCookie('CookieTokenEBCN', fetchLoginPOSTData.data.token, {
                path: '/',
                domain: process.env.REACT_APP_ENVIRONMENT === 'local' ? '' : process.env.REACT_APP_COOKIE_DOMAIN,
                maxAge: 14400
            })

            const dataToken: any = jwt_decode(fetchLoginPOSTData.data.token)

            if (params.url_redirect) {

                localStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE || '', JSON.stringify({
                    username: dataToken.sub,
                    name: dataToken.name,
                    rol: dataToken.rol,
                    exp: dataToken.exp,
                    token: fetchLoginPOSTData.data.token
                }))

                window.location.href = String(params.url_redirect)

            } else {

                dispatch(logIn({
                    username: dataToken.sub,
                    name: dataToken.name,
                    rol: dataToken.rol,
                    exp: dataToken.exp,
                    token: fetchLoginPOSTData.data.token
                }))
            }
        }

    }, [fetchLoginPOSTData])

    const setToTrue = () => {

        const currentTimeAsMs = Date.now();
        const adjustedTimeAsMs = currentTimeAsMs - (1000 * 60 * 60 * 4);

        removeCookie('CookieTokenEBCN')
    }

    const deleteCookieEBCN = () => {

        removeCookie('CookieTokenEBCN')
    }

    const setCookieEBCN = () => {

        setCookie('CookieTokenEBCN', 'fetchLoginPOSTData.data.token', {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN || '',
            maxAge: 14400
        })

    }

    const navigate = useNavigate()

    // Validar numero de colegiado y precolegiado

    // const [validRecordar, handleValidationRecordar] = useValidator({
    //     username: [
    //         { functionValid: validExiste, msgError: Lang('VALID_EXISTE_NUMERO_COLEGIADO'), isOk: true },
    //     ]
    // })

    const [fetchResetEmailGET, fetchResetEmailGETData] = ResetEmailServices.GET()


    const [existUser, setexistUser] = useState(true)

    const handleRecordarClave = () => {

        if (formLogin.username) {
            setexistUser(true)

            handleOpen()

            const pathTemp = `/restablecer?username=${formLogin.username}&token=`

            fetchResetEmailGET({
                params: {
                    username: formLogin.username,
                    url: process.env.PUBLIC_URL ? process.env.PUBLIC_URL + pathTemp : 'http://localhost:3000' + pathTemp
                }
            })

        } else {

            setexistUser(false)
        }
    }

    // Modal informativa

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    return (
        <Box className='Login'>
            <Grid container className='Login__Container'>
                <Grid item className='Login__left z-depth-3 d-flex flex-column'>

                    <div className='Login__Header d-flex justify-content-center my-4 mt-5'>
                        <img src={getImage('./logo-EMC-negatiu.png')} alt="" />
                    </div>

                    <div className="Login__Body px-3 px-sm-3 px-lg-4">
                        <div>
                            <Typography variant='h6' className="my-0 py-1 text-center text-white fw-500">
                                {Lang('MI_COLEGIO')}
                            </Typography>
                            <Typography className="my-0 py-1 text-center text-white opacity-8">
                                <small>{Lang('PARRAFO_LOGIN')}</small>
                            </Typography>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: '#ffffffa6' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='Login__Tabs'
                                        centered
                                        TabIndicatorProps={{
                                            sx: {
                                                backgroundColor: 'white',
                                            },
                                        }}
                                    >
                                        <Tab label={Lang('COLEGIADO')} {...a11yProps(0)} />
                                        <Tab label={Lang('PRECOLEGIADO')} {...a11yProps(1)} />
                                        <Tab label={Lang('EXTERNO')} {...a11yProps(2)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>

                                    <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <InputText
                                            label={Lang('NUMERO_COLEGIADO')}
                                            name='username'
                                            value={formLogin.username}
                                            onChange={handleInputLogin}
                                            localValidation={validLogin.username}
                                            className='my-2 w-100'
                                        />

                                        <InputText
                                            label={Lang('CLAVE')}
                                            type='password'
                                            name='password'
                                            value={formLogin.password}
                                            onChange={handleInputLogin}
                                            localValidation={validLogin.password}
                                            className='mt-2 mb-3 w-100'
                                        />

                                        <Button variant="outlined" className='btn_login_form my-3 border-0'
                                            onClick={handleClickLogIn}
                                        >
                                            {Lang('IDENTIFICATE')}
                                        </Button>

                                        <small>
                                            <Link onClick={handleRecordarClave}
                                                color="secondary" underline="hover" className='d-flex opacity-8 mx-2 pointer color-secondary text-center'>
                                                {Lang('PARRAFO_RECUPERAR_COLEGIADO')}
                                            </Link>
                                        </small>
                                    </Box>
                                    <small className='d-block'>
                                        {
                                            !existUser && <Alert severity="error" className='mt-3'>Per recordar la seva clau, primer ingresse el seu número</Alert>
                                        }
                                    </small>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <InputText
                                            label={Lang('NUMERO_PRECOLEGIADO')}
                                            name='username'
                                            value={formLogin.username}
                                            onChange={handleInputLogin}
                                            localValidation={validLogin.username}
                                            className='my-2 w-100'
                                        />

                                        <InputText
                                            label={Lang('CLAVE')}
                                            type='password'
                                            name='password'
                                            value={formLogin.password}
                                            onChange={handleInputLogin}
                                            localValidation={validLogin.password}
                                            className='mt-2 mb-3 w-100'
                                        />

                                        <Button variant="outlined" className='btn_login_form my-3 border-0'
                                            onClick={handleClickLogIn}
                                        >
                                            {Lang('IDENTIFICATE')}
                                        </Button>

                                        <small>
                                            <Link onClick={handleRecordarClave}
                                                color="secondary" underline="hover" className='d-flex opacity-8 mx-2 pointer color-secondary text-center'>
                                                {Lang('PARRAFO_RECUPERAR_PRECOLEGIADO')}
                                            </Link>
                                        </small>
                                    </Box>
                                    <small className='d-block'>
                                        {
                                            !existUser && <Alert severity="error" className='mt-3'>Per recordar la seva clau, primer ingresse el seu número</Alert>
                                        }
                                    </small>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <InputText
                                            label={Lang('NIF')}
                                            name='username'
                                            value={formLogin.username}
                                            onChange={handleInputLogin}
                                            localValidation={validLogin.username}
                                            className='my-2 w-100'
                                        />

                                        <InputText
                                            label={Lang('CLAVE')}
                                            type='password'
                                            name='password'
                                            value={formLogin.password}
                                            onChange={handleInputLogin}
                                            localValidation={validLogin.password}
                                            className='mt-2 mb-3 w-100'
                                        />

                                        <Button variant="outlined" className='btn_login_form my-3 border-0'
                                            onClick={handleClickLogIn}
                                        >
                                            {Lang('IDENTIFICATE')}
                                        </Button>

                                        <small>
                                            <Link onClick={handleRecordarClave}
                                                color="secondary" underline="hover" className='d-flex opacity-8 mx-2 pointer color-secondary text-center'>
                                                {Lang('PARRAFO_RECUPERAR_EXTERNO')}
                                            </Link>
                                        </small>
                                    </Box>
                                    <small className='d-block'>
                                        {
                                            !existUser && <Alert severity="error" className='mt-3'>Per recordar la seva clau, primer ingresse el seu número</Alert>
                                        }
                                    </small>
                                </TabPanel>
                            </Box>
                        </div>
                    </div>
                    <div className="Login__Footer d-flex flex-column align-items-center my-4">
                        <img src={getImage('./logo-white.png')} alt="" />
                        <div className='d-flex justify-content-center d-none'>
                            <Typography className={`py-1 ${language === 'CA' ? 'color-secondary' : 'text-white opacity-8'}`}>
                                <small>
                                    <Link onClick={() => dispatch(changeLanguage('CA'))} color="inherit" underline="hover" className='mx-2 pointer'>
                                        {Lang('CATALAN')}
                                    </Link>
                                </small>
                            </Typography>
                            <Typography className={`py-1 ${language === 'ES' ? 'color-secondary' : 'text-white opacity-8'}`}>
                                <small>
                                    <Link onClick={() => dispatch(changeLanguage('ES'))} color="inherit" underline="hover" className='mx-2 pointer'>
                                        {Lang('ESPANIOL')}
                                    </Link>
                                </small>
                            </Typography>
                        </div>
                    </div>

                </Grid>
                <Grid item xs className='Login__right d-flex align-items-end text-center'>
                    <footer className='py-2'>
                        <Typography className="my-0 py-1 px-3">
                            © Col·legi d’Enginyers Graduats i Enginyers Tècnics Industrials de Barcelona - Tots els drets reservats
                        </Typography>
                        <div className='d-flex justify-content-center flex-column flex-sm-row'>
                            <Typography className="py-1">
                                <Link href="https://www.enginyersbcn.cat/" color="inherit" underline="hover" className='mx-2'>
                                    ENGINYERS BCN
                                </Link>
                            </Typography>
                            <Typography className="py-1">
                                <Link href="https://www.enginyersbcn.cat/avis-legal.html" color="inherit" underline="hover" className='mx-2'>
                                    Avís Legal
                                </Link>
                            </Typography>
                            <Typography className="py-1">
                                <Link href="https://www.enginyersbcn.cat/accessibilitat.html" color="inherit" underline="hover" className='mx-2'>
                                    Accessibilitat
                                </Link>
                            </Typography>
                            <Typography className="py-1">
                                <Link href="https://www.enginyersbcn.cat/informacio-cookies.html" color="inherit" underline="hover" className='mx-2'>
                                    Cookies
                                </Link>
                            </Typography>
                        </div>
                    </footer>

                </Grid>
            </Grid>

            <ModalStatusServer
                fetchData={fetchLoginPOSTData}
                activeLoading
                onlyError
                messageStatus={
                    {
                        500:
                            <>
                                <Typography sx={{ mt: 2 }}>
                                    Usuari o clau incorrectes.
                                </Typography>
                            </>
                    }
                }
            />

            <ModalStatusServer
                fetchData={fetchResetEmailGETData}
                activeLoading
                messageStatus={
                    {
                        200:
                            <>
                                <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                <Typography sx={{ mt: 2 }}>
                                    S’ha enviat un missatge al teu correu electrònic, revisa la teva bústia d’entrada o bé la bústia de correu brossa.
                                </Typography>
                            </>,
                        404:
                            <>
                                <Typography sx={{ mt: 2 }}>
                                    L'usuari no existeix.
                                </Typography>
                            </>
                    }
                }
            />

        </Box>
    )
}


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

import { BreadCrumbCustom } from '../../components/BreadCrumbCustom/BreadCrumbCustom';
import { Box, Button, Grid, List, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLang } from '../../language';
import { Navigate, Route, Routes } from 'react-router';
import { SubList } from '../../components/SubList/SubList';
import { SelloIngenieroGraduadoIndustrial } from './SelloIngenieroGraduadoIndustrial';
import { SelloIngenieroTecnicoIndustrial } from './SelloIngenieroTecnicoIndustrial';
import { SelloIngenieroTecnicoDisenioIndustrial } from './SelloIngenieroTecnicoDisenioIndustrial';
import { SelloSociedadesProfesionales } from './SelloSociedadesProfesionales';
import { DatosServices } from '../ActualizacionDatos/controllers/services/datos.services';

export const Sellos = ({ onlyPath }: any) => {

    const Lang = useLang()

    const [BreadcrumbLineItems, setBreadcrumbLineItems] = useState<any>([
        {
            title: Lang('SELLOS'),
            link: onlyPath
        }
    ])

    const listRoutes = [
        {
            title: Lang('SELLO_INGENIERO_GRADUADO_INDUSTRIAL'),
            link: '/segell-enginyer-graduat-industrial'
        },
        {
            title: Lang('SELLO_INGENIERO_TECNICO_INDUSTRIAL'),
            link: '/segell-enginyer-tecnic-industrial'
        },
        {
            title: Lang('SELLO_INGENIERO_TECNICO_DISENIO_INDUSTRIAL'),
            link: '/segell-enginyer-tecnic-disseny-industrial'
        },
        {
            title: Lang('SELLO_SOCIEDADES_PROFESIONALES'),
            link: '/segell-societats-professionals'
        },
    ]

    
    const [fetchDatosGET, fetchDatosGETData] = DatosServices.GET()

    useEffect(() => {

        fetchDatosGET({
            params: {
                'tipo-direccion': 'CONTACT'
            }
        })

    }, [])


    return (
        <div>
            <BreadCrumbCustom items={BreadcrumbLineItems} />

            <section className='LayoutPrimary__contentmain'>

                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className='Paper__SubList'>

                                <SubList
                                    listRoutes={listRoutes}
                                    parentPath={onlyPath}
                                />

                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>

                            <Routes>

                                <Route
                                    path={listRoutes[0].link}
                                    element={<SelloIngenieroGraduadoIndustrial formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[0]} />}
                                />

                                <Route
                                    path={listRoutes[1].link}
                                    element={<SelloIngenieroTecnicoIndustrial formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[1]} />}
                                />

                                <Route
                                    path={listRoutes[2].link}
                                    element={<SelloIngenieroTecnicoDisenioIndustrial formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[2]} />}
                                />

                                <Route
                                    path={listRoutes[3].link}
                                    element={<SelloSociedadesProfesionales formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[3]} />}
                                />

                                <Route path="*" element={<Navigate to={`${onlyPath}${listRoutes[0].link}`} replace />} />

                            </Routes>

                        </Grid>
                    </Grid>
                </Box>
            </section>
        </div>
    )
}

import { styled } from '@mui/material/styles';

import {
    Alert,
    Box, Button, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCoffee, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { BreadCrumbCustom } from '../../../components/BreadCrumbCustom/BreadCrumbCustom';
import { useLang } from '../../../language';
import { InputTextarea } from '../../../components/Inputs/InputTextarea';
import { InputText } from '../../../components/Inputs/InputText';
import { InputSelect } from '../../../components/Inputs/InputSelect';
import { InputRadio } from '../../../components/Inputs/InputRadio';
import { useForm } from '../../../hooks/useForm';
import { DatosAdapters } from '../../ActualizacionDatos/controllers/adapters/datos.adapters';
import { useValidator } from '../../../hooks/useValidator';
import { ComunicacionServices } from '../../../controllers/services/comunicaciones.services';
import { validCorrectMovil, validExiste } from '../../../helpers';
import InputImage from '../../../components/Inputs/InputImage';
import { ModalCropImage } from '../../../components/ModalCropImage/ModalCropImage';
import { ModalStatusServer } from '../../../components/ModalStatus/ModalStatusServer';
import { ModalInfo } from '../../../components/ModalInfo/ModalInfo';

export const SolicitudCarnetColegiado = ({ setBreadcrumbLineItems, route, formContact }: any) => {

    const Lang = useLang()

    // Config de carga de imagenes

    const label__MaxSizeMB = '1 MB'
    const MaxSizeMB = 2000000


    useEffect(() => {

        setBreadcrumbLineItems((item: any) => [item[0], route])

    }, [])


    const [formSolicitud, handleInputSolicitud, handleFormSolicitud, handleResetFormSolicitud] = useForm({
        ...DatosAdapters.GET.receive.schema(),
        emailDesignado: ''
    })

    useEffect(() => {
        if (formContact.status === 200) {

            handleFormSolicitud({
                ...formContact.data?.items?.[0]
            })
        }
    }, [formContact])


    // ---------------- Validaciones

    const [validComunicacion, handleValidationComunicacion] = useValidator({
        datosContacto__movil: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
            { functionValid: validCorrectMovil, msgError: Lang('VALID_CORRECT_MOVIL'), isOk: true },
        ],
    })

    const [isOkImage, setisOkImage] = useState(true)

    const handleValidationImage = () => {

        if (InputFile?.length === 0) {
            setisOkImage(false)
        } else {
            setisOkImage(true)
        }

        return InputFile?.length > 0
    }

    // Envio de solicitud por EMAIL

    const [fetchComunicacionesPOST, fetchComunicacionesPOSTData] = ComunicacionServices.POST()

    const handleSubmitComunicacionPOST = () => {

        if (handleValidationComunicacion(formSolicitud) && handleValidationImage()) {

            const date = new Date();

            // const todayDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
            // const todayHour = `${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`

            const bodyTemp = {
                // adjunto: new File([""], "filename"),
                comunicacion: {
                    asunto: `[web] Formulari de la web: Sol·licitud de carnet de col·legiat`,
                    cuerpo: `
                    <h3 style="margin-bottom: 15px">Sol·licitud de carnet de col·legiat</h3>

                    <table lang="CA" border="1" width="100%" cellspacing="4" cellpadding="0" style="margin-bottom: 15px">
                        <tbody lang="CA">
                            <tr>
                                <td colspan="2" style="padding: 7px">
                                    <p style="text-align: center; margin: 0">
                                        <strong>Dades personals</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Nom:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.nombre} ${formSolicitud.apellidos}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Numero colegiat:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.persCodiCole}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>Telefon:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.datosContacto__movil}
                                    </p>
                                </td>
                            </tr>
                            
                            <tr>
                                <td style="padding: 7px">
                                    <p style="text-align: right; margin: 0">
                                        <strong>DNI:</strong>
                                    </p>
                                </td>
                                <td style="padding: 7px">
                                    <p style="margin: 0">
                                        ${formSolicitud.persCifNif}
                                    </p>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    `,
                    remitente: `${formSolicitud.nombre} ${formSolicitud.apellidos}`,
                    cuerpoHTML: true,
                    emailRemitente: `${formSolicitud.datosContacto__email}`,
                    destinatarios: [
                        {
                            email: process.env.REACT_APP_EMAIL_SOLICITUD_CARNET_COLEGIADO,
                            destinatario: process.env.REACT_APP_BUSINESS_NAME,
                            variables: {}
                        },
                    ]
                },
                adjunto: InputFile?.[0],
            }

            fetchComunicacionesPOST({
                body: bodyTemp
            })
        }
    }


    // Modal Rercortar imagen

    const [InputFile, setInputFile] = useState<any>([])

    const [showModalCropImage, setShowModalCropImage] = useState({
        value: false,
        payload: null
    });

    const handleCloseModalCropImage = () => setShowModalCropImage({
        value: false,
        payload: null
    })

    const handleShowModalCropImage = (payload: any) => setShowModalCropImage({
        value: true,
        payload
    });

    const handleChangeInputImage = (e: any) => {

        if (e?.target?.files?.length > 0) {

            if (e.target.files[0]?.size > MaxSizeMB) {

                handleShowModal_InfoError({})
                e.target.value = null

            } else {

                handleShowModalCropImage(e.target.files[0])
            }
        }
    }

    // const [fetchPersonaDatGenPOST_SUBIR_IMAGEN,
    //     fetchPersonaDatGenPOST_SUBIR_IMAGENData,
    //     resetfetchPersonaDatGenPOST_SUBIR_IMAGEN] = PersonaDatosGeneralesServices.POST_SUBIR_IMAGEN(id)


    const handleSubirImagen = (tipo: any, file: any) => {

        // fetchPersonaDatGenPOST_SUBIR_IMAGEN({
        //     body: {
        //         tipo,
        //         imagen: file
        //     }
        // })
    }

    const handleEliminarImagen = (tipo: any) => {

        // fetchPersonaDatGenDELETE_IMAGEN({
        //     body: {
        //         tipo
        //     }
        // })
    }


    // ---------- Modal Info Error

    const [dataModal_InfoError, setDataModal_InfoError] = useState({
        value: false,
        payload: null
    });

    const handleCloseModal_InfoError = () => setDataModal_InfoError({
        value: false,
        payload: null
    })

    const handleShowModal_InfoError = (payload: any) => setDataModal_InfoError({
        value: true,
        payload
    });

    const handleReset2 = () => {

        handleFormSolicitud({
            ...formSolicitud,
            datosContacto__movil: '',
        })
    }

    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" >

                <Grid item xs={12} className='mb-4 pb-2'>
                    <Paper className='p-3 p-md-4'>

                        <Typography variant='h6' className="my-0 pb-1 fw-500 color-primary text-center">
                            {Lang('SOLICITUD_CARNET_COLEGIADO')}
                        </Typography>

                        <Typography variant='body1' className="mt-2 mb-2">
                            Rebreu el carnet per correu postal a l'adreça de contacte que ens consta en la nostra base de dades.
                        </Typography>

                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="mt-2 fw-500 color-primary">
                                    Dades personals
                                </Typography>
                            </Grid>

                            <Grid item lg={8}>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <InputText
                                            label={Lang('NOMS')}
                                            name='nombre'
                                            value={formSolicitud.nombre}
                                            onChange={handleInputSolicitud}
                                            readOnly
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} lg={8}>
                                        <InputText
                                            label={Lang('COGNOMS')}
                                            name='apellidos'
                                            value={formSolicitud.apellidos}
                                            onChange={handleInputSolicitud}
                                            readOnly
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} lg={4}>
                                        <InputText
                                            label={Lang('NUMERO_COLEGIADO')}
                                            name='persCodiCole'
                                            value={formSolicitud.persCodiCole}
                                            onChange={handleInputSolicitud}
                                            readOnly
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} lg={4}>
                                        <InputText
                                            label='NIF'
                                            name='persCifNif'
                                            value={formSolicitud.persCifNif}
                                            onChange={handleInputSolicitud}
                                            readOnly
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={6}>
                                        <InputText
                                            label='Telèfon de contacte'
                                            name='datosContacto__movil'
                                            value={formSolicitud.datosContacto__movil}
                                            onChange={handleInputSolicitud}
                                            localValidation={validComunicacion.datosContacto__movil}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid item xs={12} lg={4}>

                                <Grid item xs={12}>

                                    <InputImage
                                        label='Fotografia del col·legiat:'
                                        width='100%'
                                        height='13rem'
                                        onChange={handleChangeInputImage}
                                        InputFile={InputFile}
                                        setInputFile={setInputFile}
                                        urlImage={formSolicitud.url_fotografia}
                                        handleEliminarImagen={() => handleEliminarImagen('fotografia')}
                                    />
                                    <small className='text-center mt-2 d-block'>Màx. {label__MaxSizeMB}.</small>

                                    {
                                        !isOkImage && <span className='d-block mt-1' style={{ 'color': '#d32f2f' }}>{Lang('VALID_EXISTE_CAMPO_DEFAULT')}</span>
                                    }

                                </Grid>

                            </Grid>

                        </Grid>

                        <div className="d-flex justify-content-between mt-4">
                            <Button color="primary" variant="contained" onClick={handleSubmitComunicacionPOST}>{Lang('CONFIRMAR')}</Button>
                            <Button color="primary" variant="outlined" onClick={handleReset2}>{Lang('LIMPIAR')}</Button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <ModalCropImage
                show={showModalCropImage}
                handleClose={handleCloseModalCropImage}
                height='500px'
                setInputFile={setInputFile}
                handleSubirImagen={handleSubirImagen}
                tipo='fotografia'
                escalas={[
                    { x: 10, y: 10, label: 'Cuadrado' }
                ]}
            />

            <ModalStatusServer
                fetchData={fetchComunicacionesPOSTData}
                activeLoading
                disableStatus
                messageStatus={
                    {
                        201:
                            <>
                                <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                <Typography sx={{ mt: 2 }}>
                                    S'ha enviat correctament la sol·licitud
                                </Typography>
                            </>
                    }
                }
            />

            <ModalInfo
                data={dataModal_InfoError}
                handleClose={handleCloseModal_InfoError}
                message={`S'ha excedit el pes permès`}
            />
        </Box>
    )
}

import { styled } from '@mui/material/styles';

import {
    Alert,
    Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup,
    FormLabel, Grid, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCoffee, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { BreadCrumbCustom } from '../../../components/BreadCrumbCustom/BreadCrumbCustom';
import { useLang } from '../../../language';
import { InputTextarea } from '../../../components/Inputs/InputTextarea';
import { InputText } from '../../../components/Inputs/InputText';
import { InputSelect } from '../../../components/Inputs/InputSelect';
import { InputRadio } from '../../../components/Inputs/InputRadio';
import { InputChecks } from '../../../components/Inputs/InputChecks';
import { DatosAdapters } from '../../ActualizacionDatos/controllers/adapters/datos.adapters';
import { DatosServices } from '../../ActualizacionDatos/controllers/services/datos.services';
import { useForm } from '../../../hooks/useForm';
import { PaisesServices, PoblacionesServices } from '../../../controllers/services/ubicaciones.services';
import { cloneDeep } from 'lodash';
import { receiveCheck, sendCheck } from '../../../controllers/adapters/helpers';
import { ModalStatusServer } from '../../../components/ModalStatus/ModalStatusServer';
import { useValidator } from '../../../hooks/useValidator';
import { validExiste } from '../../../helpers';

export const DatosContactoRelacionColegio = ({ setBreadcrumbLineItems, route, formContact }: any) => {

    const Lang = useLang()

    useEffect(() => {

        setBreadcrumbLineItems((item: any) => [item[0], route])

    }, [])

    const [isEmptyDataCEE, setIsEmptyDataCEE] = useState(false)

    // Formulario principal

    const [formDatosContacto, handleInputDatosContacto, handleFormDatosContacto, handleResetFormDatosContacto] = useForm({
        ...DatosAdapters.GET.receive.schema()
    })

    const [formDatosCEE, handleInputDatosCEE, handleFormDatosCEE, handleResetFormDatosCEE] = useForm({
        ...DatosAdapters.GET.receive.schema(),
        lopd: []
    })

    const [validCEE, handleValidationCEE] = useValidator({
        direccion__direccion: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        direccion__codigoPostal: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        direccion__poblacion: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        direccion__provincia: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        datosContacto__movil: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        datosContacto__email: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ]
    })

    const [fetchDatosCEEGET, fetchDatosCEEGETData] = DatosServices.GET()

    useEffect(() => {

        fetchDatosCEEGET({
            params: {
                'tipo-direccion': 'CEE'
            }
        })

        fetchPaisesGET()

    }, [])


    useEffect(() => {
        if (formContact.status === 200) {
            
            handleFormDatosContacto({
                ...formDatosContacto,
                ...formContact.data?.items?.[0]
            })
        }
    }, [formContact])


    useEffect(() => {
        if (fetchDatosCEEGETData.status === 200) {
            
            handleFormDatosCEE({
                ...formDatosCEE,
                ...fetchDatosCEEGETData.data?.items?.[0],
                lopd: receiveCheck(fetchDatosCEEGETData.data?.items?.[0]?.lopd, 'lopd'),
                direccion__idpais: 3
            })

            setIsEmptyDataCEE(fetchDatosCEEGETData.data?.items?.length === 0)
            // setIsEmptyDataCEE(true)
        }
    }, [fetchDatosCEEGETData])

    // ------------------------ Ubicaciones

    const [activeEspana, setactiveEspana] = useState(false)

    const [fetchPoblacionesGET, fetchPoblacionesGETData] = PoblacionesServices.GET()

    const [fetchPaisesGET, fetchPaisesGETData] = PaisesServices.GET()

    useEffect(() => {

        if (formDatosCEE.direccion__idpais === 3) {

            if (formDatosCEE.direccion__codigoPostal?.length === 5) {
                setactiveEspana(true)

                fetchPoblacionesGET({
                    params: {
                        page: 0,
                        size: 200,
                        cpostal: formDatosCEE.direccion__codigoPostal,
                    }
                })

            } else {

                handleFormDatosCEE({
                    ...formDatosCEE,
                    direccion__poblacion: '',
                    direccion__provincia: '',
                    direccion__comarca: '',
                })
            }

        } else {
            setactiveEspana(false)

            handleFormDatosCEE({
                ...formDatosCEE,
                direccion__poblacion: '',
                direccion__provincia: '',
                direccion__comarca: '',
            })
        }

    }, [formDatosCEE.direccion__codigoPostal, formDatosCEE.direccion__idpais])

    useEffect(() => {

        if (formDatosCEE.direccion__poblacion && fetchPoblacionesGETData.data && activeEspana) {

            const poblacionesTemp = [...fetchPoblacionesGETData.data?.elementos]

            const resp = poblacionesTemp.find(item => item.poblacion === formDatosCEE.direccion__poblacion)

            handleFormDatosCEE({
                ...formDatosCEE,
                direccion__poblacion: resp?.poblacion,
                direccion__provincia: resp?.provincia,
                direccion__comarca: resp?.comarca,
            })
        }

    }, [formDatosCEE.direccion__poblacion, fetchPoblacionesGETData.data])

    useEffect(() => {
        
        if (fetchPoblacionesGETData.status === 200) {

            const res = fetchPoblacionesGETData.data?.elementos?.[0]

            handleFormDatosCEE({
                ...formDatosCEE,
                direccion__poblacion: res?.poblacion,
                direccion__provincia: res?.provincia,
                direccion__comarca: res?.comarca,
            })
        }

        if (String(fetchPoblacionesGETData.status)?.[0] === '4') {
            handleFormDatosCEE({
                ...formDatosCEE,
                direccion__poblacion: '',
                direccion__provincia: '',
                direccion__comarca: '',
            })
        }

    }, [fetchPoblacionesGETData])

    // Funciones helpers

    const handleChangeCheck = (e: any, name: string) => {

        let listaModulosTemp = cloneDeep(formDatosCEE[name])

        if (e.target.checked) {

            listaModulosTemp.push(e.target.name)

        } else {

            listaModulosTemp = listaModulosTemp.filter((item: any) => item !== e.target.name)
        }

        handleFormDatosCEE({
            ...formDatosCEE,
            [name]: listaModulosTemp
        })
    }

    // Actualizacion de datos de OMBINUS

    const [fetchDatosPUT, fetchDatosPUTData] = DatosServices.PUT()

    const handleSubmitDatosPUT = () => {

        if (handleValidationCEE(formDatosCEE)) {

            fetchDatosPUT({
                body: {
                    ...formDatosCEE,
                    tipoDireccion: 'CEE',
                    lopd: sendCheck(formDatosCEE.lopd),
                    direccion__pais: fetchPaisesGETData.data?.find((item: any) => item.id === formDatosCEE.direccion__idpais)?.nombre || ''
                }
            })
        }
    }

    useEffect(() => {

        if (String(fetchDatosPUTData.status)?.[0] === '2') {

            fetchDatosCEEGET({
                params: {
                    'tipo-direccion': 'CEE'
                }
            })
        }

    }, [fetchDatosPUTData])

    // Actualizacion de datos de OMBINUS

    const [fetchDatosPOST, fetchDatosPOSTData] = DatosServices.POST()

    const handleSubmitDatosPOST = () => {

        if (handleValidationCEE(formDatosCEE)) {

            fetchDatosPOST({
                body: {
                    ...formDatosCEE,
                    tipoDireccion: 'CEE',
                    lopd: sendCheck(formDatosCEE.lopd),
                    persCodiCole: formDatosContacto.persCodiCole,
                    direccion__pais: fetchPaisesGETData.data?.find((item: any) => item.id === formDatosCEE.direccion__idpais)?.nombre || ''
                }
            })
        }
    }

    // Actualizar fetchData para saber si ya existen datos y no hacer POST, sino PUT

    useEffect(() => {

        if (String(fetchDatosPOSTData.status)?.[0] === '2') {

            fetchDatosCEEGET({
                params: {
                    'tipo-direccion': 'CEE'
                }
            })
        }

    }, [fetchDatosPOSTData])


    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" >

                <Grid item xs={12} className='mb-4 pb-2'>
                    <Paper className='p-3 p-md-4'>

                        <Alert severity="info" className='mb-3'>
                            <Typography variant='body2' className="mb-2">
                                Per tal de formar part del llistat de tècnics certificadors cal que facilitis les dades consignades a continuació així com que enviïs la Declaració Responsable conforme en disposes d’una pòlissa d’Assegurança de Responsabilitat Civil al correu electrònic següent: <a className='color-primary' href="mailto:ebcn@ebcn.cat">ebcn@ebcn.cat</a>.
                            </Typography>

                            <Typography variant='body2' className="mb-2">
                                Les dades corresponents a l’adreça, població, telèfon i correu electrònic són obligatòries i caldrà que estiguin correctament emplenades per tal de formalitzar el procés de registre. Les dades corresponents a la formació addicional (cursos relacionats amb els procediments de certificació dels edificis), el nom de l’empresa i el web són opcionals i no és imprescindible que estiguin emplenades per realitzar el registre.
                            </Typography>

                            <Typography variant='body2' className="mb-2">
                                Et recordem que el registre no es farà efectiu mentre el Col·legi no rebi la <a href="https://documentacio.enginyersbcn.cat/coneixement/docs/docs/serveis-tecnics/declaracio-responsable-registre-de-tecnics-de-licaen" target="_blank" rel="noreferrer" className='color-primary'>Declaració Responsable</a> relacionada amb l'Assegurança de Responsabilitat Civil.
                            </Typography>
                        </Alert>

                        <Typography variant='h6' className="my-0 pb-1 fw-500 color-primary text-center">
                            Procés d’inscripció registre de tècnics certificadors
                        </Typography>

                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="mt-2 fw-500 color-primary">
                                    Les teves dades personals
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} lg={3}>
                                <InputText
                                    label={Lang('NUMERO_COLEGIADO')}
                                    value={formDatosContacto.persCodiCole}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={5}>
                                <InputText
                                    label={Lang('COGNOMS')}
                                    value={formDatosContacto.apellidos}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <InputText
                                    label={Lang('NOMS')}
                                    value={formDatosContacto.nombre}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <InputText
                                    label='DNI/NIF'
                                    value={formDatosContacto.persCifNif}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='body1' className="fw-500 color-primary">
                                    Les teves dades de contacte
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <InputText
                                    label={Lang('DOMICILIO')}
                                    name='direccion__direccion'
                                    value={formDatosCEE.direccion__direccion}
                                    onChange={handleInputDatosCEE}
                                    readOnly={!isEmptyDataCEE}
                                    localValidation={validCEE.direccion__direccion}
                                />
                            </Grid>

                            {/* <Grid item xs={12} lg={6} xl={4}>
                                <InputSelect
                                    label={Lang('PAIS')}
                                    name='direccion__idpais'
                                    onChange={handleInputDatosCEE}
                                    value={formDatosCEE.direccion__idpais}
                                    values={fetchPaisesGETData.data?.map((item: any) => {
                                        return {
                                            id: item.id,
                                            name: item.nombre
                                        }
                                    }) || []}
                                    readOnly={!isEmptyDataCEE}
                                />
                            </Grid> */}

                            <Grid item xs={12} lg={6} xl={4}>
                                <InputText
                                    label={Lang('CODIGO_POSTAL') + ' *'}
                                    name='direccion__codigoPostal'
                                    value={formDatosCEE.direccion__codigoPostal}
                                    onChange={handleInputDatosCEE}
                                    type='number'
                                    localValidation={validCEE.direccion__codigoPostal}
                                    readOnly={!isEmptyDataCEE}
                                />
                            </Grid>

                            {/* <Grid item xs={12} lg={6} xl={4}>
                                <InputText
                                    label={Lang('POBLACION') + ' *'}
                                    name='direccion__poblacion'
                                    value={formDatosCEE.direccion__poblacion}
                                    onChange={handleInputDatosCEE}
                                    disabled
                                    localValidation={validCEE.direccion__poblacion}
                                    readOnly={!isEmptyDataCEE}
                                />
                            </Grid> */}

                            {
                                activeEspana ?
                                    <Grid item xs={12} lg={6} xl={4}>
                                        <InputSelect
                                            label={Lang('POBLACION') + ' *'}
                                            name='direccion__poblacion'
                                            onChange={handleInputDatosCEE}
                                            value={formDatosCEE.direccion__poblacion}
                                            values={fetchPoblacionesGETData.data?.elementos?.map((item: any) => {
                                                return {
                                                    id: item.poblacion,
                                                    name: item.poblacion
                                                }
                                            }) || []}
                                            readOnly={!isEmptyDataCEE}
                                            localValidation={validCEE.direccion__poblacion}
                                        />
                                    </Grid>
                                    :
                                    <Grid item xs={12} lg={6} xl={4}>
                                        <InputText
                                            label={Lang('POBLACION')}
                                            name='direccion__poblacion'
                                            value={formDatosCEE.direccion__poblacion}
                                            onChange={handleInputDatosCEE}
                                            disabled
                                            readOnly={!isEmptyDataCEE}
                                            localValidation={validCEE.direccion__poblacion}
                                        />
                                    </Grid>
                            }

                            <Grid item xs={12} lg={6} xl={4}>
                                <InputText
                                    label={Lang('PROVINCIA') + ' *'}
                                    name='direccion__provincia'
                                    value={formDatosCEE.direccion__provincia}
                                    onChange={handleInputDatosCEE}
                                    localValidation={validCEE.direccion__provincia}
                                    disabled
                                    readOnly={!isEmptyDataCEE}
                                />

                            </Grid>

                            {/* <Grid item xs={12} className=''>
                                <Typography variant='body1' className="mb-0">
                                    Si no trobes la població o codi postal, selecciona l’opció <strong>Altres poblacions</strong> i entra les dades directament.
                                </Typography>
                            </Grid> */}

                            <Grid item xs={12} lg={4}>
                                <InputText
                                    label={Lang('TELEFONO_CONTACTO') + ' *'}
                                    name='datosContacto__movil'
                                    value={formDatosCEE.datosContacto__movil}
                                    onChange={handleInputDatosCEE}
                                    readOnly={!isEmptyDataCEE}
                                    localValidation={validCEE.datosContacto__movil}
                                />
                            </Grid>

                            {/* <Grid item xs={12}>
                                <InputText
                                    label={Lang('NOMBRE_DE_EMPRESA')}
                                    name='comentario'
                                    value={formDatosCEE.comentario}
                                    onChange={handleInputDatosCEE}
                                    readOnly={!isEmptyDataCEE}
                                />
                            </Grid> */}

                            <Grid item xs={12} lg={6}>
                                <InputText
                                    label={Lang('EMAIL') + ' *'}
                                    name='datosContacto__email'
                                    value={formDatosCEE.datosContacto__email}
                                    onChange={handleInputDatosCEE}
                                    readOnly={!isEmptyDataCEE}
                                    localValidation={validCEE.datosContacto__email}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputText
                                    label={Lang('WEB')}
                                    name='datosContacto__web'
                                    value={formDatosCEE.datosContacto__web}
                                    onChange={handleInputDatosCEE}
                                    readOnly={!isEmptyDataCEE}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputTextarea
                                    label={`Formació addicional relacionada amb eficiència energètica d'edificis`}
                                    placeholder={Lang('NOMBRE_DE_EMPRESA')}
                                    rows={4}
                                    name='comentario'
                                    value={formDatosCEE.comentario}
                                    onChange={handleInputDatosCEE}
                                    readOnly={!isEmptyDataCEE}
                                />
                            </Grid>

                            <Grid item xs={12} className=''>
                                <div className="border-top"></div>
                            </Grid>

                            <Grid item xs={12} className='pt-3'>
                                <Typography variant='body1' className="mt-0 mb-2">
                                    Aquestes dades seran tractades segons el que estableix la Llei Orgànica 15/1999, 13 de desembre, de protecció de dades de caràcter personal. Seran conservades en el fitxer automatitzat, inscrit en el Registre General de Protecció de Dades amb codi 20000240139 del qual és responsable ENGINYERS BCN i, en el seu nom, el secretari. Us recordem que podeu exercir, en qualsevol moment, els drets d’accés, rectificació, cancel·lació i oposició mitjançant comunicació adreçada a ENGINYERS BCN.
                                </Typography>
                                {
                                    isEmptyDataCEE &&
                                    <InputChecks
                                        values={[
                                            {
                                                id: 0,
                                                checked: formDatosCEE.lopd?.length > 0,
                                                label: `Autoritzo al Col·legi d'Enginyers Graduats i Enginyers Tècnics Industrials de Barcelona a cedir aquestes dades a l'ICAEN.`
                                            }
                                        ]}
                                        onChange={(e: any) => handleChangeCheck(e, 'lopd')}
                                    />
                                }
                            </Grid>

                        </Grid>
                        {
                            isEmptyDataCEE && <div className="d-flex justify-content-center mt-4">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={formDatosCEE.lopd?.length === 0}
                                    onClick={isEmptyDataCEE ? handleSubmitDatosPOST : handleSubmitDatosPUT}
                                >
                                    Inscriure's
                                </Button>
                            </div>
                        }

                    </Paper>
                </Grid>
            </Grid>

            <ModalStatusServer
                fetchData={fetchDatosPOSTData}
                activeLoading
                disableStatus
                messageStatus={
                    {
                        204:
                            <>
                                <FontAwesomeIcon icon={faCheckCircle} className='color-green fa-2x' />

                                <Typography sx={{ mt: 2 }}>
                                    S'han actualitzat correctament les dades
                                </Typography>
                            </>
                    }
                }
            />

            <ModalStatusServer
                fetchData={fetchDatosPUTData}
                activeLoading
                disableStatus
                messageStatus={
                    {
                        204:
                            <>
                                <FontAwesomeIcon icon={faExclamationCircle} className='color-green fa-2x' />

                                <Typography sx={{ mt: 2 }}>
                                    Les dades estan pendents de ser validades
                                </Typography>
                            </>
                    }
                }
            />

        </Box>
    )
}

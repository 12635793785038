import { sendMultipart, sendMultipartEmail } from "./helpers"
import { parseArray, parseBoolean, parseNumber, parseString, receiveBoolean, receiveNumber, receiveObject, receiveString } from "./receives"
import { sendString } from "./sends"

const ComunicacionAdapters = {

    POST: {

        send: {

            schema: function (item: any = {}) {

                // var data = {

                //     comunicacion: {
                //         id: parseString(item.comunicacion__id),
                //         idUsuario: parseString(item.direccion__idUsuario),
                //         nombreUsuario: parseString(item.nombreUsuario__direccion),
                //         rolUsuario: parseString(item.direccion__rolUsuario),
                //         tipoUsuario: parseString(item.direccion__tipoUsuario),
                //         cuenta: {
                //             id: parseString(item.comunicacion__cuenta__id),
                //             email: parseString(item.comunicacion__cuenta__email),
                //             host: parseString(item.comunicacion__cuenta__host),
                //             port: parseString(item.comunicacion__cuenta__port),
                //             username: parseString(item.comunicacion__cuenta__username),
                //             password: parseString(item.comunicacion__cuenta__password),
                //             smtpAuth: parseString(item.comunicacion__cuenta__smtpAuth),
                //             smtpConnectionTimeOut: parseString(item.comunicacion__cuenta__smtpConnectionTimeOut),
                //             smtpTimeOut: parseString(item.comunicacion__cuenta__smtpTimeOut),
                //             smtpWriteTimeOut: parseString(item.comunicacion__cuenta__smtpWriteTimeOut),
                //             deshabilitada: parseString(item.comunicacion__cuenta__deshabilitada),
                //         },
                //         plantilla: {
                //             id: parseString(item.comunicacion__plantilla__id),
                //             nombre: parseString(item.comunicacion__plantilla__nombre),
                //             asunto: parseString(item.comunicacion__plantilla__asunto),
                //             cuerpo: parseString(item.comunicacion__plantilla__cuerpo),
                //             cuerpoHTML: parseString(item.comunicacion__plantilla__cuerpoHTML),
                //             variables: parseArray(item.comunicacion__plantilla__variables),
                //         },
                //         asunto: parseString(item.comunicacion__asunto),
                //         cuerpo: parseString(item.comunicacion__cuerpo),
                //         cuerpoHTML: parseBoolean(item.comunicacion__cuerpoHTML),
                //         remitente: parseString(item.comunicacion__remitente),
                //         emailRemitente: parseString(item.comunicacion__emailRemitente),
                //         usadaCuentaPorDefecto: parseBoolean(item.comunicacion__usadaCuentaPorDefecto),
                //         ficheroAdjunto: parseString(item.comunicacion__ficheroAdjunto),
                //         nombreOriginalFicheroAdjunto: parseString(item.comunicacion__nombreOriginalFicheroAdjunto),
                //         destinatarios: []
                //     },
                //     adjunto: parseString(item.adjunto),
                // }

                return item
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return sendMultipartEmail(items)

                // return {
                //     ...item
                // }
            }
        },
    },
}

export {
    ComunicacionAdapters,
}
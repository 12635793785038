const GENERALS = {
    MI_COLEGIO: 'Mi Colegio',
    PARRAFO_LOGIN: 'Tu espacio de trabajo; Actualiza tus datos, consulta tus trámites y accede a todos los servicios del colegio.',
    CATALAN: 'Catalán',
    ESPANIOL: 'Español',
    PARRAFO_RECUPERAR_COLEGIADO: 'Si ha olvidado la clave, introduzca su Numero colegiado y pulse aquí',
    PARRAFO_RECUPERAR_PRECOLEGIADO: 'Si ha olvidado la clave, introduzca su Numero precolegiado y pulse aquí',
    PARRAFO_RECUPERAR_EXTERNO: 'Si ha olvidado la clave, introduzca su NIF y pulse aquí',
    IDENTIFICATE: 'Identifícate',
    ESPACIO_CORPORATIVO: 'Espacio Corporativo',
    ESPACIO_DE_TRABAJO: 'Espacio de Trabajo',
    XARXA_EBCN: 'XARXA e-BCN',
    VUELVE_A_VER: 'Vuelve a Ver',
    DATOS_PROFESIONALES: 'Datos profesionales',
    DATOS_ACCESO_CONSUMIDOR: `Datos de acceso al consumidor y usuario`,
    DATOS_GUIA_PROFESIONAL: 'Datos de la Guía de Profesionales',
    DATOS_CONTACTO_RELACION_COLEGIO: 'Datos de contacto y relación con el Colegio',

    REGISTRO_TECNICOS_CERTIFICADORES: 'Registro de Técnicos Certificadores',
    SOLICITUD_CERTIFICADO_COLEGIACION: 'Solicitud de Certificado de Colegiación',
    CORREO_ELECTRONICO_CORPORATIVO: 'Correo Electrónico Corporativo',
    DL_ABSTRACT: 'DL Abstract',
    SOLICITUDES: 'Solicitudes',
    SELLO_INGENIERO_GRADUADO_INDUSTRIAL: 'Sello del ingeniero graduado en el ámbito industrial',
    SELLO_INGENIERO_TECNICO_INDUSTRIAL: 'Sello del ingeniero técnico industrial',
    SELLO_INGENIERO_TECNICO_DISENIO_INDUSTRIAL: `Sello del ingeniero técnico en diseño industrial`,
    SELLO_SOCIEDADES_PROFESIONALES: `Sello de sociedades profesionales de ingeniería`,
    SOLICITUD_CARNET_COLEGIADO: `Solicitud de carné de colegiado`,
    SELLOS: 'Sellos',
    NOMBRE_DE_EMPRESA: `Nombre de la empresa`,
    SECTOR: 'Sector',
    AREA_DE_aCTUACION: 'Área de actuación',
    POSICION_O_CARGO: 'Posición o cargo',
    DOMICILIO: 'Domicilio',
    POBLACION: 'Población',
    TELEFONO_1: 'Teléfono 1',
    TELEFONO_2: 'Teléfono 2',
    TELEFONO_MOVIL: 'Teléfono Móvil',
    TELEFONO_CONTACTO: 'Teléfono de Contacto',
    FAX: 'FAX',
    EMAIL: 'Email',
    WEB: 'Web',
    ACTUALIZAR_DATOS: 'Actualizar datos',
    ELIMINAR_DATOS: 'Eliminar datos',
    BORRAR_DATOS: 'Borrar datos',
    MODIFICAR_CONTRASENIA: 'Modificar contraseña',
    CERRAR_SESION: 'Cerrar sesión',
    COLEGIADO: 'Colegiado',
    PRECOLEGIADO: 'Precolegiado',
    EXTERNO: 'Externo',
    NUMERO_COLEGIADO: 'Número colegiado',
    CODIGO: 'Código',
    CLAVE: 'Clave',
    NUMERO_PRECOLEGIADO: 'Número precolegiado',
    NIF: 'NIF',

    BUSCADOR_DOCUMENTACION_SEGUIMIENTO: 'Buscador de documentación y conocimiento',
    TECNOVISAT: 'Tecnovisat',
    GESTIONA_TUS_INSCRIPCIONES: 'Gestiona tus inscripciones',
    CAMPUS_VIRTUAL: 'Campus virtual',
    BOLSA_DE_TRABAJO: 'Bolsa de trabajo',
    ALTA_GUIA_PROFESIONALES: 'Alta Guía de profesionales',
    ALTA_REGISTRO_CERTIFICADORES_EFICIENCIA_ENERGETICA: 'Alta registro de certificadores de eficiencia energética',
    PERITOS_TERCEROS_ADMINISTRACION: 'Peritos y terceros para la administración',
    SLAM: 'Slam',
    TOOLBOX: 'Tool Box',
    ACTUALIZACION_DATOS: 'Actualización de datos',
    COGNOMS: 'Apellidos',
    NOMS: 'Nombres',
    Q_HABILITADO: '¿Habilitado?',
    TITULACIONES: 'Titulaciones',
    OTRAS_TITULACIONES: 'Otras titulaciones',
    FECHA_NACIMIENTO: 'Fecha nacimiento',
    ESTADO: 'Estado',
    OTRO_TELEFONO: 'Otro teléfono',

    EMPRESAS: 'Empresas',
    ENVIAR_SOLICITUD: 'Enviar solicitud',
    LIMPIAR: 'Limpiar',
    ENVIAR: 'Enviar',
    CONFIRMAR: 'Confirmar',
    DESCARGAR_SELLO: 'Descargar sello',
    SOLICITAR_SELLO: 'Solicitar sello',
    ACTUALIZA: 'Actualiza',
    RESTABLECER_CLAVE: 'Restablecer clave',
    USUARIO: 'Usuario',
    CONFIRMAR_CLAVE: 'Confirmar clave',
    PARRAFO_OPERACION_EXITO: 'Se ha realizado la operación con éxito',
    CODIGO_POSTAL: 'Código postal',
    PAIS: 'País',
    PROVINCIA: 'Provincia',
    RECORTAR_IMAGEN: 'Recortar imagen',
    MANTENER_ORIGINAL: 'Mantener original',
    CONTRASENIA: 'Contraseña',
    CONFIRMAR_CONTRASENIA: 'Confirmar contraseña',
    PRESENTACION: 'Presentación',
    CAMPO_ENLACE: 'Campo enlace',
    DAR_DE_BAJA: 'Dar de baja',
    NOMBRE_EMPRESA_FORMACION_ADICIONAL: 'Nombre de empresa y formación adicional',
    INICIAR_SESION: 'Iniciar Sesión',
    COMARCA: 'Comarca',
    INSCRIPCION_LISTA_GENERAL_PERITOS_JUDICIALES: 'Inscripción en el listado general de peritos judiciales',
    DOCUMENTOS_ACCESO: 'Documentos acceso',
    INSCRIPCION_LISTA_PERITOS_JUDICIALES_ASISTENCIA_RECONOCIDA: 'Inscripción en el listado de peritos judiciales en procedimientos con asistencia jurídica gratuita reconocida',
    INSCRIPCION_LISTA_PERITOS_TERCEROS: `Inscripción en el listado de peritos terceros para valoraciones y tasaciones con las administraciones`,
    INSCRIPCION_LISTA_COLEGIADOS: 'Inscripción en el listado de colegiados dispuestos a actuar como peritos en relación con el artículo 50 de la Ley del Notariado',
    AREA_DE_COLEGIACION: 'Área de colegiación',
    CATALOGO_DE_CURSOS: `Catálogo de cursos`,

}

const VALIDATIONS = {
    VALID_EXISTE_NUMERO_COLEGIADO: 'El número de colegiado es obligatorio.',
    VALID_EXISTE_CLAVE: 'La clave es obligatoria.',
    VALID_CONFIRMAR_CLAVE: 'Las claves no son las mismas.',
    VALID_EXISTE_NOMBRE_SOCIEDAD: 'El nombre de la sociedad es obligatorio.',
    VALID_EXISTE_CAMPO_DEFAULT: 'El campo es obligatorio.',
    VALID_CORRECT_EMAIL: 'Por favor, introduzca una dirección de correo válida.',
    VALID_CORRECT_MOVIL: 'El número de móvil sólo puede contener dígitos (sin espacios, puntos,...)',
}

const lang = {
    ES: {
        ...GENERALS,
        ...VALIDATIONS
    }
}

export default lang;
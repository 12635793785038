import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
  auth: any
}

const initialState: AuthState = {
  auth: localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE || '') ? JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE || '') || '') : {},
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logIn: (state, action: any) => {
      state.auth = action.payload
      localStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE || '', JSON.stringify(action.payload))
    },

    logOut: (state) => {
      state.auth = {}
      localStorage.removeItem(process.env.REACT_APP_MAIN_VARIABLE || '')
    },
  },
})

// Action creators are generated for each case reducer function
export const { logOut, logIn } = authSlice.actions

// export default authSlice.reducer
import { sendString } from "./sends"

const FamiliasAdapters = {

    GET: {

        receive: {

            schema: function (item: any = '') {

                return item
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const res = this.schema(dataTemp)

                return res
            }
        },
    },

}

const SubFamiliasAdapters = {

    GET: {

        receive: {

            schema: function (item: any = '') {

                return item
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const res = this.schema(dataTemp)

                return res
            }
        },
    },
}

export {
    FamiliasAdapters,
    SubFamiliasAdapters
}
import { BreadCrumbCustom } from '../../components/BreadCrumbCustom/BreadCrumbCustom';
import { Box, Button, Grid, List, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLang } from '../../language';
import { DatosProfesionales } from './DatosProfesionales';
import { Navigate, Route, Routes } from 'react-router';
import { SubList } from '../../components/SubList/SubList';
import { DatosAccesoConsumidor } from './DatosAccesoConsumidor';
import { DatosContactoRelacionColegio } from './DatosContactoRelacionColegio';
import { Home } from './Home';
import { isColegiado, isPreColegiado } from '../../routes/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { PrivateRouter } from '../../routes/PrivateRouter';
import { PrivateRoute } from '../../routes/PrivateRoute';
import { RolRoute } from '../../routes/RolRoute';
import { DatosServices } from './controllers/services/datos.services';

export const ActualizacionDatos = ({ onlyPath }: any) => {

    const [index, setindex] = useState(0)

    // const parentPath = '/actualitzacio-dades'
    const Lang = useLang()


    const [BreadcrumbLineItems, setBreadcrumbLineItems] = useState<any>([
        {
            title: Lang('ACTUALIZACION_DATOS'),
            link: onlyPath
        }
    ])

    const { auth } = useSelector((state: RootState) => state.auth)


    const listRoutes = [
        {
            title: Lang('DATOS_PROFESIONALES'),
            link: '/datos-profesionales',
            show: false
        },
        {
            title: Lang('DATOS_ACCESO_CONSUMIDOR'),
            link: '/dades-acces-consumidor',
            show: isColegiado(auth.rol)
        },
        {
            title: Lang('DATOS_CONTACTO_RELACION_COLEGIO'),
            link: '/dades-contacte-relacio-col-legi',
            show: true
        }
    ]

    const [fetchDatosGET, fetchDatosGETData] = DatosServices.GET()

    useEffect(() => {

        fetchDatosGET({
            params: {
                'tipo-direccion': 'CONTACT'
            }
        })

    }, [])

    return (
        <div>
            <BreadCrumbCustom items={BreadcrumbLineItems} />

            <section className='LayoutPrimary__contentmain'>

                <Box>
                    <Grid container spacing={3} justifyContent='center'>
                        {
                            isColegiado(auth.rol) &&
                            <Grid item xs={12} md={4} lg={3}>
                                <Paper className='Paper__SubList'>

                                    <SubList
                                        listRoutes={listRoutes?.filter((item: any) => item.show)}
                                        parentPath={onlyPath}
                                    />

                                </Paper>
                            </Grid>
                        }


                        <Grid item xs={12} md={8} lg={9}>

                            <Routes>

                                <Route index element={<Home rol={auth.rol} setBreadcrumbLineItems={setBreadcrumbLineItems} />} />

                                <Route path={listRoutes[0].link} element={<RolRoute isAllowedRol={isColegiado(auth.rol)} onlyPath={onlyPath} />}>

                                    <Route index element={<DatosProfesionales formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[0]} />} />

                                </Route>

                                <Route path={listRoutes[1].link} element={<RolRoute isAllowedRol={isColegiado(auth.rol)} onlyPath={onlyPath} />}>

                                    <Route index element={<DatosAccesoConsumidor formContact={fetchDatosGETData} setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[1]} />} />

                                </Route>

                                <Route path={listRoutes[2].link} element={<RolRoute isAllowedRol={isColegiado(auth.rol) || isPreColegiado(auth.rol)} onlyPath={onlyPath} />}>
                                {/* <Route path={listRoutes[2].link} element={<RolRoute isAllowedRol={true} onlyPath={onlyPath} />}> */}

                                    <Route index element={<DatosContactoRelacionColegio setBreadcrumbLineItems={setBreadcrumbLineItems} route={listRoutes[2]} />} />

                                </Route>

                                <Route path="*" element={<Navigate to={`${onlyPath}/`} replace />} />

                            </Routes>

                        </Grid>
                    </Grid>
                </Box>
            </section>
        </div>
    )
}

import React, { useState } from 'react'
import './LayoutPrimary.scss'

import { useNavigate } from 'react-router-dom';
import Navleft from './Navleft';
import Body from './Body';
import Navtop from './Navtop';

interface ILayoutPrimary {
    children: any;
    label?: string;
}

const LayoutPrimary = ({ children, label }: ILayoutPrimary) => {

    const navigate = useNavigate();

    const handleClickNavigateTo = (url: string) => navigate(`/${url}`)

    const [showNavLeft, setshowNavLeft] = useState(false)

    const [showNavTop, setshowNavTop] = useState(false)

    return (
        <section className={`LayoutPrimary ${showNavLeft ? 'LayoutPrimary__Navleft--active' : ''} ${showNavTop ? 'LayoutPrimary__Navtop--active' : ''}`}>
            {
                children?.map((childElement: any, indexchildElement: any) =>
                    addPropsToReactElement(childElement, { showNavLeft, setshowNavLeft, label, 'key': indexchildElement, showNavTop, setshowNavTop } )
                )
            }
        </section>
    )
}

function addPropsToReactElement(element: any, props: any) {
    if (React.isValidElement(element)) {
        return React.cloneElement(element, props)
    }
    return element
}


LayoutPrimary.Navtop = Navtop
LayoutPrimary.Navleft = Navleft
LayoutPrimary.Body = Body

export default LayoutPrimary



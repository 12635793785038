import { env } from "process"
import { sendMultipart } from "../../../../controllers/adapters/helpers"
import { parseArray, parseBoolean, parseNumber, parseObject, parseString, receiveBoolean, receiveNumber, receiveObject, receiveString } from "../../../../controllers/adapters/receives"
import { sendString } from "../../../../controllers/adapters/sends"
import { removeDuplicatesObject } from "../../../../helpers"

var LoginAdapters = {

    POST: {

        send: {

            schema: function (item: any = {}) {

                return {
                    // username: sendString(item.username),
                    // password: sendString(item.password),
                }
            },

            adapter: function (data: any) {

                const item = this.schema(data)

                return {
                    ...item
                }
            }
        },

        receive: {

            schema: function (item: any = {}) {

                return {
                    token: item.token
                }
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const res = this.schema(dataTemp)

                return res
            }
        },
    },

}

const PoblacionesAdapters = {

    GET: {

        receive: {

            schema: function (item: any = '') {

                return item
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const res = this.schema(dataTemp)

                return res
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                var data = {
                    password: sendString(item.password),
                    token: sendString(item.token),
                }

                return data
            },

            adapter: function (data: any) {

                const item = this.schema(data)

                return {
                    ...item
                }
            }
        },
    },
}

const PaisesAdapters = {

    GET: {

        receive: {

            schema: function (item: any = '') {

                return item
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const res = this.schema(dataTemp)

                return res
            }
        },
    },
}

const DatosAdapters = {

    GET: {

        receive: {

            schema: function (item: any = []) {

                return {
                    identificador: receiveNumber(item.identificador),
                    persPersonId: receiveString(item.persPersonId),
                    persCifNif: receiveString(item.persCifNif),
                    persCodiCole: receiveString(item.persCodiCole),
                    nombre: receiveString(item.nombre),
                    apellidos: receiveString(item.apellidos),
                    fechaNacimiento: receiveString(item.fechaNacimiento),

                    direccion: receiveObject(item.direccion),
                    direccion__codigoPostal: receiveString(item.direccion?.codigoPostal),
                    direccion__comarca: receiveString(item.direccion?.comarca),
                    direccion__direccion: receiveString(item.direccion?.direccion),
                    direccion__pais: receiveString(item.direccion?.pais),
                    direccion__idpais: receiveNumber(item.idPais),
                    direccion__poblacion: receiveString(item.direccion?.poblacion),
                    direccion__provincia: receiveString(item.direccion?.provincia),

                    tipoDireccion: receiveString(item.tipoDireccion),

                    datosContacto: receiveObject(item.datosContacto),
                    datosContacto__telefono: receiveString(item.datosContacto?.telefono),
                    datosContacto__telefono2: receiveString(item.datosContacto?.telefono2),
                    datosContacto__telefonoMovil: receiveString(item.datosContacto?.telefonoMovil),
                    datosContacto__movil: receiveString(item.datosContacto?.movil),
                    datosContacto__telefonoFax: receiveString(item.datosContacto?.telefonoFax),
                    datosContacto__email: receiveString(item.datosContacto?.email),
                    datosContacto__website: receiveString(item.datosContacto?.website),
                    datosContacto__web: receiveString(item.datosContacto?.web),

                    colegio: receiveString(item.colegio),
                    situacion: receiveString(item.situacion),
                    titulos: receiveString(item.titulos),
                    estat: receiveString(item.estat),
                    habilitado: receiveBoolean(item.habilitado),
                    lopd: receiveBoolean(item.lopd),
                    theknos: receiveBoolean(item.theknos),
                    butlleti: receiveBoolean(item.butlleti),
                    serviciosActivos: receiveString(item.serviciosActivos),
                    comentario: receiveString(item.comentario),
                }
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = dataTemp?.map((item: any) => (this.schema(item)))

                return {
                    items
                }
            }
        },
    },

    PUT: {

        send: {

            schema: function (item: any = {}) {

                var data = {
                    identificador: parseNumber(item.identificador),
                    persPersonId: parseString(item.persPersonId),
                    persCifNif: parseString(item.persCifNif),
                    persCodiCole: parseString(item.persCodiCole),
                    nombre: parseString(item.nombre),
                    apellidos: parseString(item.apellidos),
                    fechaNacimiento: parseString(item.fechaNacimiento),

                    direccion: {
                        codigoPostal: parseString(item.direccion__codigoPostal),
                        comarca: parseString(item.direccion__comarca),
                        direccion: parseString(item.direccion__direccion),
                        pais: parseString(item.direccion__pais),
                        poblacion: parseString(item.direccion__poblacion),
                        provincia: parseString(item.direccion__provincia),
                    },

                    tipoDireccion: parseString(item.tipoDireccion),

                    datosContacto: {
                        telefono: parseString(item.datosContacto__telefono),
                        telefono2: parseString(item.datosContacto__telefono2),
                        telefonoMovil: parseString(item.datosContacto__telefonoMovil),
                        movil: parseString(item.datosContacto__movil),
                        telefonoFax: parseString(item.datosContacto__telefonoFax),
                        email: parseString(item.datosContacto__email),
                        website: parseString(item.datosContacto__website),
                        web: parseString(item.datosContacto__web),
                    },

                    colegio: parseString(item.colegio),
                    situacion: parseString(item.situacion),
                    titulos: parseString(item.titulos),
                    estat: parseString(item.estat),
                    habilitado: parseBoolean(item.habilitado),
                    lopd: parseBoolean(item.lopd),
                    theknos: parseBoolean(item.theknos),
                    butlleti: parseBoolean(item.butlleti),
                    serviciosActivos: parseString(item.serviciosActivos),
                    comentario: parseString(item.comentario),
                }

                return data
            },

            adapter: function (data: any) {

                const item = this.schema(data)

                return {
                    ...item
                }
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any = {}) {

                var data = {
                    identificador: parseNumber(item.identificador),
                    persPersonId: parseString(item.persPersonId),
                    persCifNif: parseString(item.persCifNif),
                    persCodiCole: parseString(item.persCodiCole),
                    nombre: parseString(item.nombre),
                    apellidos: parseString(item.apellidos),
                    fechaNacimiento: parseString(item.fechaNacimiento),

                    direccion: {
                        codigoPostal: parseString(item.direccion__codigoPostal),
                        comarca: parseString(item.direccion__comarca),
                        direccion: parseString(item.direccion__direccion),
                        pais: parseString(item.direccion__pais),
                        poblacion: parseString(item.direccion__poblacion),
                        provincia: parseString(item.direccion__provincia),
                    },

                    tipoDireccion: parseString(item.tipoDireccion),

                    datosContacto: {
                        telefono: parseString(item.datosContacto__telefono),
                        telefono2: parseString(item.datosContacto__telefono2),
                        telefonoMovil: parseString(item.datosContacto__telefonoMovil),
                        movil: parseString(item.datosContacto__movil),
                        telefonoFax: parseString(item.datosContacto__telefonoFax),
                        email: parseString(item.datosContacto__email),
                        website: parseString(item.datosContacto__website),
                        web: parseString(item.datosContacto__web),
                    },

                    colegio: parseString(item.colegio),
                    situacion: parseString(item.situacion),
                    titulos: parseString(item.titulos),
                    estat: parseString(item.estat),
                    habilitado: parseBoolean(item.habilitado),
                    lopd: parseBoolean(item.lopd),
                    theknos: parseBoolean(item.theknos),
                    butlleti: parseBoolean(item.butlleti),
                    serviciosActivos: parseString(item.serviciosActivos),
                    comentario: parseString(item.comentario),
                }

                return data
            },

            adapter: function (data: any) {

                const item = this.schema(data)

                return {
                    ...item
                }
            }
        },
    },
}

const DatosProfesionalesAdapters = {

    GET: {

        receive: {

            schema: function (item: any = []) {

                return {
                    persCifNif: receiveString(item.persCifNif),

                    direccion: receiveObject(item.direccion),
                    direccion__codigoPostal: receiveString(item.direccion?.codigoPostal),
                    direccion__comarca: receiveString(item.direccion?.comarca),
                    direccion__direccion: receiveString(item.direccion?.direccion),
                    direccion__pais: receiveString(item.direccion?.pais),
                    direccion__idpais: receiveNumber(item.idPais),
                    direccion__poblacion: receiveString(item.direccion?.poblacion),
                    direccion__provincia: receiveString(item.direccion?.provincia),

                    datosContacto: receiveObject(item.datosContacto),
                    datosContacto__telefono: receiveString(item.datosContacto?.telefono),
                    datosContacto__telefono2: receiveString(item.datosContacto?.telefono2),
                    datosContacto__movil: receiveString(item.datosContacto?.movil),
                    datosContacto__telefonoFax: receiveString(item.datosContacto?.telefonoFax),
                    datosContacto__email: receiveString(item.datosContacto?.email),
                    datosContacto__web: receiveString(item.datosContacto?.web),

                    nombreEmpresa: receiveString(item.nombreEmpresa),
                    presentacion: receiveString(item.presentacion),
                    enlace: receiveString(item.enlace),
                    visible: receiveBoolean(item.visible),

                    fotoPersonalUrl: item.fotoPersonalUrl ? receiveString(process.env.REACT_APP_API_URL + item.fotoPersonalUrl) : '',
                    logoEmpresaUrl: item.logoEmpresaUrl ? receiveString(process.env.REACT_APP_API_URL + item.logoEmpresaUrl) : '',
                    curriculumUrl: item.curriculumUrl ? receiveString(process.env.REACT_APP_API_URL + item.curriculumUrl) : '',
                    actividades: parseArray(item.actividades),
                    comarcasPreferentes: parseArray(item.comarcasPreferentes),
                }
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    ...items
                }
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any = {}) {

                var data = {
                    nif: parseString(item.persCifNif),

                    direccion: {
                        codigoPostal: parseString(item.direccion__codigoPostal),
                        comarca: parseString(item.direccion__comarca),
                        direccion: parseString(item.direccion__direccion),
                        pais: parseString(item.direccion__pais),
                        poblacion: parseString(item.direccion__poblacion),
                        provincia: parseString(item.direccion__provincia),
                    },

                    datosContacto: {
                        telefono: parseString(item.datosContacto__telefono),
                        telefono2: parseString(item.datosContacto__telefono2),
                        movil: parseString(item.datosContacto__movil),
                        telefonoFax: parseString(item.datosContacto__telefonoFax),
                        email: parseString(item.datosContacto__email),
                        web: parseString(item.datosContacto__web),
                    },

                    nombreEmpresa: parseString(item.nombreEmpresa),
                    presentacion: parseString(item.presentacion),
                    enlace: parseString(item.enlace),
                    visible: parseBoolean(item.visible),

                    actividades: removeDuplicatesObject(parseArray(item.actividades))?.map((item: any) => {
                        return {
                            id: item.id,
                        }
                    })?.filter((item2: any) => item2.id) || [],

                    comarcasPreferentes: removeDuplicatesObject(parseArray(item.comarcasPreferentes))?.map((item: any) => {
                        return {
                            id: item.id,
                        }
                    })?.filter((item2: any) => item2.id) || [],
                }

                return data
            },

            adapter: function (data: any) {

                const item = this.schema(data)

                return {
                    ...item
                }
            }
        },
    },

    POST_DOCUMENTO: {
        send: {

            schema: function (item: any = {}) {


                return item
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return sendMultipart(items)

                // return {
                //     ...item
                // }
            }
        },
    },

    GET_DOCUMENTO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}
                
                const data = {
                    // filtros: item.filtros || '',
                    // lista_ids: item.lista_ids || '',
                    // lista_exclusion: item.lista_exclusion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE_DOCUMENTO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)

                return {
                    ...items
                }
            }
        },
    },
}


export {
    LoginAdapters,
    PoblacionesAdapters,
    PaisesAdapters,
    DatosAdapters,
    DatosProfesionalesAdapters,
}
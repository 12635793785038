import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Placeholder } from '../Placeholder/Placeholder';
import { UploadCard } from '../UploadCard/Index';

import './InputImage.css'

const InputImage = ({ label, labelRsl, width = 'auto', height = 'auto', urlImage = '', setUrlImage = (() => null),
    onChange, setInputFile, InputFile, fetchAccionesData, handleSubirImagen, handleEliminarImagen, onlyRead }: any) => {

    const inputFileRef = useRef<any>(null)

    useEffect(() => {

        if (urlImage) {
            setInputFile([
                {
                    path: urlImage
                }
            ])
        }

    }, [urlImage])


    const onFileChange = (e: any) => {

        const newFiles = e.target.files

        // if (handleSubirImagen) {

        //     handleSubirImagen(newFiles[0])
        // }

        setInputFile([
            ...InputFile,
            ...newFiles
        ])

    }

    const handleClickInputFile = () => {
        inputFileRef.current.click();
    }

    const handleClickRemoveFile = (index: any) => {

        let InputFileTemp = [...InputFile]

        if (handleEliminarImagen) {

            handleEliminarImagen()
        }

        InputFileTemp.splice(index, 1)

        setInputFile([
            ...InputFileTemp
        ])
        inputFileRef.current.value = null
        setUrlImage('')
    }

    return (
        <div className='InputImage d-flex flex-column' style={{ 'width': width, 'height': 'auto' }}>
            {
                label && <section className="d-flex justify-content-between mb-2">
                    <span>{label}</span>
                </section>
            }
            <input type="file" accept="image/png, .jpeg, .jpg, image/gif" ref={inputFileRef} onChange={(e) => {
                if (onChange) {
                    onChange(e)
                } else {
                    onFileChange(e)
                }
            }} className='d-none' />
            <div className="InputImage__container d-flex flex-wrap container-files-input mb-0 justify-content-center flex-column align-items-center">
                {
                    InputFile.length === 0 && <UploadCard
                        height={height}
                        width='100%'
                        label='Nova imatge'
                        onClick={handleClickInputFile}
                    />
                }
                {
                    InputFile.map((item: any, index: number) => {
                        let src = item?.path
                        if (!src) {
                            src = URL.createObjectURL(item)
                        }
                        return (
                            <>{
                                onlyRead ? <div className='box-input-file-elem m-0 p-0 box-input-file-elem-nohover pe-none' key={'2' + index} style={{ 'width': '100%', 'height': height }}>
                                    <img src={src} alt="" />
                                </div> :
                                    <div className='box-input-file-elem m-0 p-0' key={'2' + index} style={{ 'width': '100%', 'height': height }}>
                                        <img src={src} alt="" />
                                        <div className='box-input-file-elem-options'>
                                            <div className='box-input-file-elem-option elem-option-1 text-danger' onClick={() => handleClickRemoveFile(index)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </div>
                                        </div>
                                    </div>
                            }
                            </>
                        )
                    })
                }
            </div>
            {
                fetchAccionesData && fetchAccionesData.loading && <div className='InputImage__Containerloader d-flex align-items-center justify-content-center'>
                    <Placeholder />
                </div>
            }
        </div>
    )
}

export default InputImage
import { FormControl, Input, InputLabel, TextField, Typography } from '@mui/material'
import React from 'react'

export const InputText = ({ label, readOnly, value, validation, onChange, name, type = 'text', className = '', localValidation = [] }: any) => {

    const validations = validated(localValidation)

    return (
        <FormControl fullWidth variant="standard">
            {
                readOnly ?
                    <>
                        <Typography variant='body2' className='mb-1' component="span">
                            {label}
                        </Typography>
                        <Typography variant='body1' className="my-0 pb-1" component="span">
                            {value || '-'}
                        </Typography>
                    </> :
                    <>
                        {/* <InputLabel htmlFor="standard-adornment-amount">
                            {label}
                        </InputLabel> */}
                        <TextField
                            variant="standard"
                            value={value}
                            label={label}
                            onChange={onChange}
                            name={name}
                            type={type}
                            {...validations}
                            className={className}
                        />
                    </>
            }
        </FormControl>
    )
}

const validated = (localValidation: any) => {

    let localValidationPreFilter = localValidation.filter((item: any) => item.isOk === false)    

    let res = {}

    if (localValidationPreFilter.length > 0) {
        res = {
            helperText: localValidationPreFilter?.map((item: any, index: number) => {
                return <span className='d-block' key={index}>{item.msgError}</span>
            }),
            error: true
        }
    }

    return res
}

import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Input, InputLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'

export const InputChecks = ({ label, readOnly, value, values, onChange }: any) => {
    return (
        <FormControl fullWidth variant="standard">
            {
                readOnly ?
                    <>
                        <Typography variant='body2' className='mb-1' component="span">
                            {label}
                        </Typography>
                        <Typography variant='body1' className="my-0 pb-1" component="span">

                        </Typography>
                    </> :
                    <>
                        <FormLabel>{label}</FormLabel>
                        <FormGroup>
                            {
                                values?.map((item: any) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={item.checked}
                                                    name={item.id}
                                                    onChange={onChange}
                                                />
                                            }
                                            label={item.label}
                                        />
                                    )
                                })
                            }
                        </FormGroup>
                    </>
            }
        </FormControl>
    )
}
